import { Container, Sprite, Texture } from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';

import { ISongs } from '../../../config';
import { calcPercentage, getCssVariable } from '../../../utils';
import { EventTypes, eventManager } from '../../config';

export class UiButton extends Container {
  public btn: Sprite;

  public size: number;

  public isDisabled = false;

  public applicationSize = { width: 0, height: 0 };

  public isLandscapeMode = false;

  public isPortraitMode = false;

  public intent: string;

  private isActive = false;

  constructor(intent = '') {
    super();
    this.x = 0;
    this.y = 0;
    this.intent = intent;
    this.btn = this.initButton(`${intent}-default`);
    this.size = this.btn.width;
    this.addChild(this.btn);
    this.zIndex = 2;
    eventManager.on(EventTypes.RESIZE, this.resize.bind(this));
  }

  public updateIntent(intent: string): void {
    this.intent = intent;
    this.btn.texture = Texture.from(`${intent}-default`);
  }

  public setActive(isActive: boolean): void {
    if (!this.isDisabled) {
      this.isActive = isActive;
      if (this.isActive) {
        this.setIntent(`${this.intent}-pressed`);
      } else {
        this.setIntent(`${this.intent}-default`);
      }
    }
  }

  public setDisable(disable: boolean): void {
    this.isDisabled = disable;
    this.btn.interactive = !this.isDisabled;
    if (this.isDisabled) {
      this.setIntent(`${this.intent}-disabled`);
    } else {
      this.setIntent(`${this.intent}-default`);
    }
  }

  public setSize(size: number): void {
    this.btn.scale.set(size / this.size);
  }

  public handlePosition(): void {}

  public isFreezed(): boolean {
    return false;
  }

  resize(width: number, height: number): void {
    this.isLandscapeMode = width >= height;
    this.isPortraitMode = width < height;
    this.applicationSize.width = width;
    this.applicationSize.height = this.isPortraitMode
      ? calcPercentage(height, 100 - parseInt(getCssVariable('--bottom-height-percent-portrait'), 10))
      : calcPercentage(height, 100 - parseInt(getCssVariable('--bottom-height-percent-landscape'), 10));

    this.handlePosition();
  }

  private initButton(intent: string) {
    const btn = Sprite.from(intent);
    btn.anchor.set(0.5, 0.5);
    btn.buttonMode = !this.isDisabled;
    btn.interactive = !this.isDisabled;
    btn.on('mousedown', () => {
      if (this.isFreezed()) return;
      this.setIntent(`${this.intent}-pressed`);
    });
    btn.on('mouseup', () => {
      if (this.isFreezed()) return;
      this.setIntent(`${this.intent}-default`);
    });
    btn.on('mouseover', () => {
      if (this.isFreezed()) return;
      AudioApi.play({ type: ISongs.SFX_UI_Hover });
      this.setIntent(`${this.intent}-hover`);
    });
    btn.on('mouseout', () => {
      if (this.isFreezed()) return;
      if (!this.isActive) {
        this.setIntent(`${this.intent}-default`);
      }
    });
    return btn;
  }

  public setIntent(intent: string): void {
    this.btn.texture = Texture.from(intent);
  }
}
