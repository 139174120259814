import Animation from './animation';
import { IAnimation } from './d';

export interface IAnimationGroup extends IAnimation {
  animations?: Animation[];
}
class AnimationGroup extends Animation {
  public animations: Animation[];

  private maxDurationAnimation: Animation | null = null;

  constructor(options?: IAnimationGroup) {
    super(options ?? {});
    this.animations = options?.animations ?? [];
    this.animations.forEach((animation) => {
      if (!this.maxDurationAnimation) this.maxDurationAnimation = animation;
      else if (this.maxDurationAnimation.duration < animation.duration) {
        this.maxDurationAnimation = animation;
        this.duration = animation.duration;
      }
    });
  }

  public addAnimation(animation: Animation): void {
    this.animations.push(animation);
    if (!this.maxDurationAnimation) this.maxDurationAnimation = animation;
    else if (animation.duration >= this.maxDurationAnimation?.duration) {
      this.maxDurationAnimation = animation;
      this.duration = animation.duration;
    }
  }

  public override start(): void {
    super.start();
    if (this.animations.length) {
      if (!this.maxDurationAnimation?.complete.includes(this.bindedOnComplete))
        this.maxDurationAnimation?.addOnComplete(this.bindedOnComplete);
      if (!this.maxDurationAnimation?.change.includes(this.bindedOnChange))
        this.maxDurationAnimation?.addOnChange(this.bindedOnChange);
      this.animations.forEach((animation) => animation.start());
    } else {
      this.onComplete();
    }
  }

  private bindedOnComplete = this.onComplete.bind(this);

  private bindedOnChange = this.onChange.bind(this);

  public override end(): void {
    super.end();
    this.animations.forEach((animation) => animation.end());
  }

  public override skip(isLoop?: boolean): void {
    this.isLoop = false;
    this.animations.forEach((animation) => {
      if (!animation.ended || isLoop) {
        animation.skip(this.isLoop || isLoop);
      }
    });
    this.onSkip();
  }
}

export default AnimationGroup;
