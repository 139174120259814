import { TAudioSprite } from '@phoenix7dev/audio-api/dist/d';

export const ISongs = {
  SFX_UI_AutoSpin: 'SFX_UI_AutoSpin',
  SFX_UI_BetChange: 'SFX_UI_BetChange',
  SFX_UI_Close: 'SFX_UI_Close',
  SFX_UI_General: 'SFX_UI_General',
  SFX_UI_Hover: 'SFX_UI_Hover',
  SFX_UI_MaxBet: 'SFX_UI_MaxBet',
  SFX_UI_MenuOpen: 'SFX_UI_MenuOpen',
  XT003S_3ok_stop: 'XT003S_3ok_stop',
  XT003S_4ok_stop: 'XT003S_4ok_stop',
  XT003S_5ok_stop: 'XT003S_5ok_stop',
  XT003S_bg_base_loop: 'XT003S_bg_base_loop',
  XT003S_bg_feature1_loop: 'XT003S_bg_feature1_loop',
  XT003S_bg_feature2_loop: 'XT003S_bg_feature2_loop',
  XT003S_bg_feature3_loop: 'XT003S_bg_feature3_loop',
  XT003S_bigwin_1shot: 'XT003S_bigwin_1shot',
  XT003S_cutin_def: 'XT003S_cutin_def',
  XT003S_cutin_high: 'XT003S_cutin_high',
  XT003S_cutin_low: 'XT003S_cutin_low',
  XT003S_cutin_mid: 'XT003S_cutin_mid',
  XT003S_cutin_reversal: 'XT003S_cutin_reversal',
  XT003S_cutin_soldout: 'XT003S_cutin_soldout',
  XT003S_feature_end: 'XT003S_feature_end',
  XT003S_feature_end_voice: 'XT003S_feature_end_voice',
  XT003S_feature_trigger: 'XT003S_feature_trigger',
  XT003S_lantern_high: 'XT003S_lantern_high',
  XT003S_lantern_low: 'XT003S_lantern_low',
  XT003S_lantern_mid: 'XT003S_lantern_mid',
  XT003S_longspin: 'XT003S_longspin',
  XT003S_okwr_stop_gase: 'XT003S_okwr_stop_gase',
  XT003S_okwr_stop_suc1: 'XT003S_okwr_stop_suc1',
  XT003S_okwr_stop_suc2: 'XT003S_okwr_stop_suc2',
  XT003S_okwr_stop_suc3: 'XT003S_okwr_stop_suc3',
  XT003S_order_feature: 'XT003S_order_feature',
  XT003S_phoenix_sign: 'XT003S_phoenix_sign',
  XT003S_push_buy_feature: 'XT003S_push_buy_feature',
  XT003S_spin_loop: 'XT003S_spin_loop',
  XT003S_spin_start: 'XT003S_spin_start',
  XT003S_spin_stop1: 'XT003S_spin_stop1',
  XT003S_spin_stop2: 'XT003S_spin_stop2',
  XT003S_spin_stop3: 'XT003S_spin_stop3',
  XT003S_spin_stop4: 'XT003S_spin_stop4',
  XT003S_spin_stop5: 'XT003S_spin_stop5',
  XT003S_wild_stop: 'XT003S_wild_stop',
  XT003S_win_big: 'XT003S_win_big',
  XT003S_win_end: 'XT003S_win_end',
  XT003S_win_epic: 'XT003S_win_epic',
  XT003S_win_great: 'XT003S_win_great',
  XT003S_win_loop: 'XT003S_win_loop',
  XT003S_win_mega: 'XT003S_win_mega',
} as const;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type ISongs = (typeof ISongs)[keyof typeof ISongs];

export const audioSprite: TAudioSprite = {
  SFX_UI_AutoSpin: [0, 320.4761904761905],
  SFX_UI_BetChange: [2000, 32.086167800453595],
  SFX_UI_Close: [4000, 200.6802721088432],
  SFX_UI_General: [6000, 88.34467120181432],
  SFX_UI_Hover: [8000, 151.76870748299365],
  SFX_UI_MaxBet: [10000, 122.92517006802761],
  SFX_UI_MenuOpen: [12000, 277.14285714285757],
  XT003S_3ok_stop: [14000, 1000],
  XT003S_4ok_stop: [16000, 1500],
  XT003S_5ok_stop: [19000, 5000],
  XT003S_bg_base_loop: [25000, 79090.95238095238, true],
  XT003S_bg_feature1_loop: [106000, 51600.02267573697, true],
  XT003S_bg_feature2_loop: [159000, 51600.02267573697, true],
  XT003S_bg_feature3_loop: [212000, 51600.02267573697, true],
  XT003S_bigwin_1shot: [265000, 3200.0226757369887],
  XT003S_cutin_def: [270000, 2000],
  XT003S_cutin_high: [273000, 2000],
  XT003S_cutin_low: [276000, 2000],
  XT003S_cutin_mid: [279000, 2000],
  XT003S_cutin_reversal: [282000, 2933.333333333337],
  XT003S_cutin_soldout: [286000, 14800.022675736955],
  XT003S_feature_end: [302000, 4800.022675736955],
  XT003S_feature_end_voice: [308000, 3000],
  XT003S_feature_trigger: [312000, 4000],
  XT003S_lantern_high: [317000, 4333.333333333314],
  XT003S_lantern_low: [323000, 2015.850340136069],
  XT003S_lantern_mid: [327000, 3016.6666666666515],
  XT003S_longspin: [332000, 8000],
  XT003S_okwr_stop_gase: [341000, 600.0000000000227],
  XT003S_okwr_stop_suc1: [343000, 3000],
  XT003S_okwr_stop_suc2: [347000, 3000],
  XT003S_okwr_stop_suc3: [351000, 3000],
  XT003S_order_feature: [355000, 633.3333333333258],
  XT003S_phoenix_sign: [357000, 4033.33333333336],
  XT003S_push_buy_feature: [363000, 633.3333333333258],
  XT003S_spin_loop: [365000, 2000, true],
  XT003S_spin_start: [368000, 1000],
  XT003S_spin_stop1: [370000, 600.022675736966],
  XT003S_spin_stop2: [372000, 600.0000000000227],
  XT003S_spin_stop3: [374000, 600.0000000000227],
  XT003S_spin_stop4: [376000, 600.0000000000227],
  XT003S_spin_stop5: [378000, 600.0000000000227],
  XT003S_wild_stop: [380000, 666.6666666666856],
  XT003S_win_big: [382000, 1800.0453514738979],
  XT003S_win_end: [385000, 1000],
  XT003S_win_epic: [387000, 3000.0453514739434],
  XT003S_win_great: [392000, 1800.0453514738979],
  XT003S_win_loop: [395000, 1000, true],
  XT003S_win_mega: [397000, 1800.0453514738979],
};
