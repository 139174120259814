import { setBetAmount, setCoinAmount, setCoinValue, setCurrency, setSlotConfig } from '../gql/cache';
import { EventTypes, eventManager } from '../slotMachine/config';

import { getNonNullableValue } from './helper';

export const findSubstituteCoinAmount = (requestedCoinAmount: number, coinAmounts: number[]): number => {
  for (let i = coinAmounts.length - 1; i >= 0; i--) {
    const coinAmount = coinAmounts[i]!;

    if (coinAmount <= requestedCoinAmount) {
      return coinAmount;
    }
  }

  return coinAmounts[0] ?? 0; // return 0 if BE send empty arr []
};

// updated coin value from BE after bonus game, because on bonus game we use Coin Value from history
export const updateCoinValueAfterBonuses = (): void => {
  const slotConfig = getNonNullableValue(setSlotConfig());
  const coinValue = slotConfig.clientSettings.coinValues.find((elem) => elem?.code === setCurrency())?.variants[0];
  const coinAmount = findSubstituteCoinAmount(setCoinAmount(), slotConfig.clientSettings.coinAmounts.default);
  setCoinValue(coinValue);
  setCoinAmount(coinAmount);
  setBetAmount(coinAmount * slotConfig.lineSet.coinAmountMultiplier);
  eventManager.emit(EventTypes.UPDATE_BET, setBetAmount());
};
