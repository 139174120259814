import { IPointData } from 'pixi.js';

import { SpineInterface } from './spine.generated';

export const INTRO_FRAME_SPINE_WIDTH = 1184;
export const INTRO_FRAME_SPINE_HEIGHT = 708;
export interface ISlide {
  txtKeyTop: string;
  txtKeyBottom: string;
  txtTop: IPointData;
  txtBottom: IPointData;
  animation: SpineInterface['introscreen']['animations'];
}

export const introContents = [
  {
    txtKeyTop: 'introTitle.page1.Top',
    txtKeyBottom: '',
    txtTop: { x: 0, y: 99 },
    txtBottom: { x: 0, y: 0 },
    animation: 'a',
  },
  {
    txtKeyTop: 'introTitle.page2.Top',
    txtKeyBottom: 'introTitle.page2.Bottom',
    txtTop: { x: 0, y: 77 },
    txtBottom: { x: INTRO_FRAME_SPINE_WIDTH / 2 - 80, y: INTRO_FRAME_SPINE_HEIGHT - 93 },
    animation: 'b',
  },
  {
    txtKeyTop: 'introTitle.page3.Top',
    txtKeyBottom: '',
    txtTop: { x: 0, y: 94 },
    txtBottom: { x: 0, y: 0 },
    animation: 'c',
  },
] as const satisfies readonly ISlide[];

export const INTRO_OUTER_TEXT_POSITION: IPointData = {
  x: 80 - INTRO_FRAME_SPINE_WIDTH / 2,
  y: 678,
};

export const INTRO_DOT_POSITION_Y = INTRO_FRAME_SPINE_HEIGHT - 62;
