import { useQuery } from '@apollo/client';
import * as PIXI from 'pixi.js';
import React, { useEffect, useRef } from 'react';

import AudioApi from '@phoenix7dev/audio-api';

import { setIsSlotBusy, setIsSpinInProgress, setSlotConfig } from '../../gql/cache';
import { configGql } from '../../gql/query';
import SlotMachine from '../../slotMachine';
import Animator from '../../slotMachine/animations/animator';
import { EventTypes, eventManager } from '../../slotMachine/config';
import { IntroScreen } from '../../slotMachine/introScreen';
import { wait } from '../../utils';
import { wrap } from '../../utils/helper';
import AutoPlaySettingsMenu from '../AutoPlaySettings/AutoPlaySettingsPopup';
import BetSettingsMenu from '../BetSettings/BetSettingsMenu';
import useBuyFeature from '../BuyFeature';
import EventListener from '../EventListener';
import HistoryPopup from '../History/HistoryPopup';
import InfoPopup from '../Info/InfoPopup';
import Spin from '../SpinButton';

import styles from './gameScreen.module.scss';
import { preTextureTransfer } from './preTextureTransfer';

const GameScreen: React.FC = () => {
  const { data } = useQuery(configGql);
  const { isSoundOn } = data!;
  const pixiContainerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    AudioApi.mute(isSoundOn);
    AudioApi.setSoundState(isSoundOn);
  }, [isSoundOn]);

  useEffect(() => {
    const application = new PIXI.Application({
      resolution: window.devicePixelRatio || 1,
      autoDensity: true,
    });
    globalThis.__PIXI_APP__ = application;
    Animator.init(application);
    preTextureTransfer(application);
    application.renderer.once(EventTypes.POST_RENDER, () => {
      eventManager.emit(EventTypes.POST_RENDER);
    });
    pixiContainerRef.current?.appendChild(application.view);

    const resize = (): void => {
      const parent = pixiContainerRef.current!;
      const width = parent.clientWidth;
      const height = parent.clientHeight;
      application.renderer.resize(width, height);
      eventManager.emit(EventTypes.RESIZE, width, height);
    };

    IntroScreen.initIntroScreen(application);
    eventManager.addListener(EventTypes.HANDLE_DESTROY_INTRO_SCREEN, () => {
      SlotMachine.initSlotMachine(
        application,
        setSlotConfig(),
        wrap(setIsSpinInProgress, false),
        wrap(setIsSlotBusy, false),
      );
      resize();
    });

    resize();

    const resizeHandler = async () => {
      const userAgent = navigator.userAgent;
      if ((PIXI.isMobile.apple.phone || PIXI.isMobile.apple.tablet) && userAgent.includes('CriOS')) {
        await wait(50);
      }
      resize();
    };

    window.addEventListener(EventTypes.RESIZE, resizeHandler);
    return () => window.removeEventListener(EventTypes.RESIZE, resizeHandler);
  }, []);

  useBuyFeature();

  return (
    <>
      <div ref={pixiContainerRef} className={styles.canvas} />
      <EventListener />
      <AutoPlaySettingsMenu />
      <InfoPopup />
      <HistoryPopup />
      <BetSettingsMenu />
      <Spin />
    </>
  );
};

export default GameScreen;
