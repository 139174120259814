import { gql } from './__generated__';

export const authGql = gql(`
  mutation Auth($input: AuthInput!) {
    auth(input: $input) {
      sessionId
    }
  }
`);

export const placeBetGql = gql(`
  mutation PlaceBet($input: PlaceBetInput!) {
    placeBet(input: $input) {
      bet {
        id
        coinAmount
        coinValue
        reelSet {
          id
          type
        }
        lineSet {
          id
          coinAmountMultiplier
        }
        result {
          reelPositions
          winCoinAmount
        }
        data
        userBonus{
          bonusId
        }
      }
      paylines {
        lineId
        winPositions
        amount
      }
      balance {
        placed {
          amount
          currency
        }
        settled {
          amount
          currency
        }
      }
      rewards {
        __typename
        ...betCoinReward
        ...betBonusReward
      }
    }
  }
`);

export const buyBonusGql = gql(`
  mutation buyBonus($input: BuyBonusesInput!) {
    buyBonus(input: $input) {
      id
      bonusId
      status
      coinValue
      data
      balance {
        amount
        currency
      }
      rounds
      roundsPlayed
    }
  }
`);
