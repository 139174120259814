import * as PIXI from 'pixi.js';

import { formatNumber } from '@phoenix7dev/utils-fe';

import { normalizeCoins, showCurrency } from '../../utils';
import AutoResizeText from '../components/autoResizeText';
import ViewContainer from '../components/container';

import { buyFeatureSymbolCostTextStyle, buyFeatureSymbolNameTextStyle } from './textStyles';

export class BuyFeatureSymbolContainer extends ViewContainer {
  private symbolCostText: AutoResizeText;

  private symbolName: AutoResizeText;

  private currency = 'FUN';

  private costMultiplier: number;

  private betValue: number;

  constructor(symbolName: string, currency: string, costMultiplier: number, betValue: number, callback: () => void) {
    super();

    this.currency = currency;
    this.costMultiplier = costMultiplier;
    this.betValue = betValue;

    this.symbolCostText = new AutoResizeText(this.getValueText(), buyFeatureSymbolCostTextStyle);
    this.symbolCostText.anchor.set(1.0);

    this.symbolName = new AutoResizeText(symbolName, buyFeatureSymbolNameTextStyle);
    this.symbolName.anchor.set(1.0, 1.0);
    this.symbolName.position.set(0, 56);

    this.addChild(this.symbolName, this.symbolCostText);

    this.hitArea = new PIXI.Rectangle(-430, -100, 430, 200);
    this.interactive = true;
    this.buttonMode = true;
    this.on('pointerdown', () => {
      callback();
    });
  }

  public updateBetValue(value: number) {
    this.betValue = value;
    this.symbolCostText.text = this.getValueText();
  }

  private getValueText(): string {
    return `${formatNumber({
      currency: this.currency,
      value: normalizeCoins(this.betValue * this.costMultiplier),
      showCurrency: showCurrency(this.currency),
    })}`;
  }
}
