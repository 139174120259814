import { useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';

import AudioApi from '@phoenix7dev/audio-api';
import { Button, Popup } from '@phoenix7dev/shared-components/';

import { ISongs } from '../../config';
import { PopupOpeningTypes } from '../../consts';
import { setIsOpenInfoPopup, setIsPopupOpeningInProgress } from '../../gql/cache';
import { configGql } from '../../gql/query';

import AutoPlay from './AutoPlay';
import ButtonBehaviors from './ButtonBehaviors';
import Features from './Features';
import GameRules from './GameRules';
import PayLines from './PayLines';
import PaytableComponent from './Paytable';
import styles from './info.module.scss';

const InfoPopup: React.FC = () => {
  const [showPopup, setShowPopup] = useState(false);
  const { data: configData } = useQuery(configGql);
  const { isOpenInfoPopup, isSpinInProgress } = configData!;

  useEffect(() => {
    if (isOpenInfoPopup) {
      AudioApi.play({ type: ISongs.SFX_UI_MenuOpen });
    }
    setShowPopup(isOpenInfoPopup);
  }, [isOpenInfoPopup]);

  useEffect(() => {
    if (showPopup && isSpinInProgress) {
      setShowPopup(false);
      setIsOpenInfoPopup(false);
    }
  }, [isSpinInProgress, showPopup]);

  const handleClose = (directClickToCloseIcon = false) => {
    if (
      setIsPopupOpeningInProgress() &&
      setIsPopupOpeningInProgress() !== PopupOpeningTypes.AUTOPLAY_POPUP &&
      setIsPopupOpeningInProgress() !== PopupOpeningTypes.BUY_FEATURE_POPUP &&
      !directClickToCloseIcon
    )
      return;

    setTimeout(() => {
      if (!AudioApi.isPlaying(ISongs.SFX_UI_MenuOpen)) {
        AudioApi.play({ type: ISongs.SFX_UI_Close });
      }
    }, 0);
    setIsOpenInfoPopup(false);
  };

  return (
    <Popup id="popup" className="popup" showNow={showPopup} setShowNow={() => handleClose()}>
      <Button intent="close" className="popup__close" onClick={handleClose} />
      <div className={styles.info}>
        <div className={styles.container}>
          <div className={styles.wrap}>
            <PaytableComponent />
            <Features />
            <GameRules />
            <PayLines />
            <ButtonBehaviors />
            <AutoPlay />
          </div>
        </div>
      </div>
    </Popup>
  );
};

export default InfoPopup;
