import * as PIXI from 'pixi.js';

import { Variables } from '../../config';
import { AutoResizeTextStyle } from '../components/autoResizeText';

const font = Variables.FONT_FAMILY;

export const totalWinTitleStyles: Partial<AutoResizeTextStyle> = {
  fontFamily: font,
  maxWidth: 1024,
  fontSize: 132,
  fontStyle: 'normal',
  fontWeight: '700',
  fill: ['#FF0100', '#FF8C00'], // gradient
  fillGradientStops: [0.1, 0.9],
  fillGradientType: PIXI.TEXT_GRADIENT.LINEAR_VERTICAL,
  align: 'center',
  stroke: '#FFFFFF',
  strokeThickness: 10,
  dropShadow: true,
  dropShadowColor: '#000000',
  dropShadowBlur: 0,
  dropShadowAngle: Math.PI / 4,
  dropShadowDistance: 10,
  lineJoin: 'round',
  dropShadowAlpha: 0.8,
};

export const totalWinAmountTextStyles: Partial<AutoResizeTextStyle> = {
  fontFamily: font,
  maxWidth: 1024,
  fontSize: 211,
  fontStyle: 'normal',
  fontWeight: '700',
  fill: ['#FF0100', '#FF8C00'], // gradient
  fillGradientStops: [0.1, 0.9],
  fillGradientType: PIXI.TEXT_GRADIENT.LINEAR_VERTICAL,
  align: 'center',
  stroke: '#FFFFFF',
  strokeThickness: 10,
  dropShadow: true,
  dropShadowColor: '#000000',
  dropShadowBlur: 0,
  dropShadowAngle: Math.PI / 4,
  dropShadowDistance: 10,
  dropShadowAlpha: 0.8,
};

export const respinResultRespinCountTextStyle: Partial<AutoResizeTextStyle> = {
  fontFamily: font,
  maxWidth: 256,
  fontSize: 100,
  fontStyle: 'normal',
  fontWeight: '700',
  fill: '#FA0124',
  align: 'left',
  stroke: '#FFFFFF',
  strokeThickness: 10,
  dropShadow: true,
  dropShadowColor: '#000000',
  dropShadowBlur: 0,
  dropShadowAngle: Math.PI / 4,
  dropShadowDistance: 8,
  dropShadowAlpha: 0.8,
};

export const respinResultRespinCountXTextStyle: Partial<AutoResizeTextStyle> = {
  ...respinResultRespinCountTextStyle,
  fontSize: 72,
  lineJoin: 'round',
};

export const respinResultRespinCountOkawariTextStyle: Partial<AutoResizeTextStyle> = {
  ...respinResultRespinCountTextStyle,
  fontSize: 72,
  lineJoin: 'round',
  maxWidth: 400,
};

export const soldOutYouWonTextStyle: Partial<AutoResizeTextStyle> = {
  fontFamily: font,
  maxWidth: 660,
  fontSize: 105,
  fontStyle: 'normal',
  fontWeight: '700',
  fill: ['#EE0B14 ', '#FF780D '], // gradient
  fillGradientStops: [0, 1],
  fillGradientType: PIXI.TEXT_GRADIENT.LINEAR_VERTICAL,
  align: 'center',
  stroke: '#FFFFFF',
  strokeThickness: 5,
  letterSpacing: 0,
  lineHeight: 36,
  dropShadow: true,
  dropShadowAngle: Math.PI / 4,
  dropShadowBlur: 0,
  dropShadowDistance: 5,
  dropShadowColor: '#000',
  lineJoin: 'round',
  dropShadowAlpha: 0.8,
};
