import { setCurrency } from '../../gql/cache';
import client from '../../gql/client';
import { getUserGql } from '../../gql/query';

export const useUserBalance = () => {
  const getUserBalance = async () => {
    const userBalance = await client.query({
      query: getUserGql,
      fetchPolicy: 'network-only',
    });
    setCurrency(userBalance.data.user.balance?.currency);
  };

  return {
    getUserBalance,
  };
};
