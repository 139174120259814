import ViewContainer from '../../components/container';
import { TickerSpine } from '../../components/spine';

class ReelsFrame extends ViewContainer {
  private frame: TickerSpine<'reelframe'>;

  constructor() {
    super();
    this.frame = new TickerSpine('reelframe');
    this.frame.position.set(-75 + 1475 / 2, -238 + 1090 / 2);
    this.frame.state.setAnimation(0, 'waku', true);

    this.addChild(this.frame);
  }
}

export default ReelsFrame;
