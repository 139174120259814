import Tween from '../../animations/tween';
import { CutinType, RespinFrameSkinNameCnv } from '../../announce/config';
import ViewContainer from '../../components/container';
import { TickerSpine } from '../../components/spine';
import { EventTypes, REEL_WIDTH, SLOT_HEIGHT, SLOT_RESOURCE_HEIGHT, SLOT_WIDTH, eventManager } from '../../config';

class ReelsBackgroundContainer extends ViewContainer {
  private bgSpine: TickerSpine<'reelframe'>;

  private respinReelFrame: TickerSpine<'respin_waku'>;

  private longspinReelFrame: TickerSpine<'longspin_waku'>;

  constructor() {
    super();

    this.bgSpine = new TickerSpine('reelframe');
    this.bgSpine.position.set(-75 + 1475 / 2, -238 + 1090 / 2);
    this.bgSpine.alpha = 0.5;
    this.addChild(this.bgSpine);

    this.bgSpine.state.setAnimation(0, 'base', true);
    //    this.bgSpine.stateData.setMix('base', 'longspin', 0.5);

    this.respinReelFrame = new TickerSpine('respin_waku');
    this.respinReelFrame.x = REEL_WIDTH * 2 + SLOT_WIDTH / 2;
    this.respinReelFrame.y = SLOT_HEIGHT * 1 + SLOT_RESOURCE_HEIGHT / 2;
    this.respinReelFrame.visible = false;
    this.respinReelFrame.state.setAnimation(0, 'base', true);
    this.respinReelFrame.skeleton.setSkinByName('a');
    this.addChild(this.respinReelFrame);

    this.longspinReelFrame = new TickerSpine('longspin_waku');
    this.longspinReelFrame.x = REEL_WIDTH * 4 + SLOT_WIDTH / 2;
    this.longspinReelFrame.y = SLOT_HEIGHT * 1 + SLOT_RESOURCE_HEIGHT / 2;
    this.longspinReelFrame.visible = false;
    this.longspinReelFrame.state.setAnimation(0, 'base', true);
    //    this.longspinReelFrame.skeleton.setSkinByName('default');
    this.addChild(this.longspinReelFrame);

    eventManager.on(EventTypes.CHANGE_MODE, this.onModeChange.bind(this));
    //    eventManager.on(EventTypes.MANUAL_CHANGE_BACKGROUND, this.onModeChange.bind(this));
    eventManager.addListener(EventTypes.START_SPIN_ANIMATION, this.onStartSpin.bind(this));
    eventManager.addListener(EventTypes.REEL_STOPPED, this.onReelStopped.bind(this));
    eventManager.addListener(EventTypes.REELS_STOPPED, this.onAnticipationAnimationEnd.bind(this));
    eventManager.addListener(EventTypes.ANTICIPATION_ANIMATIONS_START, this.onAnticipationAnimationStarts.bind(this));
    eventManager.addListener(EventTypes.ANTICIPATION_ANIMATIONS_END, this.onAnticipationAnimationEnd.bind(this));

    eventManager.addListener(EventTypes.START_CUTIN_ANIMATION, this.startReelFrameAnimation.bind(this));
    eventManager.addListener(EventTypes.CHANGE_RESPIN_REELFRAME, this.changeFrameOnReelSetting.bind(this));
    eventManager.addListener(EventTypes.FORCE_STOP_REELS, this.forceStopReels.bind(this));

    //    eventManager.addListener(EventTypes.SETUP_REEL_POSITIONS, this.setupCutinAnimation.bind(this));
  }

  private onModeChange(): void {}

  private onStartSpin(): void {
    this.respinReelFrame.visible = false;
  }

  private onReelStopped() {
    //wait slot stop animation
    const slotStopAnimationWait = Tween.createDelayAnimation(500);
    slotStopAnimationWait.addOnComplete(() => {
      this.respinReelFrame.visible = false;
      this.respinReelFrame.state.setEmptyAnimation(0);
    });
    slotStopAnimationWait.start();
  }

  private forceStopReels(_isTurboSpin: boolean, isSkipOnlyStarting?: boolean) {
    this.respinReelFrame.visible = false;
    this.respinReelFrame.state.setEmptyAnimation(0);

    if (!isSkipOnlyStarting) {
      this.longspinReelFrame.visible = false;
      this.longspinReelFrame.state.setEmptyAnimation(0);
    }
  }

  private startReelFrameAnimation() {}

  private changeFrameOnReelSetting(cutinType: CutinType) {
    this.respinReelFrame.state.setAnimation(0, 'base', true);
    this.respinReelFrame.skeleton.setSkinByName(RespinFrameSkinNameCnv[cutinType]);
    this.respinReelFrame.visible = true;
  }

  private onAnticipationAnimationStarts(): void {
    this.longspinReelFrame.state.setAnimation(0, 'in', false);
    this.longspinReelFrame.state.addAnimation(0, 'base', true);
    this.longspinReelFrame.update(0);
    this.longspinReelFrame.visible = true;
  }

  private onAnticipationAnimationEnd(): void {
    const slotStopAnimationWait = Tween.createDelayAnimation(500);
    slotStopAnimationWait.addOnComplete(() => {
      this.longspinReelFrame.visible = false;
    });
    slotStopAnimationWait.start();
  }
}

export default ReelsBackgroundContainer;
