import { makeVar } from '@apollo/client';
import { ResultOf } from '@graphql-typed-document-node/core';

import { getUserConfig } from '@phoenix7dev/utils-fe';

import { SlotId, config } from '../config';
import { GameMode, PopupOpeningTypes } from '../consts';
import { ISettledBet, ReelSet, RespinSymbolType, UserBonus } from '../global.d';

import { IConfig, IProgress, ISlotConfig, IStressful } from './d';
import { historyNodeFragment } from './fragment';
import { getBonuses } from './query';

export const setIsAuthorized = makeVar<boolean>(false);

export const setProgress = makeVar<IProgress>({
  status: 0,
  wasLoaded: false,
});

export const setIsSoundOn = makeVar<boolean>(getUserConfig<IConfig>('config', 'isSoundOn', true));

export const setIsRevokeThrowingError = makeVar<boolean>(false);

export const setPrevReelsPosition = makeVar<number[]>([0, 0, 0, 0, 0]);

export const setPrevReelSet = makeVar<ReelSet>({ id: '', layout: [] });

export const setIsSpinInProgress = makeVar<boolean>(false);

export const setIsMobile = makeVar<boolean>(false);

export const setIsSlotBusy = makeVar<boolean>(false);

export const setBetAmount = makeVar<number>(20);

export const setCurrency = makeVar<string>('FUN');

export const setBrokenGame = makeVar<boolean>(false);

export const setBrokenBuyFeatureGame = makeVar<string>('');

export const setCurrentFreeSpinsTotalWin = makeVar<number>(0);

export const setCoinAmount = makeVar<number>(1);

export const setCoinValue = makeVar<number>(1);

export const setWinAmount = makeVar<number>(0);

export const setAutoSpinsAmount = makeVar<number>(config.autoplay.defaultAutoSpinsAmount);

export const setStopOnWinExceeds = makeVar<number>(0);

export const setStopOnBalanceIncrease = makeVar<number>(0);

export const setStopOnBalanceDecrease = makeVar<number>(0);

export const setIsStopOnAnyWin = makeVar<boolean>(false);

export const setIsStopOnWinExceeds = makeVar<boolean>(false);

export const setIsStopOnBalanceIncrease = makeVar<boolean>(false);

export const setIsStopOnBalanceDecrease = makeVar<boolean>(false);

export const setIsStopOnFeatureWin = makeVar<boolean>(true);

export const setLastRegularWinAmount = makeVar<number>(0);

export const setIsAutoSpins = makeVar<boolean>(false);

export const setIsFreeSpinsWin = makeVar<boolean>(false);

export const setStopOnBalanceIncreaseSliderValue = makeVar<number>(1);

export const setStopOnWinExceedsSliderValue = makeVar<number>(1);

export const setIsContinueAutoSpinsAfterFeature = makeVar<boolean>(false);

export const setAutoSpinsLeft = makeVar<number>(0);

// todo replace with real backend logic
export const setFreeSpinsTotalWin = makeVar<number>(0);

export const setGameMode = makeVar<GameMode>(GameMode.REGULAR);

export const setReelSetId = makeVar<string>('');

export const setDefaultReelSetId = makeVar<string>('');

export const setNextResult = makeVar<ISettledBet | null>(null);

export const setUserLastBetResult = makeVar<ResultOf<typeof historyNodeFragment> | null>(null);

export const setCurrentBonus = makeVar<UserBonus>({
  id: '',
  isActive: false,
  bonusId: '',
  rounds: 0,
  roundsPlayed: 0,
  totalWinAmount: 0,
  coinAmount: 1,
  betId: '',
  bonus: null,
  status: 'INACTIVE',
  data: {},
  coinValue: 0,
});

export const setAutoSpinsStartBalance = makeVar<number>(0);

export const setSlotConfig = makeVar<ISlotConfig>({
  id: '',
  lineSet: {
    id: '',
    lines: [],
    coinAmountMultiplier: 0,
  },
  clientSettings: null,
  lines: [],
  sessionId: '',
  reels: [],
  icons: [],
  settings: {
    startPosition: [],
  },
});

export const setBonuses = makeVar<ResultOf<typeof getBonuses>['bonuses']>([]);

export const setIsTurboSpin = makeVar<boolean>(getUserConfig<IConfig>('config', 'isTurboSpin', false));

export const setStressful = makeVar<IStressful>({
  show: false,
  type: 'none',
  message: '',
});

export const setIsOpenedMessageBanner = makeVar<boolean>(false);
export const setIsInTransition = makeVar<boolean>(false);

export const setIsSpinShortCut = makeVar<boolean>(false);

export const setIsRespinRevivalWaiting = makeVar<boolean>(false);

export const setIsBuyFeaturePopupOpened = makeVar<boolean>(false);

export const setIsBuyFeatureSpin = makeVar<boolean>(false);

export const setIsOpeningScenePlaying = makeVar<boolean>(false);

export const setRespinSymbolType = makeVar<RespinSymbolType>('E');

export const setAnticipationSymbolType = makeVar<SlotId>(SlotId.X);

////////
export const setIsOpenInfoPopup = makeVar<boolean>(false);
export const setIsOpenBetSettingsPopup = makeVar<boolean>(false);
export const setIsOpenHistoryPopup = makeVar<boolean>(false);

export const setIsOpenAutoplayPopup = makeVar<boolean>(false);

export const setIsPopupOpeningInProgress = makeVar<PopupOpeningTypes>(PopupOpeningTypes.NONE);

export const setIsAllowForceReelStop = makeVar<boolean>(false);

export const setRespinCnt = makeVar<number>(0);

export const setIsRespinRevivePattern = makeVar<boolean>(false);

export const setIsTimeoutErrorMessage = makeVar(false);

export const setResumeRespinResult = makeVar<boolean>(false);

export const setIsBuyFeaturePurchased = makeVar<boolean>(false);
