import { Colors } from '../../config/variables';
import { setIsTurboSpin } from '../../gql/cache';
import { calcPercentage } from '../../utils';
import { UiButton } from '../components/ui/uiButton';
import { EventTypes, eventManager } from '../config';

export class TurboSpinBtn extends UiButton {
  constructor() {
    super(setIsTurboSpin() ? 'turbospin-on' : 'turbospin-off');
    this.btn.tint = setIsTurboSpin() ? Colors.GAME_COLOR : Colors.WHITE_COLOR;
    this.interactive = !this.isDisabled;
    this.on('click', () => this.handleClick());
    this.on('touchstart', () => this.handleClick());
    this.initSubscriptions();
  }

  private initSubscriptions = (): void => {
    eventManager.on(EventTypes.TOGGLE_TURBO_SPIN, (isTurboSpin: boolean) => {
      this.handleUpdateIntent(isTurboSpin);
    });

    eventManager.on(EventTypes.OPEN_BUY_FEATURE_POPUP, () => {
      this.handleDisable(true);
    });

    eventManager.on(EventTypes.CLOSE_BUY_FEATURE_POPUP, () => {
      this.handleDisable(false);
    });
  };

  private handleDisable(flg: boolean): void {
    this.setDisable(flg);
    this.interactive = !this.isDisabled;
  }

  private handleClick = (): void => {
    setIsTurboSpin(!setIsTurboSpin());
    this.handleUpdateIntent(setIsTurboSpin());
  };

  private handleUpdateIntent(isTurboSpin: boolean): void {
    if (isTurboSpin) {
      this.btn.tint = Colors.GAME_COLOR;
      this.updateIntent('turbospin-on');
    } else {
      this.btn.tint = Colors.WHITE_COLOR;
      this.updateIntent('turbospin-off');
    }
  }

  public override handlePosition(): void {
    this.btn.anchor.set(1, 0);
    let size = calcPercentage(this.applicationSize.width, 7.5);
    const gap = calcPercentage(this.applicationSize.width, 1.25);

    let x = this.applicationSize.width - gap - size;
    let y = gap;
    this.setSize(size);

    if (this.isPortraitMode) {
      size = calcPercentage(this.applicationSize.height, 7.5);
      this.btn.anchor.set(0, 1);
      x = gap;
      y = this.applicationSize.height - gap;
      this.setSize(size);
    }

    // if (this.isLandscapeMode) {}

    this.x = x;
    this.y = y;
  }
}
