import { MessageFreeSpinsBannerProps } from '../../global';
import { TickerSpine } from '../components/spine/index';
import {
  SAFE_AREA_LANDSCAPE_HEIGHT,
  SAFE_AREA_LANDSCAPE_PIVOT_X,
  SAFE_AREA_LANDSCAPE_PIVOT_Y,
  SAFE_AREA_LANDSCAPE_WIDTH,
  SAFE_AREA_PORTRAIT_HEIGHT,
  SAFE_AREA_PORTRAIT_PIVOT_X,
  SAFE_AREA_PORTRAIT_PIVOT_Y,
  SAFE_AREA_PORTRAIT_WIDTH,
} from '../config';

import { BaseMessageBanner } from './baseMessageBanner';

export class MessageFreeSpinsBanner extends BaseMessageBanner {
  protected override props: MessageFreeSpinsBannerProps;

  private bg: TickerSpine<'respin_title'>;

  constructor(props: MessageFreeSpinsBannerProps) {
    super(props);
    this.props = props;

    this.bg = new TickerSpine<'respin_title'>('respin_title');
    this.bg.update(0);
  }

  public override init(): MessageFreeSpinsBanner {
    super.init();
    this.bg.state.setAnimation(0, 'in', false);
    this.bg.state.addAnimation(0, 'loop', true);
    this.addChild(this.bg);
    return this;
  }

  public override handleResize(width: number, height: number): void {
    super.handleResize(width, height);
    if (height > width) {
      this.x = SAFE_AREA_PORTRAIT_WIDTH / 2 + SAFE_AREA_PORTRAIT_PIVOT_X;
      this.y = SAFE_AREA_PORTRAIT_HEIGHT / 2 + SAFE_AREA_PORTRAIT_PIVOT_Y - 125;
    } else {
      this.x = SAFE_AREA_LANDSCAPE_WIDTH / 2 + SAFE_AREA_LANDSCAPE_PIVOT_X;
      this.y = SAFE_AREA_LANDSCAPE_HEIGHT / 2 + SAFE_AREA_LANDSCAPE_PIVOT_Y - 105;
    }
  }
}
