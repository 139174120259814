import * as PIXI from 'pixi.js';

import { SlotId } from '../../../config';
import Animation from '../../animations/animation';
import { AnimationStateListener, TickerSpine } from '../../components/spine';
import { SLOT_SCALE } from '../../config';

import { IAnimateSlot } from './IAnimateSlot';

declare global {
  interface String {
    toLowerCase<T extends string>(this: T): Lowercase<T>;
  }
}

type lowerCaseSymbol = Lowercase<(typeof SlotId)[keyof typeof SlotId]>;

export class SpineAnimateSlot extends TickerSpine<`symbol_${lowerCaseSymbol}`> implements IAnimateSlot {
  public slotId: SlotId;

  constructor(slotId: SlotId) {
    super(`symbol_${slotId.toLowerCase()}`);

    this.slotId = slotId;
    this.scale.set(SLOT_SCALE);
    this.state.setEmptyAnimation(0);
  }

  public startSpinStartAnimation(): void {
    this.state.setAnimation(0, 'spin', false);
    this.state.addAnimation(0, 'spin_after', true);
    //    this.state.addEmptyAnimation(0, 0, 0);
  }

  public startStopAnimation(): void {
    this.state.setAnimation(0, 'stop', false);
    this.state.addEmptyAnimation(0, 0, 0);
  }

  public startLongSpinStopAnimation(): void {
    this.state.setAnimation(0, 'longspin_stop', false);
    this.state.addAnimation(0, 'longspin', true);
    //    this.state.addEmptyAnimation(0, 0, 0);
  }

  public startWinAnimation(): void {
    this.state.setAnimation(0, 'win', false);
    this.state.addEmptyAnimation(0, 0, 0);
  }

  public startLongspinAnimation(): void {
    this.state.setAnimation(0, 'longspin', true);
  }

  public getWinAnimation(): Animation {
    const animation = new Animation({});
    animation.duration = this.skeleton.data.findAnimation('win').duration * 1000;

    const listener: AnimationStateListener = {
      complete: () => {
        PIXI.Ticker.shared.addOnce(() => {
          animation.onComplete();
        });
      },
    };

    animation.addOnStart(() => {
      PIXI.Ticker.shared.addOnce(() => {
        this.startWinAnimation();
        this.state.addListener(listener);
      });
    });
    animation.addOnSkip(() => {
      this.state.removeListener(listener);
      this.state.setEmptyAnimation(0, 0);
    });
    animation.addOnComplete(() => {
      this.state.removeListener(listener);
      this.state.setEmptyAnimation(0, 0);
    });

    return animation;
  }

  public skip(): void {
    this.state.setEmptyAnimation(0);
  }
}
