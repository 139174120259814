import { setCoinValue, setSlotConfig } from './cache';
import { IBetSettings, ISlotConfig } from './d';

export const getBetsSetting = (): IBetSettings => {
  const { lineSet, clientSettings } = setSlotConfig() as RecursiveNonNullable<ISlotConfig>;

  const minBet = clientSettings.coinAmounts.default[0] ?? 0;
  const maxBet = clientSettings.coinAmounts.default[clientSettings.coinAmounts.default.length - 1] ?? 0;
  const bets = clientSettings.coinAmounts.default;
  const { quick } = clientSettings.coinAmounts;
  const coinValue = setCoinValue();

  const minBetCount = (minBet * lineSet.coinAmountMultiplier * coinValue) / 100;
  const maxBetCount = (maxBet * lineSet.coinAmountMultiplier * coinValue) / 100;

  return {
    minBet,
    maxBet,
    bets,
    quick,
    coinValue,
    minBetCount,
    maxBetCount,
  };
};
