import { SlotId } from '../../config';
import { AnticipationInfo, ReelSet, ReelStopSoundType } from '../../global.d';
import { setAnticipationSymbolType, setGameMode, setNextResult } from '../../gql/cache';
import {
  findBuyFeatureType,
  getRespinRoundCount,
  getSpecialRounds,
  isBuyFeatureMode,
  isFreeSpinsMode,
} from '../../utils';
import Tween from '../animations/tween';
import { AnnounceType, ReelRollingExternalDuration } from '../announce/config';
import ViewContainer from '../components/container';
import {
  ANTICIPATION_LONG_ENDING_DURATION,
  ANTICIPATION_LONG_ENDING_SLOTS_AMOUNT,
  ANTICIPATION_LONG_EXTERNAL_ROLLING_DURATION,
  ANTICIPATION_LONG_START_REELID,
  ANTICIPATION_REEL_FORMULA,
  ANTICIPATION_SHORT_ENDING_DURATION,
  ANTICIPATION_SHORT_ENDING_SLOTS_AMOUNT,
  ANTICIPATION_SHORT_ROLLING_DURATION,
  ANTICIPATION_SHORT_START_REELID,
  BASE_REEL_ROLLING_SPEED,
  BASE_SPIN_TIME,
  EventTypes,
  FORCE_STOP_SPIN_ANIMATION_DURATION,
  FORCE_STOP_SPIN_PER_EACH_DURATION,
  REELS_AMOUNT,
  REEL_BUYFEATURE_MYSTERY_ENDING_AMOUNT,
  REEL_BUYFEATURE_MYSTERY_ENDING_DURATION,
  REEL_BUYFEATURE_ROLLING_DURATION,
  REEL_ENDING_SLOTS_AMOUNT,
  RESPIN_REEL_ENDING_MULTI_DURATION,
  RESPIN_REEL_ENDING_SLOTS_AMOUNT,
  RESPIN_REEL_INDEX,
  ReelState,
  SLOTS_CONTAINER_HEIGHT,
  SLOTS_CONTAINER_WIDTH,
  SLOTS_PER_REEL_AMOUNT,
  TURBO_REEL_ROLLING_SPEED,
  TURBO_SPIN_TIME,
  eventManager,
} from '../config';

import Reel from './reel';

class ReelsContainer extends ViewContainer {
  public reels: Reel[] = [];

  public forcedStop = false;

  constructor(reels: SlotId[][], startPosition: number[]) {
    super();
    this.initContainer();
    this.initReels(reels, startPosition);
    eventManager.addListener(EventTypes.SHOW_STOP_SLOTS_DISPLAY, () => {
      this.hideSlots();
    });
    eventManager.addListener(EventTypes.REEL_STOPPED, this.hideSlots.bind(this));
    eventManager.addListener(EventTypes.HIDE_STOP_SLOTS_DISPLAY, this.showSlots.bind(this));
    eventManager.addListener(EventTypes.SETUP_REEL_POSITIONS, this.setupAnimationTarget.bind(this));
    eventManager.addListener(EventTypes.FORCE_STOP_REELS, this.forceStopReelsBranching.bind(this));
    eventManager.addListener(EventTypes.CHANGE_REEL_SET, this.changeReelSet.bind(this));
    eventManager.addListener(EventTypes.RESPIN_CHANGE_REEL_SET, this.changeReelSetRespin.bind(this));
    eventManager.addListener(EventTypes.ROLLBACK_REELS, this.rollbackReels.bind(this));
    this.sortableChildren = true;
  }

  private hideSlots(reelId?: number): void {
    const arr = [];
    if (isFreeSpinsMode(setGameMode())) {
      for (let i = 0; i < REELS_AMOUNT * SLOTS_PER_REEL_AMOUNT; i++) {
        arr.push(i);
      }
    } else {
      if (reelId !== undefined) {
        for (let i = 0; i < SLOTS_PER_REEL_AMOUNT; i++) {
          arr.push(i * REELS_AMOUNT + reelId);
        }
      } else {
        for (let i = 0; i < REELS_AMOUNT * SLOTS_PER_REEL_AMOUNT; i++) {
          arr.push(i);
        }
      }
    }
    this.setSlotsVisibility(arr, false);
  }

  private showSlots(reelId?: number): void {
    const arr = [];
    if (reelId !== undefined) {
      for (let i = 0; i < SLOTS_PER_REEL_AMOUNT; i++) arr.push(i * REELS_AMOUNT + reelId);
    } else {
      for (let i = 0; i < REELS_AMOUNT * SLOTS_PER_REEL_AMOUNT; i++) arr.push(i);
    }
    this.setSlotsVisibility(arr, true);
  }

  private rollbackReels(positions: number[]): void {
    for (let i = 0; i < positions.length; i++) {
      eventManager.emit(EventTypes.REMOVE_TWEEN_ANIMATION, this.reels[i]!.spinAnimation?.getStarting() as Tween);
      eventManager.emit(EventTypes.REMOVE_TWEEN_ANIMATION, this.reels[i]!.spinAnimation?.getFirstRolling() as Tween);
      eventManager.emit(EventTypes.REMOVE_TWEEN_ANIMATION, this.reels[i]!.spinAnimation?.getFakeRolling() as Tween);
      this.reels[i]!.position = this.reels[i]!.size - positions[i]!;
      this.reels[i]!.state = ReelState.IDLE;
    }
  }

  private initContainer(): void {
    this.width = SLOTS_CONTAINER_WIDTH;
    this.height = SLOTS_CONTAINER_HEIGHT;

    //reference point
    this.x = 0;
    this.y = 0;
  }

  private changeReelSet(settings: { reelSet: ReelSet; reelPositions: number[] }): void {
    const reelLayout = settings.reelSet.layout.map((reel) =>
      reel.length < SLOTS_PER_REEL_AMOUNT + 2 ? [...reel, ...reel] : reel,
    );

    const reelPositions = settings.reelPositions
      .slice(0, REELS_AMOUNT)
      .map((position, idx) => (reelLayout[idx]!.length - position) % reelLayout[idx]!.length);

    for (let i = 0; i < REELS_AMOUNT; i++) {
      this.reels[i]!.clean();
      this.reels[i]!.init(reelLayout[i]!, reelPositions[i]!);
    }
  }

  private changeReelSetRespin(settings: { reelSet: ReelSet; reelPositions: number[] }): void {
    const reelLayout = settings.reelSet.layout!.map((reel) =>
      reel.length < SLOTS_PER_REEL_AMOUNT + 2 ? [...reel, ...reel] : reel,
    );
    const reelPositions = settings.reelPositions
      .slice(0, REELS_AMOUNT)
      .map((position) => (reelLayout[0]!.length - position) % reelLayout[0]!.length);
    this.reels[RESPIN_REEL_INDEX]!.clean();
    this.reels[RESPIN_REEL_INDEX]!.init(reelLayout[0]!, reelPositions[RESPIN_REEL_INDEX]!);
  }

  private initReels(reels: SlotId[][], startPosition?: number[]): void {
    reels = reels.map((reel) => (reel.length < SLOTS_PER_REEL_AMOUNT + 2 ? [...reel, ...reel] : reel));

    for (let i = 0; i < REELS_AMOUNT; i++) {
      const position = startPosition ? startPosition[i]! : 0;
      const reel = new Reel(i, reels[i]!, position);
      this.reels[i] = reel;
      this.addChild(reel.container);

      eventManager.emit(EventTypes.REGISTER_ANIMATOR, reel.animator);
    }
  }

  private forceStopReelsBranching(isTurboSpin: boolean, isLongSpin?: boolean): void {
    if (!isLongSpin || isFreeSpinsMode(setGameMode())) {
      this.forceStopReels(isTurboSpin);
    } else {
      this.forceStopReelsExcept5Th();
    }
  }

  private forceStopReels(isTurboSpin: boolean): void {
    this.forcedStop = true;
    const startTime = this.reels[0]!.spinAnimation ? this.reels[0]!.spinAnimation!.startTime : 0;
    const stopAllReelsAtSameTime = Date.now() - startTime < (isTurboSpin ? TURBO_SPIN_TIME : BASE_SPIN_TIME);

    if (stopAllReelsAtSameTime) {
      let [maxSoundNo, maxSoundIdx] = ['normal', 2];
      for (let i = 0; i < this.reels.length; i++) {
        if (this.reels[i]!.stopSoundSymbolNo === '5of') {
          [maxSoundNo, maxSoundIdx] = [this.reels[i]!.stopSoundSymbolNo, i];
        } else if (this.reels[i]!.stopSoundSymbolNo === '4of' && (maxSoundNo === 'normal' || maxSoundNo === '3of')) {
          [maxSoundNo, maxSoundIdx] = [this.reels[i]!.stopSoundSymbolNo, i];
        } else if (this.reels[i]!.stopSoundSymbolNo === '3of' && maxSoundNo === 'normal') {
          [maxSoundNo, maxSoundIdx] = [this.reels[i]!.stopSoundSymbolNo, i];
        }
        this.reels[i]!.isPlaySoundOnStop = false;
      }
      this.reels[maxSoundIdx]!.isPlaySoundOnStop = true;
    }

    if (isFreeSpinsMode(setGameMode())) {
      this.reels[RESPIN_REEL_INDEX]!.stopReel(
        stopAllReelsAtSameTime ? FORCE_STOP_SPIN_ANIMATION_DURATION : FORCE_STOP_SPIN_ANIMATION_DURATION + 0,
      );
    } else {
      for (let i = 0; i < this.reels.length; i++) {
        this.reels[i]!.stopReel(
          stopAllReelsAtSameTime
            ? FORCE_STOP_SPIN_ANIMATION_DURATION
            : FORCE_STOP_SPIN_ANIMATION_DURATION + i * FORCE_STOP_SPIN_PER_EACH_DURATION,
        );
      }
      eventManager.emit(EventTypes.ANTICIPATION_ANIMATIONS_END);
    }
  }

  private forceStopReelsExcept5Th(): void {
    for (let i = 0; i < this.reels.length; i++) {
      if (i === REELS_AMOUNT - 1) {
        this.reels[i]!.stopReel(0);
      } else {
        this.reels[i]!.stopReel(FORCE_STOP_SPIN_ANIMATION_DURATION + i * FORCE_STOP_SPIN_PER_EACH_DURATION);
      }
    }
  }

  private prolongTarget = (reel: Reel, minValue: number): number => {
    let res = 0;
    while (res < minValue) res += reel.data.length;
    return res;
  };

  private setAnimationfromCalculatedTarget(
    rollingAnimation: Tween,
    endingAnimation: Tween,
    target: number,
    endingSlotAmount: number,
    rollingSpeed: number,
    reelIdx: number,
  ): void {
    let beginValue = target - endingSlotAmount - Math.round(rollingAnimation.duration * rollingSpeed);
    if (beginValue < 0) {
      const prolong = this.prolongTarget(this.reels[reelIdx]!, Math.abs(beginValue));
      beginValue += prolong;
      target += prolong;
    }
    rollingAnimation.propertyBeginValue = beginValue;
    rollingAnimation.target = target - endingSlotAmount;

    endingAnimation.propertyBeginValue = target - endingSlotAmount;
    endingAnimation.target = target;
  }

  private setupBaseGameAnimationTarget(
    reelPositions: number[],
    reelStopSounds: ReelStopSoundType[],
    anticipationInfo: AnticipationInfo[],
    announceType: AnnounceType,
  ): void {
    const anticipationSymbolMaxObj = anticipationInfo.reduce((max, info) =>
      info.symbolCount > max.symbolCount ? info : max,
    );
    const rollingSpeed = this.reels[0]!.isTurboSpin ? TURBO_REEL_ROLLING_SPEED : BASE_REEL_ROLLING_SPEED;
    const anticipation_type = Math.floor(Math.random() * ANTICIPATION_LONG_ENDING_DURATION.length);
    const endingSlotAmount = ANTICIPATION_LONG_ENDING_SLOTS_AMOUNT[anticipation_type]!;
    const anticipation_duration = ANTICIPATION_LONG_ENDING_DURATION[anticipation_type]!;
    setAnticipationSymbolType(anticipationSymbolMaxObj.symbol);

    for (let j = 0; j < this.reels.length; j++) {
      const fakeRollingAnimation = this.reels[j]!.spinAnimation!.getFakeRolling();
      const rollingAnimation = this.reels[j]!.spinAnimation!.getRolling();
      const endingAnimation = this.reels[j]!.spinAnimation!.getEnding();
      const target = this.reels[j]!.getTarget(this.reels[j]!.data.length - reelPositions[j]!);

      fakeRollingAnimation.duration = 0;
      this.reels[j]!.stopSoundSymbolNo = reelStopSounds[j]!;

      rollingAnimation.duration += ReelRollingExternalDuration[announceType];
      // include gase
      if (ANTICIPATION_SHORT_START_REELID <= anticipationSymbolMaxObj.symbolCount) {
        if (j === ANTICIPATION_SHORT_START_REELID) {
          rollingAnimation.duration += ANTICIPATION_SHORT_ROLLING_DURATION;
        } else if (j === ANTICIPATION_LONG_START_REELID) {
          rollingAnimation.duration += ANTICIPATION_SHORT_ROLLING_DURATION + ANTICIPATION_SHORT_ENDING_DURATION;
        }
      }

      //5th
      if (
        ANTICIPATION_LONG_START_REELID <= anticipationSymbolMaxObj.symbolCount &&
        j === ANTICIPATION_LONG_START_REELID
      ) {
        rollingAnimation.duration += ANTICIPATION_LONG_EXTERNAL_ROLLING_DURATION;

        this.setAnimationfromCalculatedTarget(
          rollingAnimation,
          endingAnimation,
          target,
          endingSlotAmount,
          rollingSpeed,
          j,
        );
        endingAnimation.duration += anticipation_duration;
        endingAnimation.easing = ANTICIPATION_REEL_FORMULA;
        endingAnimation.addOnComplete(() => {
          if (setNextResult() && !getSpecialRounds()) {
            eventManager.emit(EventTypes.ANTICIPATION_ANIMATIONS_END);
          }
        });
      } else if (
        ANTICIPATION_SHORT_START_REELID <= anticipationSymbolMaxObj.symbolCount &&
        j === ANTICIPATION_SHORT_START_REELID
      ) {
        //4th
        this.setAnimationfromCalculatedTarget(
          rollingAnimation,
          endingAnimation,
          target,
          ANTICIPATION_SHORT_ENDING_SLOTS_AMOUNT,
          rollingSpeed,
          j,
        );
        endingAnimation.duration += ANTICIPATION_SHORT_ENDING_DURATION;
        endingAnimation.easing = ANTICIPATION_REEL_FORMULA;
        endingAnimation.addOnStart(() => {});

        endingAnimation.addOnComplete(() => {
          if (ANTICIPATION_LONG_START_REELID <= anticipationSymbolMaxObj.symbolCount) {
            const reachSymbols = anticipationInfo
              .map((info) => (info.symbolCount >= ANTICIPATION_LONG_START_REELID ? info.symbol : undefined))
              .filter((symbol): symbol is SlotId => symbol !== undefined);
            eventManager.emit(EventTypes.ANTICIPATION_SLOT_ANIMATIONS_START, reachSymbols, j);
            eventManager.emit(EventTypes.ANTICIPATION_ANIMATIONS_START, anticipationSymbolMaxObj.symbol);
            eventManager.emit(EventTypes.ANTICIPATION_SLOT_ANIMATIONS_END, reachSymbols, j);
            eventManager.emit(EventTypes.ANTICIPATION_STARTS, ANTICIPATION_LONG_START_REELID, reachSymbols);
          } else {
            eventManager.emit(EventTypes.ANTICIPATION_ANIMATIONS_END);
            eventManager.emit(EventTypes.ANTICIPATION_SLOT_ANIMATIONS_END, [], j);
          }
        });
      } else {
        this.setAnimationfromCalculatedTarget(
          rollingAnimation,
          endingAnimation,
          target,
          REEL_ENDING_SLOTS_AMOUNT,
          rollingSpeed,
          j,
        );
        endingAnimation.addOnComplete(() => {
          if (
            ANTICIPATION_SHORT_START_REELID <= anticipationSymbolMaxObj.symbolCount &&
            ANTICIPATION_SHORT_START_REELID === j + 1
          ) {
            const reachSymbols = anticipationInfo
              .map((info) => (info.symbolCount >= ANTICIPATION_SHORT_START_REELID ? info.symbol : undefined))
              .filter((symbol): symbol is SlotId => symbol !== undefined);

            eventManager.emit(EventTypes.ANTICIPATION_SLOT_ANIMATIONS_START, reachSymbols!, j);
          }
        });
      }
    }
  }

  private setupRespinAnimationTarget(reelPositions: number[]): void {
    const isTurboSpin = this.reels[0]!.isTurboSpin;

    const rollingSpeed = this.reels[0]!.isTurboSpin ? TURBO_REEL_ROLLING_SPEED : BASE_REEL_ROLLING_SPEED;

    const fakeRollingAnimation = this.reels[RESPIN_REEL_INDEX]!.spinAnimation!.getFakeRolling();
    const rollingAnimation = this.reels[RESPIN_REEL_INDEX]!.spinAnimation!.getRolling();
    const endingAnimation = this.reels[RESPIN_REEL_INDEX]!.spinAnimation!.getEnding();
    const target = this.reels[RESPIN_REEL_INDEX]!.getTarget(
      this.reels[RESPIN_REEL_INDEX]!.data.length - reelPositions[0]!,
    );

    //if failed, to increase successCnt
    const roundCnt = getRespinRoundCount(setNextResult());

    const respinCnt =
      roundCnt > RESPIN_REEL_ENDING_MULTI_DURATION.length - 1 ? RESPIN_REEL_ENDING_MULTI_DURATION.length - 1 : roundCnt;

    fakeRollingAnimation.duration = 0;

    rollingAnimation.duration += isTurboSpin
      ? ANTICIPATION_LONG_EXTERNAL_ROLLING_DURATION / 2
      : ANTICIPATION_LONG_EXTERNAL_ROLLING_DURATION;
    this.setAnimationfromCalculatedTarget(
      rollingAnimation,
      endingAnimation,
      target,
      RESPIN_REEL_ENDING_SLOTS_AMOUNT[respinCnt]!,
      rollingSpeed,
      RESPIN_REEL_INDEX,
    );
    endingAnimation.duration += isTurboSpin
      ? RESPIN_REEL_ENDING_MULTI_DURATION[respinCnt]! / 2
      : RESPIN_REEL_ENDING_MULTI_DURATION[respinCnt]!;

    endingAnimation.easing = ANTICIPATION_REEL_FORMULA;
  }

  private setupBuyFeatureAnimationTarget(reelPositions: number[]): void {
    //    const rollingSpeed = BASE_REEL_ROLLING_SPEED;
    const isTurboSpin = this.reels[0]!.isTurboSpin;
    const rollingSpeed = isTurboSpin ? TURBO_REEL_ROLLING_SPEED : BASE_REEL_ROLLING_SPEED;

    const buyFeatureType = findBuyFeatureType(setNextResult());
    const endingAnimationType =
      buyFeatureType === 'M' ? Math.floor(Math.random() * REEL_BUYFEATURE_MYSTERY_ENDING_DURATION.length) : 0;
    const endingSlotAmount = REEL_BUYFEATURE_MYSTERY_ENDING_AMOUNT[endingAnimationType]!;
    const endingAnimationDuration = isTurboSpin
      ? REEL_BUYFEATURE_MYSTERY_ENDING_DURATION[endingAnimationType]! / 2
      : REEL_BUYFEATURE_MYSTERY_ENDING_DURATION[endingAnimationType]!;

    for (let j = 0; j < this.reels.length; j++) {
      const fakeRollingAnimation = this.reels[j]!.spinAnimation!.getFakeRolling();
      const rollingAnimation = this.reels[j]!.spinAnimation!.getRolling();
      const endingAnimation = this.reels[j]!.spinAnimation!.getEnding();
      let target = this.reels[j]!.getTarget(this.reels[j]!.data.length - reelPositions[j]!);

      fakeRollingAnimation.duration = 0;
      rollingAnimation.duration = this.reels[0]!.isTurboSpin
        ? REEL_BUYFEATURE_ROLLING_DURATION / 2
        : REEL_BUYFEATURE_ROLLING_DURATION;

      let beginValue = target - endingSlotAmount - Math.round(rollingAnimation.duration * rollingSpeed);
      if (beginValue < 0) {
        const prolong = this.prolongTarget(this.reels[j]!, Math.abs(beginValue));
        beginValue += prolong;
        target += prolong;
      }
      rollingAnimation.propertyBeginValue = beginValue;
      rollingAnimation.target = target - endingSlotAmount;

      endingAnimation.duration += endingAnimationDuration;
      endingAnimation.propertyBeginValue = target - endingSlotAmount;
      endingAnimation.target = target;
      endingAnimation.addOnComplete(() => {});
    }
  }

  private setupAnimationTarget(
    reelPositions: number[],
    reelStopSounds: ReelStopSoundType[],
    anticipationInfo: AnticipationInfo[],
    announceType: AnnounceType,
  ): void {
    eventManager.emit(EventTypes.START_ANNOUNCEMENT, announceType);

    if (announceType === 'phoenix') {
      eventManager.emit(EventTypes.PHOENIX_START);
    }

    if (isBuyFeatureMode()) {
      this.setupBuyFeatureAnimationTarget(reelPositions);
    } else if (isFreeSpinsMode(setGameMode())) {
      this.setupRespinAnimationTarget(reelPositions);
    } else {
      this.setupBaseGameAnimationTarget(reelPositions, reelStopSounds, anticipationInfo, announceType);
    }
  }

  private setSlotsVisibility(slots: number[], visibility: boolean): void {
    slots.forEach((slotId) => {
      const x = slotId % REELS_AMOUNT;
      const y = Math.floor(slotId / REELS_AMOUNT);
      const position = this.reels[x]!.size - (Math.round(this.reels[x]!.position) % this.reels[x]!.size) + y - 1;
      const normalizedPosition = position === -1 ? this.reels[x]!.size - 1 : position % this.reels[x]!.size;
      const slot = this.reels[x]!.slots[normalizedPosition];
      if (slot) slot.visible = visibility;
    });
  }
}

export default ReelsContainer;
