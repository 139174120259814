import { Event } from '@pixi-spine/all-4.1';

import AudioApi from '@phoenix7dev/audio-api';

import { ISongs, durationOf } from '../../config/audio';
import { ReelStopSoundType, RespinReelType } from '../../global.d';
import { setGameMode, setNextResult, setRespinSymbolType } from '../../gql/cache';
import { getRespinReelType, isFreeSpinsMode } from '../../utils';
import AnimationGroup from '../animations/animationGroup';
import Tween from '../animations/tween';
import ViewContainer from '../components/container';
import { TickerSpine, TrackEntry } from '../components/spine';
import { EventTypes, FAKE_ROLLING_DURATION, REVIVAL_ANIMATION_DURATION, eventManager } from '../config';
import { Icon } from '../d';

import { CutinSkinConv, CutinTypeConvByReelType, CutinVoiceConvByReelType, RespinSymbolTypeConvToSkin } from './config';

export class AnnounceContainer extends ViewContainer {
  private cutin: TickerSpine<'cutin'>;

  private cutinAnimations: AnimationGroup;

  private cutinFurimukiAnimations: AnimationGroup;

  private reviveCutin: TickerSpine<'cutin_sp'>;

  private cutinWaitFlg: boolean;

  private forceStopFlg = false;

  private respinReelType: RespinReelType;

  constructor(_spinResult: Icon[]) {
    super();
    this.cutin = new TickerSpine('cutin');
    this.cutin.x = 601 - 289 + 552 / 2;
    this.cutin.y = 540 + -581 + 852 / 2;
    this.cutin.visible = false;
    this.cutin.state.addListener({
      event: (_entry: TrackEntry, event: Event) => {
        if (event.data.name === 'change_color' && !this.forceStopFlg) {
          const cutinType = CutinTypeConvByReelType[this.respinReelType];
          eventManager.emit(EventTypes.CHANGE_RESPIN_REELFRAME, cutinType);
        }
      },
    });
    this.addChild(this.cutin);

    this.cutinWaitFlg = false;
    this.cutinAnimations = new AnimationGroup();
    this.cutinFurimukiAnimations = new AnimationGroup();

    this.reviveCutin = new TickerSpine('cutin_sp');
    this.reviveCutin.skeleton.setSkinByName('default');
    this.reviveCutin.state.setEmptyAnimation(0);
    this.reviveCutin.position.set(320 + 768 / 2, -41 + 860 / 2); //609-289,540-581
    this.reviveCutin.visible = false;
    this.addChild(this.reviveCutin);

    this.respinReelType = 'RS_L';
    eventManager.addListener(EventTypes.START_SPIN_ANIMATION, this.hideContainer.bind(this));
    eventManager.addListener(EventTypes.START_CUTIN_ANIMATION, this.startCutinAnimation.bind(this));
    eventManager.addListener(EventTypes.START_REVIVE_ANIMATION, this.startRevivalAnimation.bind(this));
    eventManager.addListener(EventTypes.SETUP_REEL_POSITIONS, this.setupCutinAnimation.bind(this));
    eventManager.addListener(EventTypes.REEL_STOPPED, this.reelStopped.bind(this));
    eventManager.addListener(EventTypes.FORCE_STOP_REELS, () => {
      this.forceStopFlg = true;
      this.cutinAnimations.skip();
      this.cutinFurimukiAnimations.skip();
    });
  }

  private startCutinAnimation() {
    this.cutinAnimations = new AnimationGroup();
    const cutinAnimation = Tween.createDelayAnimation(FAKE_ROLLING_DURATION);
    const cutinDispWait = Tween.createDelayAnimation(durationOf(ISongs.XT003S_cutin_def));
    this.cutinWaitFlg = true;
    this.forceStopFlg = false;

    cutinDispWait.addOnComplete(() => {
      this.cutinWaitFlg = false;
    });
    cutinDispWait.addOnSkip(() => {});
    this.cutinAnimations.addAnimation(cutinDispWait);

    cutinAnimation.addOnComplete(() => {
      this.cutin.visible = false;
    });
    cutinAnimation.addOnSkip(() => {
      this.cutin.visible = false;
    });

    this.cutinAnimations.addAnimation(cutinAnimation);

    this.cutin.visible = true;
    this.cutin.skeleton.setSkinByName('a');
    this.cutin.update(0);
    this.cutin.state.setAnimation(0, 'in');
    AudioApi.play({ type: ISongs.XT003S_cutin_def });
    this.cutinAnimations.start();
  }

  private setupCutinAnimation(): void {
    if (!isFreeSpinsMode(setGameMode())) return;

    const reelType = getRespinReelType(setNextResult());
    if (!reelType) {
      throw new Error('Reel type is undefined');
    }
    this.respinReelType = reelType;

    this.cutinFurimukiAnimations = new AnimationGroup();

    const furimukiAnimation = Tween.createDelayAnimation(4000);
    furimukiAnimation.addOnStart(() => {});
    furimukiAnimation.addOnChange(() => {
      if (!this.cutinWaitFlg && !this.forceStopFlg) {
        this.cutin.state.setAnimation(0, 'furimuki', false);
        this.cutinWaitFlg = true;
        furimukiAnimation.duration = 1800;

        AudioApi.play({
          type: CutinVoiceConvByReelType[this.respinReelType],
          stopPrev: true,
        });
        this.cutinChangeColor();
      }
    });
    furimukiAnimation.addOnSkip(() => {
      this.cutin.visible = false;
      this.cutin.state.setEmptyAnimation(0);
      this.cutinWaitFlg = true;
    });
    furimukiAnimation.addOnComplete(() => {
      this.cutin.visible = false;
      this.cutin.state.setEmptyAnimation(0);
    });

    furimukiAnimation.start();

    this.cutinFurimukiAnimations.addAnimation(furimukiAnimation);
  }

  private cutinChangeColor() {
    const cutinType = CutinTypeConvByReelType[this.respinReelType];
    this.cutin.skeleton.setSkinByName(CutinSkinConv[cutinType].skinName);
  }

  private startRevivalAnimation() {
    this.reviveCutin.skeleton.setSkinByName(RespinSymbolTypeConvToSkin[setRespinSymbolType()]);
    this.reviveCutin.state.setAnimation(0, 'base', false);
    this.reviveCutin.visible = true;

    AudioApi.play({ type: ISongs.XT003S_cutin_reversal });

    const delay = Tween.createDelayAnimation(REVIVAL_ANIMATION_DURATION);
    delay.addOnComplete(() => {
      this.reviveCutin.state.setEmptyAnimation(0);
      this.reviveCutin.visible = false;
    });
    delay.start();
  }

  private reelStopped(_reelId: number, _reelStopSound: ReelStopSoundType): void {
    this.cutin.state.setEmptyAnimation(0);
    this.cutin.visible = false;

    this.reviveCutin.state.setEmptyAnimation(0);
    this.reviveCutin.visible = false;

    this.cutinAnimations.skip();
    this.cutinFurimukiAnimations.skip();
  }

  private hideContainer(): void {
    this.cutin.visible = false;
  }
}
