import type React from 'react';

const LeftSvg = () => {
  return (
    <svg width="144" height="144" viewBox="0 0 144 144" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M72 136C107.346 136 136 107.346 136 72C136 36.6538 107.346 8 72 8C36.6538 8 8 36.6538 8 72C8 107.346 36.6538 136 72 136Z"
        fill="black"
        fillOpacity="0.7"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M85.9917 53.9916C88.6696 51.3137 88.6696 46.972 85.9917 44.2941C83.3138 41.6162 78.9721 41.6162 76.2943 44.2941L53.4371 67.1513C51.4287 69.1597 50.9266 72.104 51.9308 74.5803C52.2655 75.4058 52.7677 76.1793 53.4371 76.8487C53.4381 76.8497 53.4392 76.8508 53.4402 76.8518L76.2943 99.7059C78.9721 102.384 83.3138 102.384 85.9917 99.7059C88.6696 97.028 88.6696 92.6863 85.9917 90.0084L67.9833 72L85.9917 53.9916Z"
        fill="white"
      />
    </svg>
  );
};

export default LeftSvg;
