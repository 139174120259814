import * as PIXI from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';

import { ISongs, durationOf } from '../../config';
import { GameMode } from '../../consts';
import { BaseMessageBannerProps, MessageFreeSpinsBannerProps, MessageWinBannerProps } from '../../global.d';
import { setBetAmount } from '../../gql/cache';
import Tween from '../animations/tween';
import { BgmControl } from '../bgmControl/bgmControl';
import ViewContainer from '../components/container';
import {
  BASE_WIN_AMOUNT_LIMIT,
  EventTypes,
  GameViewState,
  SLOTS_CONTAINER_HEIGHT,
  SLOTS_CONTAINER_WIDTH,
  SLOT_REELMASK_X,
  SLOT_REELMASK_Y,
  eventManager,
} from '../config';
import { IGameContainer } from '../d';
import Lantern from '../lantern/lantern';
import { MessageFreeSpinsBanner } from '../messageBanner/messageFreeSpinsBanner';
import { MessageSoldOutBanner } from '../messageBanner/messageSoldOutBanner';
import { MessageWinBanner } from '../messageBanner/messageWinBanner';
import MessageWindow from '../messageWindow/messageWindow';
import ReelsFrame from '../reels/frame/reelsFrame';
import SoldOutCountUpMessage from '../winAnimations/soldOutCountUpMessage';

import GameTitle from './gameTitle';

class GameView extends ViewContainer {
  public miniPayTableContainer: PIXI.Container;

  public slotsAllContainer: PIXI.Container;

  public reelsBackgroundContainer: PIXI.Container;

  public reelsContainer: PIXI.Container;

  public tintContainer: PIXI.Container;

  public slotsContainer: PIXI.Container;

  public winLabelContainer: PIXI.Container;

  public slotStopDisplayContainer: PIXI.Container;

  public winCountUpMessage: PIXI.Container;

  public soldOutCountUpMessage: SoldOutCountUpMessage;

  public gameTitle: PIXI.Container;

  public messageWindow: PIXI.Container;

  public lantern: Lantern;

  public reelsFrame: ReelsFrame;

  public announceContainer: PIXI.Container;

  private window: PIXI.ISize = { width: 0, height: 0 };

  constructor(props: IGameContainer) {
    super();
    this.slotsAllContainer = new PIXI.Container();

    this.slotsContainer = new PIXI.Container();
    this.slotsContainer.sortableChildren = true;
    this.slotsContainer.interactive = true;
    this.gameTitle = new GameTitle();
    this.lantern = new Lantern();
    this.messageWindow = new MessageWindow();
    this.reelsFrame = new ReelsFrame();
    this.soldOutCountUpMessage = new SoldOutCountUpMessage();
    this.winLabelContainer = props.winLabelContainer;
    this.slotStopDisplayContainer = props.slotStopDisplayContainer;
    this.miniPayTableContainer = props.miniPayTableContainer;
    this.tintContainer = props.tintContainer;
    this.reelsBackgroundContainer = props.reelsBackgroundContainer;
    this.reelsContainer = props.reelsContainer;
    this.winCountUpMessage = props.winCountUpMessage;
    this.announceContainer = props.announceContainer;

    this.slotsContainer.addChild(this.tintContainer);
    this.slotsContainer.addChild(this.reelsContainer);

    this.slotsContainer.mask = new PIXI.Graphics()
      .beginFill(0xffffff)
      .drawRect(SLOT_REELMASK_X, SLOT_REELMASK_Y, SLOTS_CONTAINER_WIDTH, SLOTS_CONTAINER_HEIGHT - 2)
      .endFill();

    this.slotsAllContainer.addChild(this.gameTitle);
    this.slotsAllContainer.addChild(this.reelsBackgroundContainer);
    this.slotsAllContainer.addChild(this.slotsContainer);
    this.slotsAllContainer.addChild(this.slotsContainer.mask);
    this.slotsAllContainer.addChild(this.slotStopDisplayContainer);
    this.slotsAllContainer.addChild(this.announceContainer);
    this.slotsAllContainer.addChild(this.reelsFrame);
    this.slotsAllContainer.addChild(this.lantern);

    this.slotsAllContainer.sortableChildren = true;
    this.gameTitle.zIndex = 0;
    this.reelsBackgroundContainer.zIndex = 3;
    this.slotsContainer.zIndex = 4;
    this.slotStopDisplayContainer.zIndex = 5;
    this.announceContainer.zIndex = 6;
    this.reelsFrame.zIndex = 9;
    this.lantern.zIndex = 10;

    this.addChild(this.slotsAllContainer);
    this.addChild(this.messageWindow);
    this.addChild(this.miniPayTableContainer);
    this.addChild(this.winLabelContainer);
    this.addChild(this.winCountUpMessage);
    this.addChild(this.soldOutCountUpMessage);

    this.sortableChildren = true;
    this.slotsAllContainer.zIndex = 1;
    this.messageWindow.zIndex = 3;
    this.miniPayTableContainer.zIndex = 4;
    this.winLabelContainer.zIndex = 6;
    this.winCountUpMessage.zIndex = 10;
    this.soldOutCountUpMessage.zIndex = 11;

    eventManager.addListener(EventTypes.RESIZE, this.applicationResize.bind(this));
    eventManager.addListener(EventTypes.CHANGE_MODE, this.onModeChange.bind(this));

    eventManager.addListener(EventTypes.ANTICIPATION_ANIMATIONS_START, this.startAnticipationAnimation.bind(this));

    eventManager.addListener(EventTypes.ANTICIPATION_ANIMATIONS_END, this.endAnticipationAnimation.bind(this));

    eventManager.addListener(EventTypes.CREATE_MESSAGE_BANNER, this.createFreeSpinsStartMessage.bind(this));

    eventManager.addListener(EventTypes.CREATE_WIN_MESSAGE_BANNER, this.createFreeSpinEndMessage.bind(this));

    eventManager.addListener(EventTypes.CREATE_SOLDOUT_MESSAGE_BANNER, this.createRespinSoldOutMessage.bind(this));

    eventManager.addListener(EventTypes.SET_FREESPINS_VIEW, this.setFreeSpinsView.bind(this));
  }

  private onModeChange(settings: { mode: GameMode }): void {
    //to base
    if (settings.mode === GameMode.REGULAR) {
      this.setBaseGameView();
    }
  }

  private setBaseGameView() {
    this.slotsAllContainer.scale.set(1.0);
    eventManager.emit(EventTypes.HIDE_COINS);
    eventManager.emit(EventTypes.HIDE_WIN_COUNT_UP_MESSAGE);
    eventManager.emit(EventTypes.SKIP_ALL_WIN_ANIMATIONS);

    this.resetBasePositionByState('Base');
  }

  private onReelScStopped() {}

  private startAnticipationAnimation() {}

  private endAnticipationAnimation() {}

  private setFreeSpinsView() {
    this.resetBasePositionByState('Base');
    this.slotsAllContainer.scale.set(1);
  }

  private createFreeSpinsStartMessage(props: MessageFreeSpinsBannerProps) {
    this.setFreeSpinsView();
    const messageBanner = new MessageFreeSpinsBanner(props);
    messageBanner.init();
    messageBanner.zIndex = 20;
    messageBanner.handleResize(this.window.width, this.window.height);
    this.addChild(messageBanner);
  }

  private createFreeSpinEndMessage(props: MessageWinBannerProps): void {
    const multiplier = props.totalWinAmount / setBetAmount();

    const totalWinDelay = Tween.createDelayAnimation(durationOf(ISongs.XT003S_feature_end));
    totalWinDelay.addOnStart(() => {
      BgmControl.stopAll();
    });
    totalWinDelay.addOnSkip(() => {
      AudioApi.stop({ type: ISongs.XT003S_feature_end });

      if (multiplier >= BASE_WIN_AMOUNT_LIMIT) {
        AudioApi.stop({ type: ISongs.XT003S_feature_end_voice });
      }
    });

    AudioApi.play({ type: ISongs.XT003S_feature_end, stopPrev: true });
    if (multiplier >= BASE_WIN_AMOUNT_LIMIT) {
      AudioApi.play({ type: ISongs.XT003S_feature_end_voice, stopPrev: true });
    }

    totalWinDelay.start();

    const messageBanner = new MessageWinBanner({
      ...props,
      callback: () => {
        totalWinDelay.skip();
        if (props.callback) props.callback();
      },
    });
    messageBanner.init();
    messageBanner.zIndex = 20;
    messageBanner.handleResize(this.window.width, this.window.height);
    this.addChild(messageBanner);
  }

  private createRespinSoldOutMessage(props: BaseMessageBannerProps): void {
    BgmControl.stopAll();
    const messageBanner = new MessageSoldOutBanner({
      ...props,
      callback: () => {
        if (props.callback) props.callback();
      },
    });
    messageBanner.init();
    messageBanner.zIndex = 7;
    messageBanner.handleResize(this.window.width, this.window.height);
    this.addChild(messageBanner);
  }

  private applicationResize = (width: number, height: number): void => {
    this.window = { width, height };
  };

  private resetBasePositionByState(state: GameViewState) {
    switch (state) {
      case 'Base':
        break;
      default:
        break;
    }
  }
}

export default GameView;
