import { BuyFeatureType, RespinReelType, RespinSymbolType } from '../../global.d';

export enum BonusKind {
  FREE_SPINS,
  BUY_FEATURE,
}

export type RespinBonusInfo = {
  bonusKind: BonusKind.FREE_SPINS;
  respinReelType: RespinReelType;
  respinSymbolType: RespinSymbolType;
};

export type BuyFeatureBonusInfo = {
  bonusKind: BonusKind.BUY_FEATURE;
  buyFeatureType: BuyFeatureType;
};

export const BonusDataByBonusId: Record<string, RespinBonusInfo | BuyFeatureBonusInfo> = {
  'd1342d28-659a-44ae-9c56-39fad5a7f07d': {
    bonusKind: BonusKind.FREE_SPINS,
    respinReelType: 'RS_L',
    respinSymbolType: 'A',
  },
  '80021871-1261-4ffd-b8e8-b171e7771fb4': {
    bonusKind: BonusKind.FREE_SPINS,
    respinReelType: 'RS_M',
    respinSymbolType: 'A',
  },
  'dcabe835-7742-4f7f-8850-432d9d5a9d3f': {
    bonusKind: BonusKind.FREE_SPINS,
    respinReelType: 'RS_H',
    respinSymbolType: 'A',
  },
  '65fb2767-b1f4-4b2a-9962-9772d0b4096a': {
    bonusKind: BonusKind.FREE_SPINS,
    respinReelType: 'RS_LV',
    respinSymbolType: 'A',
  },
  '51cf2f2f-8c5b-4a21-9004-123f9969dc66': {
    bonusKind: BonusKind.FREE_SPINS,
    respinReelType: 'RS_MV',
    respinSymbolType: 'A',
  },
  'adcb3e19-bd40-4957-bfd2-6bc09dbc6a2d': {
    bonusKind: BonusKind.FREE_SPINS,

    respinReelType: 'RS_L',
    respinSymbolType: 'B',
  },
  '6c469482-b98e-481a-91f1-0b332145d215': {
    bonusKind: BonusKind.FREE_SPINS,
    respinReelType: 'RS_M',
    respinSymbolType: 'B',
  },
  '792303fb-ffbc-4b79-bdba-0b0c7a26ebd0': {
    bonusKind: BonusKind.FREE_SPINS,
    respinReelType: 'RS_H',
    respinSymbolType: 'B',
  },
  'a5fad809-4fcb-4c04-9c9c-26f94c8b7417': {
    bonusKind: BonusKind.FREE_SPINS,
    respinReelType: 'RS_LV',
    respinSymbolType: 'B',
  },
  '48bcba33-4679-4393-89bb-f50e82237774': {
    bonusKind: BonusKind.FREE_SPINS,
    respinReelType: 'RS_MV',
    respinSymbolType: 'B',
  },
  '0582560f-1b01-4166-83b5-e930631ab83e': {
    bonusKind: BonusKind.FREE_SPINS,
    respinReelType: 'RS_L',
    respinSymbolType: 'C',
  },
  'b086b2e3-ab5f-4dcc-af11-0f6423a58ab7': {
    bonusKind: BonusKind.FREE_SPINS,
    respinReelType: 'RS_M',
    respinSymbolType: 'C',
  },
  '2121e22d-010a-44bc-851b-ce2a20337e89': {
    bonusKind: BonusKind.FREE_SPINS,
    respinReelType: 'RS_H',
    respinSymbolType: 'C',
  },
  '3545e294-871a-4013-9450-97f4759e2448': {
    bonusKind: BonusKind.FREE_SPINS,
    respinReelType: 'RS_LV',
    respinSymbolType: 'C',
  },
  'e6408ed8-862e-4f6a-a3ce-743854e18e79': {
    bonusKind: BonusKind.FREE_SPINS,
    respinReelType: 'RS_MV',
    respinSymbolType: 'C',
  },
  '0991e244-b0d5-4f94-a001-05bd915582dc': {
    bonusKind: BonusKind.FREE_SPINS,
    respinReelType: 'RS_L',
    respinSymbolType: 'D',
  },
  '219faa63-bf69-4e1d-84ac-444c666e3f64': {
    bonusKind: BonusKind.FREE_SPINS,
    respinReelType: 'RS_M',
    respinSymbolType: 'D',
  },
  '91ced359-169a-40bd-b553-2ae1bb9d8d61': {
    bonusKind: BonusKind.FREE_SPINS,
    respinReelType: 'RS_H',
    respinSymbolType: 'D',
  },
  '25b3b4e6-77e2-4f11-b485-818e29386b9a': {
    bonusKind: BonusKind.FREE_SPINS,
    respinReelType: 'RS_LV',
    respinSymbolType: 'D',
  },
  '969002d7-d1b0-41a5-8285-ecea8c0decbf': {
    bonusKind: BonusKind.FREE_SPINS,
    respinReelType: 'RS_MV',
    respinSymbolType: 'D',
  },
  '5cb9148d-289a-4ad1-83db-d9d341456492': {
    bonusKind: BonusKind.FREE_SPINS,
    respinReelType: 'RS_L',
    respinSymbolType: 'E',
  },
  'ad64bb4f-827b-4999-acc2-c44792f0e2f4': {
    bonusKind: BonusKind.FREE_SPINS,
    respinReelType: 'RS_M',
    respinSymbolType: 'E',
  },
  'ec6302f5-a1b9-4ee5-bf0a-0a7a4dbb065d': {
    bonusKind: BonusKind.FREE_SPINS,
    respinReelType: 'RS_H',
    respinSymbolType: 'E',
  },
  '42ee542b-b6f2-4486-9a63-e5d5431b521f': {
    bonusKind: BonusKind.FREE_SPINS,
    respinReelType: 'RS_LV',
    respinSymbolType: 'E',
  },
  'e8bdbd3e-8e35-4767-b287-43762bbcef1d': {
    bonusKind: BonusKind.FREE_SPINS,
    respinReelType: 'RS_MV',
    respinSymbolType: 'E',
  },
  '8b56c389-10e4-4ab1-b681-90a02b327b33': {
    bonusKind: BonusKind.BUY_FEATURE,
    buyFeatureType: 'A',
  },
  '3114d0b0-513d-4861-b286-928a51ca05aa': {
    bonusKind: BonusKind.BUY_FEATURE,
    buyFeatureType: 'B',
  },
  'bb5b0389-35f5-4fc7-ad4b-d8454d67328b': {
    bonusKind: BonusKind.BUY_FEATURE,
    buyFeatureType: 'C',
  },
  'de9f7e0b-e0f1-49cb-aa64-da9c32ac3f06': {
    bonusKind: BonusKind.BUY_FEATURE,
    buyFeatureType: 'D',
  },
  '6a719396-6a5d-4ff0-82fc-e7a111d86018': {
    bonusKind: BonusKind.BUY_FEATURE,
    buyFeatureType: 'E',
  },
  '4c2b53c7-cf84-4b99-9702-f07c33b29596': {
    bonusKind: BonusKind.BUY_FEATURE,
    buyFeatureType: 'M',
  },
};
