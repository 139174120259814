import { useMutation } from '@apollo/client';
import { useEffect } from 'react';

import { setBonuses, setCoinAmount, setCoinValue } from '../../gql/cache';
import { buyBonusGql } from '../../gql/mutation';
import { EventTypes, eventManager } from '../../slotMachine/config';

const useBuyFeature = () => {
  // TO DO
  const [buyBonus] = useMutation(buyBonusGql, {
    onCompleted(data) {
      eventManager.emit(EventTypes.START_BUY_FEATURE_ROUND, data.buyBonus!.id); // Triggers free spins
    },
  });

  useEffect(() => {
    const bonuses = setBonuses();
    const handleBuyBonus = (index: number) => {
      const bonus = bonuses?.filter((bonus) => bonus?.type === 'SPECIAL_ROUND')[index];

      buyBonus({
        variables: {
          input: {
            id: bonus!.id,
            coinValue: setCoinValue(),
            coinAmount: setCoinAmount(),
          },
        },
      });
    };

    eventManager.on(EventTypes.HANDLE_BUY_BONUS, handleBuyBonus);
    return () => {
      eventManager.removeListener(EventTypes.HANDLE_BUY_BONUS, handleBuyBonus);
    };
  }, [buyBonus]);
};

export default useBuyFeature;
