import i18n from 'i18next';
import * as PIXI from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';
import { formatNumber } from '@phoenix7dev/utils-fe';

import { Colors, ISongs } from '../../config';
import { BuyFeatureType } from '../../global.d';
import {
  setBetAmount,
  setBonuses,
  setCoinAmount,
  setCurrency,
  setIsBuyFeaturePopupOpened,
  setSlotConfig,
} from '../../gql/cache';
import { IBetSettings } from '../../gql/d';
import { getBetsSetting } from '../../gql/fromFragment';
import { normalizeCoins, showCurrency } from '../../utils';
import AutoResizeText from '../components/autoResizeText';
import { SpriteButton } from '../components/button';
import ViewContainer from '../components/container';
import { TickerSpine } from '../components/spine';
import { EventTypes, WinStages, eventManager } from '../config';

import { BuyFeatureSymbolContainer } from './buyFeatureSymbolContainer';
import {
  FEATURE_DISP_INFO,
  FEATURE_POPUP_BET_TEXT_POSITION,
  FEATURE_POPUP_BET_VALUE_POSITION_X,
  FEATURE_POPUP_BET_VALUE_POSITION_Y,
  FEATURE_POPUP_CANCEL_BTN_POSITION_X,
  FEATURE_POPUP_CANCEL_BTN_POSITION_Y,
  FEATURE_POPUP_LANDSCAPE_POSITION_X,
  FEATURE_POPUP_LANDSCAPE_POSITION_Y,
  FEATURE_POPUP_MINUS_BTN_POSITION_X,
  FEATURE_POPUP_MINUS_BTN_POSITION_Y,
  FEATURE_POPUP_MP_BTN_HITAREA_SCALE,
  FEATURE_POPUP_OK_BTN_POSITION_X,
  FEATURE_POPUP_OK_BTN_POSITION_Y,
  FEATURE_POPUP_PLUS_BTN_POSITION_X,
  FEATURE_POPUP_PLUS_BTN_POSITION_Y,
  FEATURE_POPUP_PORTRAIT_POSITION_X,
  FEATURE_POPUP_PORTRAIT_POSITION_Y,
  FEATURE_POPUP_SPINE_HEIGHT,
  FEATURE_POPUP_SPINE_WIDTH,
  FEATURE_POPUP_TITLE_POSITION_X,
  FEATURE_POPUP_TITLE_POSITION_Y,
} from './config';
import {
  betValueStyle,
  buyFeatureBetDescriptionTextStyle,
  buyFeatureDescriptionTextStyle,
  buyFeatureTitleStyle,
} from './textStyles';

export class BuyFeaturePopup extends ViewContainer {
  private popupBg: TickerSpine<'buyfeature'>;

  private titleText: AutoResizeText;

  private descriptionText: AutoResizeText;

  private okBtn: SpriteButton;

  private cancelBtn: SpriteButton;

  private betDescriptionText: AutoResizeText;

  private betAmount: number;

  private betValue: AutoResizeText;

  private minusBtn: SpriteButton;

  private plusBtn: SpriteButton;

  private betSettings: IBetSettings;

  private linesAmount: number;

  private currency = 'FUN';

  private balance: number;

  private symbolsContainers: BuyFeatureSymbolContainer[] = [];

  private selectedSymbol: BuyFeatureType;

  constructor() {
    super();
    this.betSettings = getBetsSetting();
    this.visible = false;
    this.linesAmount = setSlotConfig().lineSet.coinAmountMultiplier;
    this.balance = 0;
    this.currency = setCurrency();
    this.betAmount = this.getBetAmount(setBetAmount());
    this.popupBg = this.initPopupBg();

    this.titleText = this.initTitle();
    this.descriptionText = this.initDescription();
    this.betDescriptionText = this.initBetDescriptionText();
    this.minusBtn = this.initMinusBtn();
    this.plusBtn = this.initPlusBtn();
    this.betValue = this.initBetValue();

    this.cancelBtn = this.initCancelBtn();
    this.okBtn = this.initOkBtn();
    this.init();

    this.selectedSymbol = 'A';
    this.initSymbolContainers();
  }

  private init(): void {
    this.addChild(
      this.popupBg,
      this.titleText,
      this.descriptionText,
      this.betDescriptionText,
      this.minusBtn,
      this.plusBtn,
      this.betValue,
      this.okBtn,
      this.cancelBtn,
    );
    eventManager.on(EventTypes.OPEN_BUY_FEATURE_POPUP, () => {
      this.closeAllAnimationsInSlot();
      this.visible = true;
      setIsBuyFeaturePopupOpened(true);
    });
    eventManager.on(EventTypes.UPDATE_BET, () => {
      this.betAmount = this.getBetAmount(setBetAmount());
      this.updateBets();
      this.handleDisable();
    });
    eventManager.on(EventTypes.START_BUY_FEATURE_ROUND, () => {
      this.visible = false;
      eventManager.emit(EventTypes.CLOSE_BUY_FEATURE_POPUP);
      eventManager.emit(EventTypes.CLOSE_BUY_FEATURE_POPUP_BG);
      setIsBuyFeaturePopupOpened(false);
    });
    eventManager.on(EventTypes.BUY_FEATURE_CONFIRMED, () => {
      this.visible = false;
      setIsBuyFeaturePopupOpened(false); //don't change order
      eventManager.emit(EventTypes.CLOSE_BUY_FEATURE_POPUP);
      eventManager.emit(EventTypes.CLOSE_BUY_FEATURE_POPUP_BG);
    });
    eventManager.on(EventTypes.UPDATE_USER_BALANCE, (balance) => {
      this.balance = balance!.amount / 100;
      this.handleDisable();
    });
    eventManager.on(EventTypes.RESIZE, this.applicationResize.bind(this));
  }

  private initPopupBg(): TickerSpine<'buyfeature'> {
    const popupBg = new TickerSpine<'buyfeature'>('buyfeature');
    popupBg.state.setAnimation(0, 'select_a', true);
    popupBg.skeleton.setSkinByName('default');
    popupBg.pivot.x = -FEATURE_POPUP_SPINE_WIDTH / 2;
    popupBg.pivot.y = -FEATURE_POPUP_SPINE_HEIGHT / 2;
    return popupBg;
  }

  private initTitle(): AutoResizeText {
    const title = new AutoResizeText(i18n.t('buyFeature.title'), buyFeatureTitleStyle);
    title.x = FEATURE_POPUP_TITLE_POSITION_X;
    title.y = FEATURE_POPUP_TITLE_POSITION_Y;
    title.anchor.set(0.5, 1);

    return title;
  }

  private initDescription(): AutoResizeText {
    const description = new AutoResizeText(i18n.t('buyFeature.description'), buyFeatureDescriptionTextStyle);
    description.x = FEATURE_POPUP_TITLE_POSITION_X;
    description.y = FEATURE_POPUP_TITLE_POSITION_Y - 3;
    description.anchor.set(0.5, 0);

    return description;
  }

  private initBetDescriptionText(): AutoResizeText {
    const betText = new AutoResizeText(i18n.t('buyFeature.betPerGame'), buyFeatureBetDescriptionTextStyle);
    betText.position.set(...FEATURE_POPUP_BET_TEXT_POSITION);
    betText.anchor.set(0.5, 0.5);

    return betText;
  }

  private initMinusBtn(): SpriteButton {
    const minusBtn = new SpriteButton(
      {
        default: 'buyfeature_btn_m',
        hover: 'buyfeature_btn_m_over',
        press: 'buyfeature_btn_m_click',
        disable: 'buyfeature_btn_m_disable',
      },
      () => {
        if (this.betSettings.bets[this.betAmount - 1]! > this.betSettings!.minBet) {
          // eslint-disable-next-line no-plusplus
          this.betAmount--;
          setCoinAmount(this.betSettings.bets[this.betAmount - 1]);
          this.updateBets();
          this.handleDisable();
          setBetAmount(setCoinAmount() * setSlotConfig().lineSet.coinAmountMultiplier);
          AudioApi.play({ type: ISongs.SFX_UI_BetChange });
        }
      },
    );

    minusBtn.position.set(FEATURE_POPUP_MINUS_BTN_POSITION_X, FEATURE_POPUP_MINUS_BTN_POSITION_Y);
    minusBtn.anchor.set(0.5, 0.5);

    const btnHitArea = {
      w: minusBtn.width * FEATURE_POPUP_MP_BTN_HITAREA_SCALE,
      h: minusBtn.height * FEATURE_POPUP_MP_BTN_HITAREA_SCALE,
    };
    minusBtn.hitArea = new PIXI.Rectangle(-btnHitArea.w / 2, -btnHitArea.h / 2, btnHitArea.w, btnHitArea.h);

    return minusBtn;
  }

  private initPlusBtn(): SpriteButton {
    const plusBtn = new SpriteButton(
      {
        default: 'buyfeature_btn_p',
        hover: 'buyfeature_btn_p_over',
        press: 'buyfeature_btn_p_click',
        disable: 'buyfeature_btn_p_disable',
      },
      () => {
        if (this.betSettings.bets[this.betAmount - 1]! < this.betSettings!.maxBet) {
          this.betAmount++;
          setCoinAmount(this.betSettings.bets[this.betAmount - 1]);
          this.updateBets();
          this.handleDisable();
          setBetAmount(setCoinAmount() * setSlotConfig().lineSet.coinAmountMultiplier);
          AudioApi.play({ type: ISongs.SFX_UI_BetChange });
        }
      },
    );

    plusBtn.position.set(FEATURE_POPUP_PLUS_BTN_POSITION_X, FEATURE_POPUP_PLUS_BTN_POSITION_Y);
    plusBtn.anchor.set(0.5, 0.5);

    const btnHitArea = {
      w: plusBtn.width * FEATURE_POPUP_MP_BTN_HITAREA_SCALE,
      h: plusBtn.height * FEATURE_POPUP_MP_BTN_HITAREA_SCALE,
    };
    plusBtn.hitArea = new PIXI.Rectangle(-btnHitArea.w / 2, -btnHitArea.h / 2, btnHitArea.w, btnHitArea.h);
    return plusBtn;
  }

  private initBetValue(): AutoResizeText {
    const betValue = new AutoResizeText(
      `${formatNumber({
        currency: this.currency,
        value: normalizeCoins(this.getBetValue()),
        showCurrency: showCurrency(this.currency),
      })}`,
      betValueStyle,
    );
    betValue.y = FEATURE_POPUP_BET_VALUE_POSITION_Y;
    betValue.x = FEATURE_POPUP_BET_VALUE_POSITION_X;
    betValue.anchor.set(0.5, 0.5);

    return betValue;
  }

  private initCancelBtn(): SpriteButton {
    const cancelBtn = new SpriteButton(
      {
        default: 'buyfeature_btn_cancel',
        hover: 'buyfeature_btn_cancel_over',
        press: 'buyfeature_btn_cancel_click',
        disable: 'buyfeature_btn_cancel_disable',
      },
      () => {
        AudioApi.play({ type: ISongs.SFX_UI_Close });
        this.visible = false;
        setIsBuyFeaturePopupOpened(false);
        eventManager.emit(EventTypes.DISABLE_BUY_FEATURE_BTN, false);
        eventManager.emit(EventTypes.CLOSE_BUY_FEATURE_POPUP);
        eventManager.emit(EventTypes.CLOSE_BUY_FEATURE_POPUP_BG);
      },
    );

    cancelBtn.y = FEATURE_POPUP_CANCEL_BTN_POSITION_Y;
    cancelBtn.x = FEATURE_POPUP_CANCEL_BTN_POSITION_X;
    cancelBtn.anchor.set(0.5);

    return cancelBtn;
  }

  private initOkBtn(): SpriteButton {
    const okBtn = new SpriteButton(
      {
        default: 'buyfeature_btn_ok',
        hover: 'buyfeature_btn_ok_over',
        press: 'buyfeature_btn_ok_click',
        disable: 'buyfeature_btn_ok_disable',
      },
      () => {
        AudioApi.play({ type: ISongs.SFX_UI_General });
        eventManager.emit(
          EventTypes.OPEN_BUY_FEATURE_CONFIRM_POPUP,
          this.getTotalCost(),
          this.betSettings.bets[this.betAmount]!,
          this.selectedSymbol,
        );
      },
    );

    okBtn.y = FEATURE_POPUP_OK_BTN_POSITION_Y;
    okBtn.x = FEATURE_POPUP_OK_BTN_POSITION_X;
    okBtn.anchor.set(0.5);
    okBtn.tint = Colors.GAME_COLOR;

    return okBtn;
  }

  private initSymbolContainers(): void {
    Object.entries(FEATURE_DISP_INFO).forEach(([key, value]) => {
      const callback = () => {
        if (this.selectedSymbol !== (key as BuyFeatureType)) {
          this.selectedSymbol = key as BuyFeatureType;
          this.updateSelectSymbol();
        }
      };
      const container = new BuyFeatureSymbolContainer(
        i18n.t(`infoPayTable.symbol${key as BuyFeatureType}`),
        this.currency,
        value.multiplier,
        this.getBetValue(),
        callback,
      );
      this.symbolsContainers.push(container);

      container.x = value.pos.x;
      container.y = value.pos.y;
      this.addChild(container);
    });
  }

  private updateSelectSymbol() {
    AudioApi.play({ type: ISongs.SFX_UI_General });
    this.popupBg.state.setAnimation(0, FEATURE_DISP_INFO[this.selectedSymbol].animName, true);
    this.handleDisable();
  }

  private updateSymbolCost(): void {
    this.symbolsContainers.forEach((obj) => {
      obj.updateBetValue(this.getBetValue());
    });
  }

  private getBetAmount(betAmount: number): number {
    return this.betSettings!.bets.findIndex((bet) => bet === betAmount / this.linesAmount) + 1;
  }

  private updateBets(): void {
    //    this.totalCostTextAmount.text = this.getTotalCost();
    this.betValue.text = `${formatNumber({
      currency: this.currency,
      value: normalizeCoins(this.getBetValue()),
      showCurrency: showCurrency(this.currency),
    })}`;
    this.updateSymbolCost();
  }

  private getTotalCost(): string {
    return `${formatNumber({
      currency: this.currency,
      value: normalizeCoins(this.getBetValue() * this.getCoinAmount()),
      showCurrency: showCurrency(this.currency),
    })}`;
  }

  private getBetValue(): number {
    return this.linesAmount * (this.betSettings!.bets[this.betAmount - 1] || 1);
  }

  private getCoinAmount(): number {
    const bonusNo = FEATURE_DISP_INFO[this.selectedSymbol].bonusNo;

    const bonuses = setBonuses();
    const bonus = bonuses?.filter((bonus) => bonus?.type === 'SPECIAL_ROUND')[bonusNo] ?? { coinAmount: 0 };

    return bonus.coinAmount;
  }

  private handleDisable(): void {
    const bet = this.betSettings.bets[this.betAmount - 1];

    this.minusBtn.disable = bet === this.betSettings!.minBet;

    this.plusBtn.disable = bet === this.betSettings!.maxBet;

    this.okBtn.disable = this.balance < normalizeCoins(this.getBetValue() * this.getCoinAmount());
  }

  private closeAllAnimationsInSlot() {
    eventManager.emit(EventTypes.SET_WIN_VISIBILITY, WinStages.None);
    eventManager.emit(EventTypes.HIDE_WIN_COUNT_UP_MESSAGE);
  }

  private applicationResize = (width: number, height: number): void => {
    this.handleResize(width, height);
  };

  private handleResize(width: number, height: number): void {
    if (height > width) {
      this.x = FEATURE_POPUP_PORTRAIT_POSITION_X;
      this.y = FEATURE_POPUP_PORTRAIT_POSITION_Y;
    } else {
      this.x = FEATURE_POPUP_LANDSCAPE_POSITION_X;
      this.y = FEATURE_POPUP_LANDSCAPE_POSITION_Y;
    }
  }
}
