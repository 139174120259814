import type React from 'react';

const SkipRightSvg = () => {
  return (
    <svg width="144" height="144" viewBox="0 0 144 144" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M72 7.99999C36.6538 7.99999 8.00001 36.6538 8.00001 72C8 107.346 36.6538 136 72 136C107.346 136 136 107.346 136 72C136 36.6538 107.346 8 72 7.99999Z"
        fill="black"
        fillOpacity="0.7"
      />
      <path
        d="M83.4288 94.8572C83.4288 98.6442 86.4988 101.714 90.2859 101.714C94.073 101.714 97.1431 98.6442 97.1431 94.8572L97.1431 49.1429C97.1431 45.3558 94.073 42.2857 90.2859 42.2857C86.4988 42.2857 83.4288 45.3558 83.4288 49.1429L83.4288 94.8572Z"
        fill="white"
      />
      <path
        d="M53.9918 99.7059C51.3139 102.384 46.9722 102.384 44.2943 99.7059C41.6165 97.028 41.6165 92.6863 44.2943 90.0084L62.3027 72L44.2943 53.9916C41.6165 51.3137 41.6165 46.972 44.2943 44.2941C46.9722 41.6163 51.3139 41.6163 53.9918 44.2941L76.8489 67.1513C79.5268 69.8292 79.5268 74.1709 76.8489 76.8487L53.9918 99.7059Z"
        fill="white"
      />
    </svg>
  );
};

export default SkipRightSvg;
