import i18n from 'i18next';
import * as PIXI from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';

import { Colors, ISongs } from '../../config';
import { BuyFeatureType } from '../../global.d';
import { setIsBuyFeaturePurchased } from '../../gql/cache';
import AutoResizeText from '../components/autoResizeText';
import { SpriteButton } from '../components/button';
import { TickerSpine } from '../components/spine';
import { EventTypes, eventManager } from '../config';

import {
  FEATURE_DISP_INFO,
  FEATURE_POPUP_CONFIRM_CANCEL_BTN_POSITION,
  FEATURE_POPUP_CONFIRM_OK_BTN_POSITION,
  FEATURE_POPUP_CONFIRM_TEXT_POSITION_Y,
  FEATURE_POPUP_CONFIRM_TITLE_POSITION_X,
  FEATURE_POPUP_CONFIRM_TITLE_POSITION_Y,
  FEATURE_POPUP_CONFIRM_TOTAL_COST_TEXT_POSITION_X,
  FEATURE_POPUP_CONFIRM_TOTAL_COST_TEXT_POSITION_Y,
  FEATURE_POPUP_CONFIRM_TOTAL_COST_VALUE_POSITION_X,
  FEATURE_POPUP_CONFIRM_TOTAL_COST_VALUE_POSITION_Y,
  FEATURE_POPUP_LANDSCAPE_POSITION_X,
  FEATURE_POPUP_LANDSCAPE_POSITION_Y,
  FEATURE_POPUP_PORTRAIT_POSITION_X,
  FEATURE_POPUP_PORTRAIT_POSITION_Y,
  FEATURE_POPUP_SPINE_HEIGHT,
  FEATURE_POPUP_SPINE_WIDTH,
} from './config';
import {
  buyFeatureConfirmTextStyle,
  buyFeatureConfirmTitleStyle,
  totalCostTextAmountStyle,
  totalCostTextStyle,
} from './textStyles';

export class BuyFeaturePopupConfirm extends PIXI.Container {
  private popupBg: TickerSpine<'buyfeature'>;

  private titleText: AutoResizeText;

  private confirmText: AutoResizeText;

  private totalCostText: AutoResizeText;

  private totalCostValue: AutoResizeText;

  private okBtn: SpriteButton;

  private cancelBtn: SpriteButton;

  private selectedSymbol: BuyFeatureType;

  constructor() {
    super();
    this.visible = false;
    this.selectedSymbol = 'M';

    this.popupBg = this.initPopupBg();
    this.titleText = this.initTitle();
    this.confirmText = this.initConfirmText();
    this.totalCostText = this.initTotalCostText();
    this.totalCostValue = this.initTotalCostValue();
    this.cancelBtn = this.initCancelBtn();
    this.okBtn = this.initOkBtn();
    this.interactive = true;
    this.init();
  }

  private init(): void {
    this.addChild(this.popupBg);
    this.addChild(this.titleText);
    this.addChild(this.confirmText);
    this.addChild(this.totalCostText);
    this.addChild(this.totalCostValue);
    this.addChild(this.okBtn);
    this.addChild(this.cancelBtn);
    eventManager.on(EventTypes.OPEN_BUY_FEATURE_POPUP, () => this.handleClose());
    eventManager.on(EventTypes.START_BUY_FEATURE_ROUND, () => this.handleClose());
    eventManager.on(EventTypes.BUY_FEATURE_CONFIRMED, () => this.handleClose());
    eventManager.on(
      EventTypes.OPEN_BUY_FEATURE_CONFIRM_POPUP,
      (totalCost: string, coinAmount: number, selectedSymbol: BuyFeatureType) => {
        this.handleOpen(totalCost, coinAmount, selectedSymbol);
      },
    );
    eventManager.on(EventTypes.RESIZE, this.applicationResize.bind(this));
  }

  private initPopupBg(): TickerSpine<'buyfeature'> {
    const popupBg = new TickerSpine<'buyfeature'>('buyfeature');
    popupBg.state.setAnimation(0, 'conf', true);
    popupBg.skeleton.setSkinByName('conf_mystery');
    popupBg.pivot.x = -FEATURE_POPUP_SPINE_WIDTH / 2;
    popupBg.pivot.y = -FEATURE_POPUP_SPINE_HEIGHT / 2;

    return popupBg;
  }

  private initTitle(): AutoResizeText {
    const title = new AutoResizeText(i18n.t('buyFeature.confirm.title'), buyFeatureConfirmTitleStyle);
    title.x = FEATURE_POPUP_CONFIRM_TITLE_POSITION_X;
    title.y = FEATURE_POPUP_CONFIRM_TITLE_POSITION_Y;

    title.anchor.set(0.5, 1);

    return title;
  }

  private initConfirmText(): AutoResizeText {
    const textValue = `${i18n.t(`infoPayTable.symbol${this.selectedSymbol}`)} ${i18n.t('buyFeature.confirm.text')}`;
    const text = new AutoResizeText(textValue, buyFeatureConfirmTextStyle);
    text.x = FEATURE_POPUP_CONFIRM_TITLE_POSITION_X;
    text.y = FEATURE_POPUP_CONFIRM_TEXT_POSITION_Y;

    text.anchor.set(0.5, 0.5);

    return text;
  }

  private initTotalCostText(): AutoResizeText {
    const totalCostText = new AutoResizeText(i18n.t('buyFeature.totalCost'), totalCostTextStyle);
    totalCostText.x = FEATURE_POPUP_CONFIRM_TOTAL_COST_TEXT_POSITION_X;
    totalCostText.y = FEATURE_POPUP_CONFIRM_TOTAL_COST_TEXT_POSITION_Y;

    totalCostText.anchor.set(0, 0);

    return totalCostText;
  }

  private initTotalCostValue(): AutoResizeText {
    const totalCostValue = new AutoResizeText('0', totalCostTextAmountStyle);
    totalCostValue.anchor.set(1, 1);
    totalCostValue.x = FEATURE_POPUP_CONFIRM_TOTAL_COST_VALUE_POSITION_X;
    totalCostValue.y = FEATURE_POPUP_CONFIRM_TOTAL_COST_VALUE_POSITION_Y;

    return totalCostValue;
  }

  private initCancelBtn(): SpriteButton {
    const cancelBtn = new SpriteButton(
      {
        default: 'buyfeature_btn_cancel',
        hover: 'buyfeature_btn_cancel_over',
        press: 'buyfeature_btn_cancel_click',
        disable: 'buyfeature_btn_cancel_disable',
      },
      () => {
        this.handleClose();
        eventManager.emit(EventTypes.OPEN_BUY_FEATURE_POPUP_BG);
        AudioApi.play({ type: ISongs.SFX_UI_Close });
      },
    );
    cancelBtn.position.set(...FEATURE_POPUP_CONFIRM_CANCEL_BTN_POSITION);
    cancelBtn.anchor.set(0.5);

    return cancelBtn;
  }

  private initOkBtn(): SpriteButton {
    const okBtn = new SpriteButton(
      {
        default: 'buyfeature_btn_ok',
        hover: 'buyfeature_btn_ok_over',
        press: 'buyfeature_btn_ok_click',
        disable: 'buyfeature_btn_ok_disable',
      },
      () => {
        this.okBtn.interactive = false;
        this.cancelBtn.interactive = false;
        AudioApi.play({ type: ISongs.SFX_UI_General });
        eventManager.emit(EventTypes.HANDLE_BUY_BONUS, FEATURE_DISP_INFO[this.selectedSymbol].bonusNo);
        setIsBuyFeaturePurchased(true);
      },
    );
    okBtn.position.set(...FEATURE_POPUP_CONFIRM_OK_BTN_POSITION);
    okBtn.anchor.set(0.5);
    okBtn.tint = Colors.GAME_COLOR;

    return okBtn;
  }

  public handleOpen(totalCost: string, _coinAmount: number, selectedSymbol: BuyFeatureType): void {
    this.visible = true;
    this.okBtn.interactive = true;
    this.cancelBtn.interactive = true;
    this.totalCostValue.text = totalCost;
    this.selectedSymbol = selectedSymbol;
    this.popupBg.skeleton.setSkinByName(FEATURE_DISP_INFO[this.selectedSymbol].confSkin);

    this.confirmText.text = `${i18n.t(`infoPayTable.symbol${this.selectedSymbol}`)} ${i18n.t(
      'buyFeature.confirm.text',
    )}`;
  }

  public handleClose(): void {
    this.visible = false;
    this.okBtn._trackedPointers = {};
  }

  private applicationResize = (width: number, height: number): void => {
    this.handleResize(width, height);
  };

  private handleResize(width: number, height: number): void {
    if (height > width) {
      this.x = FEATURE_POPUP_PORTRAIT_POSITION_X;
      this.y = FEATURE_POPUP_PORTRAIT_POSITION_Y;
    } else {
      this.x = FEATURE_POPUP_LANDSCAPE_POSITION_X;
      this.y = FEATURE_POPUP_LANDSCAPE_POSITION_Y;
    }
  }
}
