import * as PIXI from 'pixi.js';

import { MessageWinBannerProps } from '../../global.d';
import { setNextResult, setRespinCnt, setRespinSymbolType, setResumeRespinResult } from '../../gql/cache';
import i18n from '../../i18next';
import { isSuccessRespin } from '../../utils';
import AutoResizeText from '../components/autoResizeText';
import { TickerSpine } from '../components/spine';

import { BaseMessageBanner } from './baseMessageBanner';
import { MESSAGE_WIN_BANNER_RESPIN_Y, MESSAGE_WIN_BANNER_TITLE_Y, MESSAGE_WIN_BANNER_WIN_AMOUNT_Y } from './config';
import {
  respinResultRespinCountOkawariTextStyle,
  respinResultRespinCountTextStyle,
  respinResultRespinCountXTextStyle,
  totalWinAmountTextStyles,
  totalWinTitleStyles,
} from './textStyles';

export class MessageWinBanner extends BaseMessageBanner {
  protected override props: MessageWinBannerProps;

  private banner: TickerSpine<'banner_rs_end'>;

  private totalWin?: PIXI.Text;

  constructor(props: MessageWinBannerProps) {
    super(props);
    this.props = props;
    const backdrop = new PIXI.Sprite(PIXI.Texture.WHITE);
    backdrop.anchor.set(0.5);
    backdrop.tint = 0x000000;
    backdrop.alpha = 0.3;
    backdrop.width = 5000;
    backdrop.height = 5000;
    this.addChild(backdrop);

    this.banner = this.initBanner();
    this.banner.visible = false;
    this.addChild(this.banner);
  }

  public override init(): MessageWinBanner {
    super.init();

    this.totalWin = this.initWinTotal(this.props.totalWin);
    this.banner.scale.set(1.0);
    this.banner.visible = true;
    const title = this.initTitle();
    const okawariTxt = this.initOkawari();
    const respinCount = this.initRespinCount();
    const respinCountX = this.initRespinCountX();
    this.addChild(title, this.totalWin, okawariTxt, respinCountX, respinCount);

    if (setResumeRespinResult()) {
      respinCount.visible = false;
      respinCountX.visible = false;
      okawariTxt.visible = false;
      title.y = title.y + 50;
      this.totalWin.y = this.totalWin.y + 100;
    }
    setResumeRespinResult(false);

    return this;
  }

  protected initBanner(): TickerSpine {
    const banner = new TickerSpine('banner_rs_end');
    banner.update(0);
    if (setResumeRespinResult()) {
      banner.skeleton.setSkinByName(`default`);
    } else {
      banner.skeleton.setSkinByName(`symbol_${setRespinSymbolType().toLowerCase()}`);
    }
    return banner;
  }

  protected initTitle(): PIXI.Sprite {
    const winTitle = new AutoResizeText(i18n.t('freeSpinsTotalWin.Title'), totalWinTitleStyles);
    winTitle.anchor.set(0.5);
    winTitle.y = MESSAGE_WIN_BANNER_TITLE_Y;
    return winTitle;
  }

  protected initWinTotal(totalWin: string): AutoResizeText {
    const winTotal = new AutoResizeText(totalWin, totalWinAmountTextStyles);
    winTotal.updateText(true);
    winTotal.anchor.set(0.5);
    winTotal.y = MESSAGE_WIN_BANNER_WIN_AMOUNT_Y;
    return winTotal;
  }

  protected initOkawari(): PIXI.Sprite {
    const owkawariTxt = new AutoResizeText('OKAWARI', respinResultRespinCountOkawariTextStyle);
    owkawariTxt.anchor.set(0, 0.5);
    owkawariTxt.x = -10;
    owkawariTxt.y = MESSAGE_WIN_BANNER_RESPIN_Y - 85;
    return owkawariTxt;
  }

  private initRespinCountX(): AutoResizeText {
    const respinCount = new AutoResizeText('X', respinResultRespinCountXTextStyle);
    respinCount.anchor.set(0.5, 0.5);
    respinCount.x = 618 - 1260 / 2 + 74 / 2;
    respinCount.y = MESSAGE_WIN_BANNER_RESPIN_Y;
    return respinCount;
  }

  private initRespinCount(): AutoResizeText {
    const respin = isSuccessRespin(setNextResult()) ? setRespinCnt() : setRespinCnt() - 1;
    const respinCount = new AutoResizeText(respin.toString(), respinResultRespinCountTextStyle);
    respinCount.anchor.set(0.5, 0.5);
    respinCount.x = 680 - 1260 / 2 + 70;
    respinCount.y = MESSAGE_WIN_BANNER_RESPIN_Y;
    return respinCount;
  }
}
