import { useQuery } from '@apollo/client';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { formatNumber } from '@phoenix7dev/utils-fe';

import { MAPPED_SYMBOLS, config } from '../../config';
import { setCoinValue, setSlotConfig } from '../../gql/cache';
import { getBetAmountGql, getUserGql } from '../../gql/query';
import { ResourceTypes } from '../../resources.d';
import { countCoins, getCombosDataFromIcon, getNonNullableValue } from '../../utils';
import Resources from '../../utils/resources';

import { IPaytableData } from './d';
import styles from './info.module.scss';

const breakSymbol = ResourceTypes.symbolE;
const noPaySymbol = ResourceTypes.symbolX;

const calcMultiplier = (multiplier: number, betAmount: number): number => {
  return (
    countCoins({
      totalAmount: betAmount / setSlotConfig().lineSet.coinAmountMultiplier,
      coinValue: setCoinValue(),
    }) * multiplier
  );
};

const PaytableComponent: React.FC = () => {
  const { t, i18n } = useTranslation();
  const { data: userData } = useQuery(getUserGql);
  const { data: dataBet } = useQuery(getBetAmountGql);

  const currency = userData!.user.balance!.currency;
  const { betAmount } = dataBet!;

  const baseData: IPaytableData[] = useMemo(() => {
    return config.payTableData.flatMap((slotId) => {
      const icon = getNonNullableValue(setSlotConfig()).icons.find((icon) => icon.id === slotId);
      return {
        slug: MAPPED_SYMBOLS[slotId],
        combos: icon ? getCombosDataFromIcon(icon) : [],
      };
    });
  }, []);

  return (
    <section>
      <h1 className={styles.title}>{t('infoPayTableTitle')}</h1>
      <div className={styles['paytable-list']}>
        {baseData.map(({ combos, slug }) => (
          <React.Fragment key={slug}>
            <div className={styles['paytable-list__item']}>
              <img draggable="false" alt={slug} src={Resources.getSource(slug)} />

              {slug === noPaySymbol && (
                <div className={styles.content}>
                  <span className={styles.multiplier}>{t(`infoPayTableNoPay`, '')}</span>
                </div>
              )}
              <div className={styles.content}>
                {combos.map((i) => (
                  <div key={i.pattern}>
                    <span className={styles.multiplier} data-combo={i.pattern}>
                      {i.pattern}{' '}
                    </span>
                    {formatNumber({ currency, value: calcMultiplier(i.multiplier, betAmount) })}
                  </div>
                ))}
              </div>
              {i18n.exists(`infoPayTable.${slug}`) && <p className={styles.caption}>{t(`infoPayTable.${slug}`, '')}</p>}
            </div>
            {slug === breakSymbol && (
              <div
                className={styles['paytable-list__explain']}
                dangerouslySetInnerHTML={{
                  __html: t(`infoPayTableExplain`),
                }}
              ></div>
            )}
          </React.Fragment>
        ))}
      </div>
    </section>
  );
};

export default PaytableComponent;
