import { IPointData } from 'pixi.js';

import { SpineInterface } from '../../config/spine.generated';
import { BuyFeatureType } from '../../global';

export const FEATURE_BTN_MOUSE_OVER_SCALE = 1.13;
export const FEATURE_BTN_MOUSE_CLICK_SCALE = 0.9;

export const FEATURE_BTN_LANDSCAPE_X = -159;

export const FEATURE_BTN_TEXT_X_OFFSET = 0;
export const FEATURE_BTN_TEXT_Y_OFFSET = 0;
export const FEATURE_BTN_TEXT_PRESS_X_OFFSET = FEATURE_BTN_TEXT_X_OFFSET;
export const FEATURE_BTN_TEXT_PRESS_Y_OFFSET = FEATURE_BTN_TEXT_Y_OFFSET;

export const FEATURE_POPUP_SPINE_WIDTH = 1522;
export const FEATURE_POPUP_SPINE_HEIGHT = 952;
export const FEATURE_POPUP_CENTER_X = 649 + 319 / 2;
export const FEATURE_POPUP_TITLE_POSITION_X = FEATURE_POPUP_CENTER_X;
export const FEATURE_POPUP_TITLE_POSITION_Y = 170 + 60;
export const FEATURE_POPUP_BET_TEXT_POSITION = [FEATURE_POPUP_CENTER_X, 722 + 18];
export const FEATURE_POPUP_BET_VALUE_POSITION_X = FEATURE_POPUP_CENTER_X;
export const FEATURE_POPUP_BET_VALUE_POSITION_Y = 810 + 18;
export const FEATURE_POPUP_CANCEL_BTN_POSITION_X = 175 + 130 / 2;
export const FEATURE_POPUP_CANCEL_BTN_POSITION_Y = 760 + 128 / 2;
export const FEATURE_POPUP_MINUS_BTN_POSITION_X = 483 + 94 / 2;
export const FEATURE_POPUP_MINUS_BTN_POSITION_Y = 765 + 126 / 2;
export const FEATURE_POPUP_MP_BTN_HITAREA_SCALE = 1.8;
export const FEATURE_POPUP_OK_BTN_POSITION_X = 1274 + 130 / 2;
export const FEATURE_POPUP_OK_BTN_POSITION_Y = FEATURE_POPUP_CANCEL_BTN_POSITION_Y;
export const FEATURE_POPUP_PLUS_BTN_POSITION_X = 1037 + 94 / 2;
export const FEATURE_POPUP_PLUS_BTN_POSITION_Y = FEATURE_POPUP_MINUS_BTN_POSITION_Y;

export const FEATURE_POPUP_CONFIRM_TITLE_POSITION_X = FEATURE_POPUP_CENTER_X - 20;
export const FEATURE_POPUP_CONFIRM_TITLE_POSITION_Y = 214 + 56;
export const FEATURE_POPUP_CONFIRM_TEXT_POSITION_Y = 346 + 18;
export const FEATURE_POPUP_CONFIRM_TOTAL_COST_TEXT_POSITION_X = 614;
export const FEATURE_POPUP_CONFIRM_TOTAL_COST_TEXT_POSITION_Y = 480;
export const FEATURE_POPUP_CONFIRM_TOTAL_COST_VALUE_POSITION_X = 747 + 335;
export const FEATURE_POPUP_CONFIRM_TOTAL_COST_VALUE_POSITION_Y = 574 + 100;
export const FEATURE_POPUP_CONFIRM_CANCEL_BTN_POSITION = [565 + 130 / 2, 736 + 128 / 2];
export const FEATURE_POPUP_CONFIRM_OK_BTN_POSITION = [863 + 130 / 2, 736 + 128 / 2];

export const FEATURE_POPUP_LANDSCAPE_POSITION_X = 139 - 289;
export const FEATURE_POPUP_LANDSCAPE_POSITION_Y = 384 - 581;
export const FEATURE_POPUP_PORTRAIT_POSITION_X = 150 - 289;
export const FEATURE_POPUP_PORTRAIT_POSITION_Y = 384 - 581;

export type BuyFeatureDispInfoType = {
  animName: SpineInterface['buyfeature']['animations'];
  confSkin: SpineInterface['buyfeature']['skins'];
  multiplier: number;
  pos: IPointData;
  bonusNo: number;
};

const BUYFEATURE_ITEM_UPPER_Y = 409;
const BUYFEATURE_ITEM_DOWNER_Y = 633;
const BUYFEATURE_ITEM_LEFT_X = 580;
const BUYFEATURE_ITEM_CENTER_X = 1037;
const BUYFEATURE_ITEM_RIGHT_X = 1473;

export const FEATURE_DISP_INFO: Record<BuyFeatureType, BuyFeatureDispInfoType> = {
  A: {
    animName: 'select_a',
    confSkin: 'conf_symbol_a',
    multiplier: 150,
    pos: { x: BUYFEATURE_ITEM_LEFT_X, y: BUYFEATURE_ITEM_UPPER_Y },
    bonusNo: 0,
  },
  B: {
    animName: 'select_b',
    confSkin: 'conf_symbol_b',
    multiplier: 80,
    pos: { x: BUYFEATURE_ITEM_CENTER_X, y: BUYFEATURE_ITEM_UPPER_Y },
    bonusNo: 1,
  },
  C: {
    animName: 'select_c',
    confSkin: 'conf_symbol_c',
    multiplier: 50,
    pos: { x: BUYFEATURE_ITEM_RIGHT_X, y: BUYFEATURE_ITEM_UPPER_Y },
    bonusNo: 2,
  },
  D: {
    animName: 'select_d',
    confSkin: 'conf_symbol_d',
    multiplier: 30,
    pos: { x: BUYFEATURE_ITEM_LEFT_X, y: BUYFEATURE_ITEM_DOWNER_Y },
    bonusNo: 3,
  },
  E: {
    animName: 'select_e',
    confSkin: 'conf_symbol_e',
    multiplier: 25,
    pos: { x: BUYFEATURE_ITEM_CENTER_X, y: BUYFEATURE_ITEM_DOWNER_Y },
    bonusNo: 4,
  },
  M: {
    animName: 'select_m',
    confSkin: 'conf_mystery',
    multiplier: 50,
    pos: { x: BUYFEATURE_ITEM_RIGHT_X, y: BUYFEATURE_ITEM_DOWNER_Y },
    bonusNo: 5,
  },
};
