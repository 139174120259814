import AudioApi from '@phoenix7dev/audio-api';

import { ISongs, SlotId } from '../../config';
import { GameMode } from '../../consts';
import { ReelStopSoundType, RespinSymbolType } from '../../global.d';
import { setGameMode, setIsRespinRevivePattern, setNextResult, setRespinCnt } from '../../gql/cache';
import {
  getRespinMultipleCount,
  getSpecialRounds,
  isBuyFeatureMode,
  isFreeSpinsMode,
  isSuccessRespin,
} from '../../utils';
import { TweenProperties } from '../animations/d';
import Tween from '../animations/tween';
import { RespinActionInfo } from '../announce/config';
import ViewContainer from '../components/container';
import { TickerSpine } from '../components/spine';
import {
  ADDITIONAL_SPIN_TIME_PER_REEL,
  ANTICIPATION_SLOTS_TINT,
  BASE_REEL_ENDING_DURATION,
  BASE_REEL_ENDING_FORMULA,
  BASE_REEL_FIRST_ROLLING_DURATION,
  BASE_REEL_ROLLING_DURATION,
  BASE_REEL_ROLLING_SPEED,
  BASE_REEL_STARTING_DURATION,
  BASE_REEL_STARTING_FORMULA,
  EventTypes,
  FAKE_ROLLING_DURATION,
  MINIMUM_SPIN_SLOTS_AMOUNT,
  REELS_AMOUNT,
  REEL_ENDING_SLOTS_AMOUNT,
  REEL_STARTING_SLOTS_AMOUNT,
  REEL_STARTING_SLOTS_AMOUNT_DUMMY,
  REEL_WIDTH,
  ReelState,
  SLOTS_PER_REEL_AMOUNT,
  SLOT_HEIGHT,
  SLOT_RESOURCE_HEIGHT,
  SPIN_REEL_ANIMATION_DELAY_PER_REEL,
  TURBO_ADDITIONAL_SPIN_TIME_PER_REEL,
  TURBO_REEL_ENDING_DURATION,
  TURBO_REEL_FIRST_ROLLING_DURATION,
  TURBO_REEL_ROLLING_DURATION,
  TURBO_REEL_ROLLING_SPEED,
  TURBO_REEL_STARTING_DURATION,
  TURBO_SPIN_REEL_ANIMATION_DELAY_PER_REEL,
  eventManager,
} from '../config';
import { Slot } from '../slot/slot';
import SpinAnimation from '../spin/spin';

import { IReel } from './d';

declare global {
  interface String {
    toLowerCase<T extends string>(this: T): Lowercase<T>;
  }
}

type lowerCaseHighPaySymbol = Lowercase<RespinSymbolType>;

class Reel implements IReel {
  public id: number;

  public state: ReelState;

  public data: SlotId[];

  public container: ViewContainer;

  public position = 0;

  public previousPosition = 0;

  public spinAnimation: SpinAnimation | null = null;

  public slots: Slot[] = [];

  public animator: () => void = this.reelAnimator.bind(this);

  public isPlaySoundOnStop = false;

  public isTurboSpin = false;

  public size: number;

  public stopSoundSymbolNo: ReelStopSoundType = 'normal';

  public isLongSpin = false;

  public spineSlot: {
    spine: TickerSpine<`symbol_${lowerCaseHighPaySymbol}`>;
    index: number;
  }[] = [];

  constructor(id: number, data: SlotId[], startPosition: number) {
    this.id = id;
    this.data = data;
    this.size = data.length;
    this.state = ReelState.IDLE;
    this.container = new ViewContainer();
    this.container.width = REEL_WIDTH;
    this.container.x = id * REEL_WIDTH;
    this.container.y = 0;
    this.container.sortableChildren = true;
    this.createSlots();
    this.position = this.size - startPosition;
    eventManager.addListener(EventTypes.ANTICIPATION_STARTS, this.onAnticipationStart.bind(this));
    eventManager.addListener(EventTypes.ANTICIPATION_ANIMATIONS_END, this.resetSlotsTint.bind(this));
    eventManager.addListener(EventTypes.ANTICIPATION_SLOT_ANIMATIONS_END, this.resetTintForExcludedSlots.bind(this));
    eventManager.addListener(EventTypes.REELS_STOPPED, this.onReelsStopped.bind(this));
    eventManager.addListener(EventTypes.REMOVE_ANTICIPATION_TINT, this.resetSlotsTint.bind(this));
  }

  public init(data: SlotId[], position: number): void {
    this.data = data;
    this.size = data.length;
    this.createSlots();
    this.position = position;
    this.clearSpineSlot();
  }

  public clean(): void {
    this.container.removeChildren();
    this.slots = [];
    this.clearSpineSlot();
  }

  private clearSpineSlot(): void {
    this.spineSlot.forEach((slot) => {
      this.container.removeChild(slot.spine);
    });
    this.spineSlot = [];
  }

  private onAnticipationStart(index: number, ids: SlotId[] | undefined): void {
    if (this.id !== index) return;
    const slotStopDelay = Tween.createDelayAnimation(466);
    slotStopDelay.addOnComplete(() => {
      this.isLongSpin = true;
      AudioApi.play({ type: ISongs.XT003S_longspin, stopPrev: true });
      this.spineSlot = [];
      this.slots.forEach((slot, index) => {
        if (ids && !ids.includes(slot.textureSlotId)) {
          slot.tint = ANTICIPATION_SLOTS_TINT;
        } else {
          if (ids) {
            const spine = new TickerSpine(`symbol_${slot.textureSlotId.toLowerCase()}`);
            spine.state.setAnimation(0, 'longspin', true);
            spine.x = slot.x;
            spine.y = slot.y;
            this.spineSlot.push({ spine, index });
            this.container.addChild(spine);
          } else {
            throw new Error(`Invalid SlotId: ${ids}`);
          }
        }
      });
    });
    slotStopDelay.start();
  }

  private onReelsStopped(): void {
    if (setNextResult() && !getSpecialRounds()) {
      this.resetSlotsTint();
    }
  }

  private resetSlotsTint(): void {
    this.slots.forEach((slot) => {
      slot.tint = 0xffffff;
    });
  }

  private resetTintForExcludedSlots(reachSymbols: SlotId[], _reelIdx: number): void {
    this.slots.forEach((slot) => {
      if (!reachSymbols.includes(slot.slotId)) {
        slot.tint = 0xffffff;
      }
    });
  }

  private createSlots(): void {
    for (let i = 0; i < this.data.length; i++) {
      const slotId = this.data[i % this.data.length]!;
      const slot = new Slot(i, slotId);
      this.slots.push(slot);
      this.container.addChild(slot);
    }
  }

  public getTarget(expected: number): number {
    if (expected - this.position > MINIMUM_SPIN_SLOTS_AMOUNT) {
      return expected;
    }
    let amount = expected - this.position;
    while (amount < MINIMUM_SPIN_SLOTS_AMOUNT) amount += this.data.length;
    return amount + this.position;
  }

  private getRollingDuration(_: GameMode): number {
    if (this.isTurboSpin) {
      return TURBO_REEL_ROLLING_DURATION + this.id * TURBO_ADDITIONAL_SPIN_TIME_PER_REEL;
    }

    return BASE_REEL_ROLLING_DURATION + this.id * ADDITIONAL_SPIN_TIME_PER_REEL;
  }

  public createSpinAnimation(isTurboSpin: boolean | undefined, isSynchroStart?: boolean): SpinAnimation {
    this.position %= this.data.length;
    this.isTurboSpin = !!isTurboSpin;
    const rollingSpeed = isTurboSpin ? TURBO_REEL_ROLLING_SPEED : BASE_REEL_ROLLING_SPEED;
    const rollingTime = this.getRollingDuration(setGameMode());
    const target = this.position + Math.round(rollingTime * rollingSpeed);

    const startingDelay = isSynchroStart
      ? 0
      : isTurboSpin
      ? TURBO_SPIN_REEL_ANIMATION_DELAY_PER_REEL
      : SPIN_REEL_ANIMATION_DELAY_PER_REEL * this.id;

    const starting = new Tween({
      object: this,
      property: TweenProperties.POSITION,
      propertyBeginValue: this.position,
      target: this.position + REEL_STARTING_SLOTS_AMOUNT_DUMMY,
      easing: BASE_REEL_STARTING_FORMULA,
      delay: startingDelay,
      duration: isTurboSpin ? TURBO_REEL_STARTING_DURATION : BASE_REEL_STARTING_DURATION,
    });
    starting.addOnStart(() => {
      this.changeState(ReelState.STARTING);
      const delay = Tween.createDelayAnimation(starting.delay);
      delay.addOnComplete(() => {
        eventManager.emit(EventTypes.START_SPIN_BY_REEL, this.id);
      });
      delay.start();
    });
    starting.addOnComplete(() => {
      eventManager.emit(EventTypes.START_SPIN_BY_REEL_ON_COMPLETE, this.id);
      eventManager.emit(EventTypes.HIDE_STOP_SLOTS_DISPLAY, this.id);
    });

    const firstRolling = new Tween({
      object: this,
      property: TweenProperties.POSITION,
      propertyBeginValue: this.position + REEL_STARTING_SLOTS_AMOUNT_DUMMY,
      target:
        this.position + REEL_STARTING_SLOTS_AMOUNT_DUMMY + Math.round(BASE_REEL_FIRST_ROLLING_DURATION * rollingSpeed),
      duration: isTurboSpin ? TURBO_REEL_FIRST_ROLLING_DURATION : BASE_REEL_FIRST_ROLLING_DURATION,
    });
    const fakeRolling = new Tween({
      object: this,
      property: TweenProperties.POSITION,
      propertyBeginValue: this.position + REEL_STARTING_SLOTS_AMOUNT_DUMMY,
      target: this.position + REEL_STARTING_SLOTS_AMOUNT_DUMMY + Math.round(FAKE_ROLLING_DURATION * rollingSpeed),
      duration: FAKE_ROLLING_DURATION,
    });
    fakeRolling.addOnStart(() => {
      this.changeState(ReelState.ROLLING);
    });
    const rolling = new Tween({
      object: this,
      property: TweenProperties.POSITION,
      propertyBeginValue: this.position + REEL_STARTING_SLOTS_AMOUNT,
      target: target - REEL_ENDING_SLOTS_AMOUNT,
      duration: rollingTime,
    });
    const ending = new Tween({
      object: this,
      property: TweenProperties.POSITION,
      propertyBeginValue: target - REEL_ENDING_SLOTS_AMOUNT,
      target,
      easing: BASE_REEL_ENDING_FORMULA,
      duration: isTurboSpin ? TURBO_REEL_ENDING_DURATION : BASE_REEL_ENDING_DURATION,
    });
    ending.addOnStart(() => {
      this.changeState(ReelState.ENDING);
      this.slots.forEach((slot) => {
        slot.visible = true;
      });
    });
    ending.addOnComplete(() => {
      this.changeState(ReelState.IDLE);
      this.onReelStop();
    });
    this.spinAnimation = new SpinAnimation({
      startingAnimation: starting,
      firstRollingAnimation: firstRolling,
      fakeRollingAnimation: fakeRolling,
      rollingAnimation: rolling,
      endingAnimation: ending,
    });
    return this.spinAnimation;
  }

  private onReelEnding(_previousState: ReelState, _newState: ReelState): void {}

  private onReelStop(): void {
    const stopSlots = this.getReelStopSlots(Math.round(this.position));
    let stopSound: ISongs = ISongs.XT003S_spin_stop1;

    if (isFreeSpinsMode(setGameMode())) {
      if (!isSuccessRespin(setNextResult()) || setIsRespinRevivePattern()) {
        stopSound = ISongs.XT003S_okwr_stop_gase;
      } else {
        stopSound =
          RespinActionInfo['respinStopSound'][getRespinMultipleCount(setRespinCnt() > 0 ? setRespinCnt() - 1 : 0)]!;
      }
    } else {
      switch (this.stopSoundSymbolNo) {
        case 'normal':
          const RANDOM_STOP_SOUNDS: ISongs[] = [
            ISongs.XT003S_spin_stop1,
            ISongs.XT003S_spin_stop2,
            ISongs.XT003S_spin_stop3,
            ISongs.XT003S_spin_stop4,
            ISongs.XT003S_spin_stop5,
          ];
          if (stopSlots.slice(0, 3).some((slot) => slot.slotId === 'W')) {
            stopSound = ISongs.XT003S_wild_stop;
          } else {
            stopSound = RANDOM_STOP_SOUNDS[Math.floor(Math.random() * RANDOM_STOP_SOUNDS.length)]!;
          }
          break;
        case '3of':
          stopSound = ISongs.XT003S_3ok_stop;
          break;
        case '4of':
          stopSound = ISongs.XT003S_4ok_stop;
          break;
        case '5of':
          stopSound = ISongs.XT003S_5ok_stop;
          break;
      }
      if (isBuyFeatureMode()) {
        stopSound = ISongs.XT003S_5ok_stop;
      }
    }
    if (this.id === REELS_AMOUNT - 1) {
      AudioApi.stop({ type: ISongs.XT003S_spin_loop });

      if (this.isLongSpin) {
        AudioApi.stop({ type: ISongs.XT003S_longspin });
        this.isLongSpin = false;
      }
    }
    if (this.isPlaySoundOnStop) {
      AudioApi.play({
        type: stopSound,
        stopPrev: true,
      });
      this.isPlaySoundOnStop = false;
    }
    this.clearSpineSlot();
  }

  private onReelIdle(previousState: ReelState, _newState: ReelState): void {
    if (previousState === ReelState.ENDING) {
      eventManager.emit(EventTypes.REEL_STOPPED, this.id, this.stopSoundSymbolNo);
      const reelStopSlots = this.getReelStopSlots(Math.round(this.position));
      reelStopSlots.forEach((slot) => {
        slot.onSlotStopped();
      });
    }
  }

  public stopReel(endingDuration: number): void {
    this.spinAnimation!.getStarting().end();
    this.spinAnimation!.getFirstRolling().end();
    this.spinAnimation!.getFakeRolling().end();
    this.spinAnimation!.getRolling().end();
    if (endingDuration) {
      this.spinAnimation!.getEnding().duration = endingDuration;
    }
  }

  private getReelStopSlots(position: number): Slot[] {
    const slots: Slot[] = [];
    const top = this.slots.length - ((position % this.slots.length) + 1);
    const middle = position % this.slots.length === 0 ? 0 : this.slots.length - (position % this.slots.length);
    const bottom = (this.slots.length - ((position % this.slots.length) - 1)) % this.slots.length;
    const extra = (this.slots.length - ((position % this.slots.length) - 2)) % this.slots.length;
    slots.push(this.slots[top]!);
    slots.push(this.slots[middle]!);
    slots.push(this.slots[bottom]!);
    slots.push(this.slots[extra]!);
    return slots;
  }

  private onReelRolling(_previousState: ReelState, _newState: ReelState): void {}

  private onReelStarting(_previousState: ReelState, _newState: ReelState): void {}

  public changeState(newState: ReelState): void {
    const previousState = this.state;
    this.state = newState;
    if (newState === ReelState.IDLE) {
      this.onReelIdle(previousState, ReelState.IDLE);
    }
    if (newState === ReelState.ROLLING) {
      this.onReelRolling(previousState, ReelState.ROLLING);
    }
    if (newState === ReelState.STARTING) {
      this.onReelStarting(previousState, ReelState.STARTING);
    }
    if (newState === ReelState.ENDING) {
      this.onReelEnding(previousState, ReelState.ENDING);
    }
  }

  public reelAnimator(): void {
    this.previousPosition = this.position;
    // Update symbol positions on reel.
    for (let j = 0; j < this.slots.length; j++) {
      const slot = this.slots[j]!;
      slot.y = ((this.position + j + 2) % this.slots.length) * SLOT_HEIGHT - SLOT_HEIGHT;
      slot.toggleBlur(this.state === ReelState.ROLLING);

      this.spineSlot.forEach((spine) => {
        if (spine.index === j) {
          spine.spine.y = slot.y + SLOT_RESOURCE_HEIGHT / 2;
          spine.spine.visible = -SLOT_HEIGHT <= slot.y && slot.y <= SLOT_HEIGHT * SLOTS_PER_REEL_AMOUNT;
        }
      });
    }
  }
}

export default Reel;
