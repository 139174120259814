import { GameMode } from '../../consts';
import {
  setBetAmount,
  setBrokenBuyFeatureGame,
  setBrokenGame,
  setCoinAmount,
  setCoinValue,
  setCurrency,
  setCurrentBonus,
  setDefaultReelSetId,
  setGameMode,
  setReelSetId,
  setSlotConfig,
  setUserLastBetResult,
  setWinAmount,
} from '../../gql/cache';
import client from '../../gql/client';
import { ISlotConfig } from '../../gql/d';
import { getSlotGql } from '../../gql/query';
import { findSubstituteCoinAmount, getNonNullableValue } from '../../utils';

export const useSlotData = () => {
  const getSlotData = async () => {
    const slotData = await client.query({
      query: getSlotGql,
      variables: { input: { id: setSlotConfig().id } },
      fetchPolicy: 'network-only',
    });
    const { slot } = slotData.data;

    setSlotConfig({
      ...setSlotConfig(),
      clientSettings: slot!.clientSettings,
      icons: slot!.icons,
      reels: slot!.reels,
      lines: slot!.lines,
      lineSet: slot!.lineSets![0]!,
      settings: slot!.settings,
    });
    const reelSetId = slot!.reels!.find((reelSet) => reelSet!.type === 'DEFAULT')?.id;
    setReelSetId(reelSetId);
    setDefaultReelSetId(reelSetId);
    setGameMode(GameMode.REGULAR);

    const lastResult = setUserLastBetResult();
    const coinValue = getCoinValue(slot!.clientSettings);
    setCoinValue(coinValue);

    const coinAmount = getCoinAmount(slot!.clientSettings);
    setCoinAmount(coinAmount);
    setWinAmount(lastResult?.result.winCoinAmount);
    setBetAmount(coinAmount * slot!.lineSets![0]!.coinAmountMultiplier);
  };

  return { getSlotData };
};

function getCoinValue(clientSettings: ISlotConfig['clientSettings']): number {
  if (setBrokenGame() || setBrokenBuyFeatureGame()) {
    return setCurrentBonus().coinValue!;
  }

  return getNonNullableValue(clientSettings).coinValues.find((elem) => elem.code === setCurrency())?.variants[0]!;
}

function getCoinAmount(clientSettings: ISlotConfig['clientSettings']): number {
  if (setBrokenGame() || setBrokenBuyFeatureGame()) {
    return setCurrentBonus().coinAmount!;
  }
  const lastResult = setUserLastBetResult();
  const lastBetCoinAmount = lastResult?.id ? lastResult.coinAmount : 1;
  return findSubstituteCoinAmount(lastBetCoinAmount, getNonNullableValue(clientSettings).coinAmounts.default);
}
