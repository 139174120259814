import * as PIXI from 'pixi.js';
import { ITextStyle } from 'pixi.js';

export declare interface AutoResizeTextStyle extends ITextStyle {
  maxWidth?: number;
  maxHeight?: number;
}

class AutoResizeText extends PIXI.Text {
  constructor(text: string, style?: Partial<AutoResizeTextStyle>) {
    super(text, style);
    this.autoResizeFontSize();
  }

  public override updateText(respectDirty: boolean): void {
    const isDirty = this.dirty || !respectDirty || this.localStyleID !== this._style.styleID;
    super.updateText(respectDirty);
    if (isDirty) this.autoResizeFontSize();
  }

  public override updateTransform(): void {
    super.updateTransform();

    const sx = this.worldTransform.a;
    const sy = this.worldTransform.d;
    const coff = Math.min(Math.max(sx, sy), 1);
    this.resolution = Math.max(Math.ceil(window.devicePixelRatio * coff * 10) / 10, 1);
  }

  private autoResizeFontSize(): void {
    if (!this.isAutoResizeTextStyle(this.style)) {
      return;
    }
    const fontScaleW = this.style.maxWidth ? this.style.maxWidth / this.texture.width : 1;
    const fontScaleH = this.style.maxHeight ? this.style.maxHeight / this.texture.height : 1;

    this.scale.set(Math.min(1, fontScaleW, fontScaleH));
  }

  private isAutoResizeTextStyle = (style: Partial<AutoResizeTextStyle>): style is AutoResizeTextStyle => {
    return 'maxWidth' in style || 'maxHeight' in style;
  };
}

export default AutoResizeText;
