import { isMobile } from 'mobile-device-detect';
import type React from 'react';
import { useTranslation } from 'react-i18next';

import { lobbyBtnAvailable } from '@phoenix7dev/utils-fe';

import { queryParams } from '../../utils';

import InfoSvg from './icons/InfoSvg';
import AutoplayOffSvg from './icons/autoplayOffSvg';
import AutoplayOnSvg from './icons/autoplayOnSvg';
import BetSvg from './icons/betSvg';
import BurgerSvg from './icons/burgerSvg';
import ConfirmSvg from './icons/confirmSvg';
import HistorySvg from './icons/historySvg';
import LobbySvg from './icons/lobbySvg';
import SkipSvg from './icons/skipSvg';
import SoundSvg from './icons/soundSvg';
import SpinSvg from './icons/spinSvg';
import StartSvg from './icons/startSvg';
import TurbospinOffSvg from './icons/turbospinOffSvg';
import styles from './info.module.scss';

const ButtonBehaviors: React.FC = () => {
  const { t } = useTranslation();

  return (
    <section>
      <h1 className={styles.title}>{t('infoButtonBehaviors.Title')}</h1>
      <div className={styles.buttonBehaviors}>
        <div className={`${styles.buttonBehaviors__item} ${styles.start}`}>
          <StartSvg />
          {t('infoButtonBehaviors.Start')}
        </div>
        <div className={`${styles.buttonBehaviors__item} ${styles.spin}`}>
          <SpinSvg />
          {t('infoButtonBehaviors.Spin')}
        </div>
        <div className={`${styles.buttonBehaviors__item} ${styles.stop}`}>
          <SkipSvg />
          {t('infoButtonBehaviors.Stop')}
        </div>
        <div className={styles.buttonBehaviors__item}>
          <AutoplayOnSvg />
          {t('infoButtonBehaviors.AutoPlay')}
        </div>
        <div className={styles.buttonBehaviors__item}>
          <AutoplayOffSvg />
          {t('infoButtonBehaviors.AutoPlayStop')}
        </div>
        <div className={styles.buttonBehaviors__item}>
          <BetSvg />
          {t('infoButtonBehaviors.BetSettings')}
        </div>
        <div className={styles.buttonBehaviors__item}>
          <TurbospinOffSvg />
          {t('infoButtonBehaviors.Turbo')}
        </div>
        <div className={styles.buttonBehaviors__item}>
          <BurgerSvg />
          {t('infoButtonBehaviors.Menu')}
        </div>
        <div className={styles.buttonBehaviors__item}>
          <InfoSvg />
          {t('infoButtonBehaviors.MenuOpen')}
        </div>
      </div>

      <h1 className={styles.subtitle}>{t('infoButtonBehaviors.BetSettingsTitle')}</h1>
      <p
        className={`${styles.p} ${styles.center}`}
        dangerouslySetInnerHTML={{
          __html: t('infoButtonBehaviors.BetSettingsText'),
        }}
      />
      <h1 className={styles.subtitle}>{t('infoButtonBehaviors.AutoPlaySettingsTitle')}</h1>
      <p
        className={`${styles.p} ${styles.center}`}
        dangerouslySetInnerHTML={{
          __html: t('infoButtonBehaviors.AutoPlaySettingsText'),
        }}
      />
      <div className={`${styles.row} ${styles.center}`}>
        <div className={styles.image}>
          <ConfirmSvg />
          <p className={styles.image__text}>{t('infoButtonBehaviors.AutoPlayStart')}</p>
        </div>
      </div>

      <h1 className={styles.subtitle}>{t('infoButtonBehaviors.MenuTitle')}</h1>
      <div className={styles.buttonBehaviors}>
        <div className={styles.buttonBehaviors__item}>
          <SoundSvg />
          {t('infoButtonBehaviors.Sound')}
        </div>
        {(lobbyBtnAvailable(queryParams).desktop || isMobile) && (
          <div className={styles.buttonBehaviors__item}>
            <LobbySvg />
            {t('infoButtonBehaviors.MenuLobby')}
          </div>
        )}
        <div className={styles.buttonBehaviors__item}>
          <HistorySvg />
          {t('infoButtonBehaviors.MenuHistory')}
        </div>
      </div>
      <p className={`${styles.p} ${styles.center}`}>{t('infoButtonBehaviors.Description')}</p>
    </section>
  );
};

export default ButtonBehaviors;
