import * as PIXI from 'pixi.js';

import { MAPPED_BLURRED_SYMBOLS, MAPPED_SYMBOLS, SlotId } from '../../config';
import { SLOT_RESOURCE_HEIGHT, SLOT_SCALE, SLOT_WIDTH } from '../config';

export class Slot extends PIXI.Sprite {
  private id: number;

  public slotId: SlotId;

  public textureSlotId: SlotId;

  constructor(id: number, slotId: SlotId) {
    super(PIXI.Texture.from(MAPPED_SYMBOLS[slotId]));
    this.textureSlotId = slotId;
    this.id = id;
    this.slotId = slotId;
    this.scale.set(SLOT_SCALE);
    this.anchor.set(0.5, 0.5);
    //    this.pivot.y = -SLOT_HEIGHT / 2;
    this.pivot.y = -SLOT_RESOURCE_HEIGHT / 2;

    this.x = SLOT_WIDTH / 2;
    //    this.y = SLOT_HEIGHT * id + SLOT_HEIGHT * 2; // reset in reel.ts

    if (slotId === SlotId.F) {
      this.zIndex = 10;
    }
  }

  public changeTexture(slotId: SlotId): void {
    this.texture = PIXI.Texture.from(MAPPED_SYMBOLS[slotId]);
    this.textureSlotId = slotId;
  }

  public toggleBlur(blur: boolean): void {
    if (blur) {
      this.texture = PIXI.Texture.from(MAPPED_BLURRED_SYMBOLS[this.textureSlotId]);
      this.scale.set(SLOT_SCALE);
      //      this.anchor.set(0.5, (473 + 200) / 800);
      this.anchor.set(0.5, 0.5);
    } else {
      this.texture = PIXI.Texture.from(MAPPED_SYMBOLS[this.textureSlotId]);
      this.scale.set(SLOT_SCALE);
      this.anchor.set(0.5, 0.5);
    }
  }

  public onSlotStart(): void {}

  public onSlotStopped(): void {
    // on slot stopped
  }
}
