import { SlotId } from '../../config';
import { GameMode } from '../../consts';
import { AnticipationInfo, ISettledBet, ReelStopSoundType, RespinSymbolType } from '../../global.d';
import { setAnticipationSymbolType, setGameMode, setIsRevokeThrowingError, setRespinSymbolType } from '../../gql/cache';
import { getNonNullableValue, getSpecialRounds, isBuyFeatureMode, isFreeSpinsMode, isRespinSlotId } from '../../utils';
import Animation from '../animations/animation';
import AnimationChain from '../animations/animationChain';
import AnimationGroup from '../animations/animationGroup';
import Tween from '../animations/tween';
import { AnnounceType } from '../announce/config';
import ViewContainer from '../components/container';
import {
  ANTICIPATION_LONG_START_REELID,
  ANTICIPATION_SLOTS_TINT,
  EventTypes,
  REELS_AMOUNT,
  REEL_WIDTH,
  SLOTS_PER_REEL_AMOUNT,
  SLOT_HEIGHT,
  SLOT_RESOURCE_HEIGHT,
  SLOT_WIDTH,
  WIN_ANIMATION_INTERVAL,
  WIN_SLOT_TINT_COLOR,
  eventManager,
} from '../config';
import { Icon } from '../d';
import { IAnimateSlot, animateSlotFactory } from '../slot/animateSlot';

export class SlotsAnimationContainer extends ViewContainer {
  private slotSymbols: IAnimateSlot[] = [];

  constructor() {
    super();
    this.sortableChildren = true;

    eventManager.addListener(EventTypes.START_SPIN_ANIMATION, this.onStartSpin.bind(this));
    eventManager.addListener(EventTypes.SETUP_REEL_POSITIONS, this.setupSymbols.bind(this));
    eventManager.addListener(EventTypes.REEL_STOPPED, this.onReelStopped.bind(this));
    eventManager.addListener(
      EventTypes.ANTICIPATION_SLOT_ANIMATIONS_START,
      this.onAnticipationAnimationStarts.bind(this),
    );
    eventManager.addListener(EventTypes.ANTICIPATION_SLOT_ANIMATIONS_END, this.onAnticipationAnimationEnd.bind(this));
    eventManager.addListener(EventTypes.ANTICIPATION_ANIMATIONS_END, this.resetSlotsTint.bind(this));
    eventManager.addListener(EventTypes.SKIP_WIN_SLOTS_ANIMATION, this.skipWinSlotsAnimation.bind(this));
    eventManager.addListener(EventTypes.START_WIN_ANIMATION, this.onStartWinAnimation.bind(this));
    eventManager.addListener(EventTypes.SHOW_STOP_SLOTS_DISPLAY, (spinResult) => {
      this.initSymbols(spinResult);
      this.slotSymbols.forEach((slot) => (slot.visible = true));
    });
    eventManager.addListener(EventTypes.START_FS_WIN_ANIMATION, this.onStartFSWinAnimation.bind(this));

    eventManager.addListener(EventTypes.START_SPIN_BY_REEL, this.startSpinByReel.bind(this));
    eventManager.addListener(EventTypes.START_SPIN_BY_REEL_ON_COMPLETE, this.startSpinByReelonComplete.bind(this));
    eventManager.addListener(EventTypes.REPLACE_SLOT_SYMBOL, this.replaceSlotSymbols.bind(this));

    eventManager.addListener(EventTypes.CHANGE_MODE, this.onChangeMode.bind(this));
  }

  private initSymbols(spinResult: Icon[]) {
    if (isFreeSpinsMode(setGameMode())) {
      for (let i = 0; i < SLOTS_PER_REEL_AMOUNT; i++) {
        const j = 2;
        this.removeChild(this.slotSymbols[i * REELS_AMOUNT + j]!);
        const symbol = animateSlotFactory(spinResult[i * REELS_AMOUNT + j]!.id);
        symbol.x = REEL_WIDTH * j + SLOT_WIDTH / 2;
        symbol.y = SLOT_HEIGHT * i + SLOT_RESOURCE_HEIGHT / 2;
        this.addChild(symbol);
        this.slotSymbols[i * REELS_AMOUNT + j] = symbol;
        symbol.visible = false;
      }
    } else {
      this.cleanSymbols();

      for (let i = 0; i < SLOTS_PER_REEL_AMOUNT; i++) {
        for (let j = 0; j < REELS_AMOUNT; j++) {
          const symbol = animateSlotFactory(spinResult[i * REELS_AMOUNT + j]!.id);
          symbol.x = REEL_WIDTH * j + SLOT_WIDTH / 2;
          symbol.y = SLOT_HEIGHT * i + SLOT_RESOURCE_HEIGHT / 2;
          this.addChild(symbol);
          this.slotSymbols.push(symbol);
          symbol.visible = false;
        }
      }
    }
  }
  private onStartSpin(): void {
    if (!isFreeSpinsMode(setGameMode())) return;

    this.slotSymbols.forEach((slot) => {
      if (slot.slotId === setRespinSymbolType()) {
        slot.zIndex = 3;
      } else {
        slot.tint = ANTICIPATION_SLOTS_TINT;
      }
    });
  }

  private setupSymbols(
    _reelPositions: number[],
    _reelStopSounds: ReelStopSoundType[],
    _anticipationInfo: AnticipationInfo[],
    _announceType: AnnounceType,
    spinResult: Icon[],
  ): void {
    this.initSymbols(spinResult);
  }

  private cleanSymbols(): void {
    this.removeChild(...this.slotSymbols);
    this.slotSymbols = [];
  }

  private onChangeMode(settings: {
    mode: GameMode;
    reelPositions: number[];
    reelSetId: string;
    isRetrigger?: boolean;
  }) {
    if (isFreeSpinsMode(settings.mode)) {
      this.slotSymbols.forEach((slot) => {
        if (slot.slotId === setRespinSymbolType() || slot.slotId === SlotId.W) {
          slot.zIndex = 3;
        } else {
          slot.tint = ANTICIPATION_SLOTS_TINT;
        }
      });
    }
  }

  // Stop
  private onReelStopped(reelId: number, _: ReelStopSoundType): void {
    for (let i = 0; i < SLOTS_PER_REEL_AMOUNT; i++) {
      const slot = this.slotSymbols[i * REELS_AMOUNT + reelId]!;
      slot.visible = true;

      if (reelId === REELS_AMOUNT - 1 && getSpecialRounds() && slot.slotId === setAnticipationSymbolType()) {
        slot.startLongSpinStopAnimation();
      } else {
        if (reelId === REELS_AMOUNT - 1 && getSpecialRounds() && !isBuyFeatureMode()) {
          slot.tint = ANTICIPATION_SLOTS_TINT;
        }
        slot.startStopAnimation();
      }
    }
  }

  // Anticipation
  private onAnticipationAnimationStarts(reachSymbols: SlotId[], reelIdx: number): void {
    const slotStopDelay = Tween.createDelayAnimation(466);
    slotStopDelay.addOnComplete(() => {
      for (let i = 0; i < SLOTS_PER_REEL_AMOUNT; i++) {
        for (let j = 0; j <= reelIdx; j++) {
          const slot = this.slotSymbols[i * REELS_AMOUNT + j]!;
          if (reachSymbols.includes(slot.slotId) || slot.slotId === SlotId.W) {
            slot.zIndex = 3;
            slot.startLongspinAnimation();
          } else if (reelIdx >= ANTICIPATION_LONG_START_REELID - 1) {
            slot.tint = ANTICIPATION_SLOTS_TINT;
          }
        }
      }
    });
    slotStopDelay.start();
  }

  private onAnticipationAnimationEnd(reachSymbols: SlotId[], reelIdx: number): void {
    const slotStopDelay = Tween.createDelayAnimation(466);
    slotStopDelay.addOnComplete(() => {
      for (let i = 0; i < SLOTS_PER_REEL_AMOUNT; i++) {
        for (let j = 0; j < reelIdx; j++) {
          const slot = this.slotSymbols[i * REELS_AMOUNT + j]!;
          if (!(reachSymbols.includes(slot.slotId) || slot.slotId === SlotId.W)) {
            slot.skip();
          }
        }
      }
    });
    slotStopDelay.start();
  }

  private resetSlotsTint(): void {
    this.slotSymbols.forEach((slot) => {
      slot.tint = 0xffffff;
    });
  }

  // Winning
  private skipWinSlotsAnimation(resetTint = true): void {
    this.animation?.skip();
    this.slotSymbols.forEach((slot) => slot.skip());
    if (resetTint) {
      this.resetAllSlotTint();
    } else {
      this.resetSlotTintExceptRespinSLot();
    }
  }

  private onStartWinAnimation(nextResult: ISettledBet, _isTurboSpin: boolean): void {
    this.showWin(nextResult);
  }

  private animation?: AnimationChain | undefined;

  private getWinningWaysSymbolArrayFromPositions(positions: Set<number>): number[][] {
    const symbolMap: Map<SlotId, Set<number>> = new Map();

    for (const position of positions) {
      const symbol = this.slotSymbols[position]?.slotId;
      if (symbol !== undefined) {
        const existingPositions = symbolMap.get(symbol) || new Set();
        existingPositions.add(position);
        symbolMap.set(symbol, existingPositions);
      }
    }

    const symbolArray: number[][] = [];
    for (const [key, value] of symbolMap) {
      if (key === 'W') continue;
      const wildPositions = symbolMap.get('W' as SlotId) || new Set();
      for (const pos of wildPositions) {
        value.add(pos);
      }
      if (value.size > 0) {
        symbolArray.push(Array.from(value));
      }
    }
    return symbolArray;
  }

  private showWin(nextResult: ISettledBet): void {
    const { paylines } = getNonNullableValue(nextResult!);
    this.animation = new AnimationChain();
    this.animation.addOnSkip(() => {
      eventManager.emit(EventTypes.SHOW_TINT, false);
    });

    const set = new Set<number>();
    paylines.forEach((payline) => {
      payline.winPositions.forEach((position) => {
        set.add(position);
      });
    });

    const allSlotsHighlight = this.highlightSlots(Array.from(set));
    const allSlotsHighlight2 = this.highlightSlots(Array.from(set));

    allSlotsHighlight.addOnStart(() => {
      this.setWinSlotTint(Array.from(set));
    });
    allSlotsHighlight2.addOnComplete(() => {
      //this.resetAllSlotTint();
    });

    this.animation.appendAnimation(allSlotsHighlight);
    this.animation.appendAnimation(allSlotsHighlight2);

    const symbolArray = this.getWinningWaysSymbolArrayFromPositions(set);
    const eachSlotsHighlight = this.createHighlightChainAnimation(symbolArray, true);
    this.animation.appendAnimation(eachSlotsHighlight);
    this.animation?.start();
  }

  private highlightSlots(slotPositions: number[]): Animation {
    const animationGroup = new AnimationGroup({});
    slotPositions.forEach((slotPos) => {
      animationGroup.addAnimation(this.slotSymbols[slotPos]!.getWinAnimation());
    });
    return animationGroup;
  }

  private createHighlightChainAnimation(symbolArray: number[][], isLoop: boolean): Animation {
    const animationChain = new AnimationChain({ isLoop });
    symbolArray.forEach((symbols) => {
      const chain = this.highlightSlots(symbols);

      chain.addOnStart(() => {
        this.setWinSlotTint(symbols);
      });

      chain.addOnComplete(() => {
        //this.resetAllSlotTint();
      });
      animationChain.appendAnimation(chain);
    });
    animationChain.appendAnimation(Tween.createDelayAnimation(WIN_ANIMATION_INTERVAL));
    return animationChain;
  }

  private resetAllSlotTint(): void {
    if (isFreeSpinsMode(setGameMode())) return;
    this.slotSymbols.forEach((slot) => {
      slot.tint = 0xffffff;
    });
  }

  private resetSlotTintExceptRespinSLot(): void {
    if (isFreeSpinsMode(setGameMode())) return;
    this.slotSymbols.forEach((slot) => {
      if (!(isRespinSlotId(slot.slotId) || slot.slotId === SlotId.W)) slot.tint = 0xffffff;
    });
  }

  private setWinSlotTint(slotPositions: number[]): void {
    this.slotSymbols.forEach((slot) => {
      slot.tint = WIN_SLOT_TINT_COLOR;
    });
    slotPositions.forEach((slot) => {
      this.slotSymbols[slot]!.tint = 0xffffff;
    });
  }

  private onStartFSWinAnimation(): void {
    const buyFeatureWinSymbols = [SlotId.A, SlotId.B, SlotId.C, SlotId.D, SlotId.E];
    this.slotSymbols.forEach((slot) => {
      if (
        setAnticipationSymbolType() === undefined ||
        slot.slotId === setAnticipationSymbolType() ||
        slot.slotId === SlotId.W ||
        (isBuyFeatureMode() && Object.values(buyFeatureWinSymbols).includes(slot.slotId))
      ) {
        slot.zIndex = 3;
        slot.startLongspinAnimation();
      } else {
        slot.tint = ANTICIPATION_SLOTS_TINT;
        slot.skip();
      }
    });
  }

  private startSpinByReel(reelIndex: number): void {
    if (setIsRevokeThrowingError()) return;
    for (let i = 0; i < SLOTS_PER_REEL_AMOUNT; i++) {
      this.slotSymbols[REELS_AMOUNT * i + reelIndex]?.startSpinStartAnimation();
    }
  }

  private startSpinByReelonComplete(reelIndex: number): void {
    for (let i = 0; i < SLOTS_PER_REEL_AMOUNT; i++) {
      const slot = this.slotSymbols[i * REELS_AMOUNT + reelIndex]!;
      slot.visible = false;
    }
  }

  private replaceSlotSymbols(reelIndex: number, slotIndex: number, slotid: RespinSymbolType): void {
    this.removeChild(this.slotSymbols[slotIndex * REELS_AMOUNT + reelIndex]!);
    const symbol = animateSlotFactory(slotid as SlotId);
    symbol.x = REEL_WIDTH * reelIndex + SLOT_WIDTH / 2;
    symbol.y = SLOT_HEIGHT * slotIndex + SLOT_RESOURCE_HEIGHT / 2;
    symbol.visible = true;

    this.addChild(symbol);
    this.slotSymbols[slotIndex * REELS_AMOUNT + reelIndex] = symbol;
  }
}
