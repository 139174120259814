import * as PIXI from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';

import { GameMode } from '../../consts';
import { setGameMode, setRespinCnt } from '../../gql/cache';
import { getRespinMultipleCount, isFreeSpinsMode } from '../../utils';
import Tween from '../animations/tween';
import {
  AnnounceType,
  AnnounceTypeToLanternAnimName,
  AnnounceTypeToLanternSoundName,
  RespinActionInfo,
} from '../announce/config';
import ViewContainer from '../components/container';
import { TickerSpine } from '../components/spine';
import {
  EventTypes,
  RESPIN_MULTIPLIER_MAX_COUNT,
  SLOTS_CONTAINER_HEIGHT,
  SLOTS_CONTAINER_WIDTH,
  eventManager,
} from '../config';

class Lantern extends ViewContainer {
  private blackBoard: PIXI.Sprite;

  private spine: TickerSpine<'multiplier'>;

  constructor() {
    super();

    this.blackBoard = new PIXI.Sprite(PIXI.Texture.WHITE);
    this.blackBoard.anchor.set(0.5);
    this.blackBoard.tint = 0x000000;
    this.blackBoard.alpha = 0.6;
    this.blackBoard.width = SLOTS_CONTAINER_WIDTH + 20;
    this.blackBoard.height = SLOTS_CONTAINER_HEIGHT + 10;
    this.blackBoard.position.y = SLOTS_CONTAINER_HEIGHT / 2 + 70;
    this.blackBoard.visible = false;
    this.addChild(this.blackBoard);

    this.spine = new TickerSpine('multiplier');
    this.addChild(this.spine);

    this.spine.state.setAnimation(0, 'base', true);
    eventManager.on(EventTypes.CHANGE_MODE, this.onModeChange.bind(this));
    //    eventManager.on(EventTypes.MANUAL_CHANGE_BACKGROUND, this.onModeChange.bind(this));

    eventManager.on(EventTypes.START_SPIN_ANIMATION, this.onStartSpin.bind(this));
    eventManager.on(EventTypes.START_ANNOUNCEMENT, this.onStartAnnounce.bind(this));
    eventManager.on(EventTypes.SET_RESPIN_VIEW, this.setRespinLantern.bind(this));

    this.position.set(9 + 1316 / 2, -147 + 154 / 2);
  }

  private onModeChange(settings: { mode: GameMode }) {
    if (isFreeSpinsMode(settings.mode)) {
      this.blackBoard.visible = false;
    } else {
      this.resetAnimation();
    }
  }

  private resetAnimation() {
    this.spine.state.setAnimation(0, 'base', true);
    this.blackBoard.visible = false;
  }

  private onStartSpin() {
    this.blackBoard.visible = false;

    if (isFreeSpinsMode(setGameMode())) {
      this.startRespinAnimation();
    }
  }

  private onStartAnnounce(type: AnnounceType) {
    if (type === 'None' || type === 'phoenix') {
      return;
    }
    this.spine.state.setAnimation(0, AnnounceTypeToLanternAnimName[type], false);
    this.spine.state.addAnimation(0, 'base', true);

    const animDuration = this.spine.spineData.findAnimation(AnnounceTypeToLanternAnimName[type]).duration;
    const animWait = Tween.createDelayAnimation(animDuration * 1000);
    animWait.addOnComplete(() => {
      this.blackBoard.visible = false;
    });
    this.blackBoard.visible = true;
    animWait.start();

    AudioApi.play({ type: AnnounceTypeToLanternSoundName[type] });
  }

  private startRespinAnimation() {
    const respinCount = getRespinMultipleCount();
    this.spine.state.setAnimation(0, RespinActionInfo['in'][respinCount]!, false);
    this.spine.state.addAnimation(0, RespinActionInfo['loop'][respinCount]!, true);

    //AudioApi.play({ type: RespinActionInfo['sound'][respinCount]! });
  }

  private setRespinLantern() {
    if (setRespinCnt() === 0) {
      this.spine.state.setAnimation(0, 'base', true);
    } else {
      const respinCount =
        RESPIN_MULTIPLIER_MAX_COUNT >= setRespinCnt() ? getRespinMultipleCount() - 1 : getRespinMultipleCount();
      this.spine.state.setAnimation(0, RespinActionInfo['loop'][respinCount]!, true);
    }
  }
}

export default Lantern;
