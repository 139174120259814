import { SLOTMACHINE_CONTAINER_LANDSCAPE_BASE_Y } from '../config';

export const BANNER_POSITION_X = 0;
export const BANNER_POSITION_Y = -50;

// FS BANNER
export const MESSAGE_FS_BANNER_TITLE_Y = -150;
export const MESSAGE_FS_BANNER_YOU_WON_Y = -90;
export const MESSAGE_FS_BANNER_FEAT_NAME_Y = 0;
export const MESSAGE_FS_BANNER_DESCRIPTION_Y = 90;
export const MESSAGE_FS_BANNER_PRESS_Y = 214;

// WIN BANNER
export const MESSAGE_WIN_BANNER_TITLE_Y = 181 - 788 / 2;
export const MESSAGE_WIN_BANNER_WIN_AMOUNT_Y = 216 + 287 / 2 - 788 / 2;
export const MESSAGE_WIN_BANNER_RESPIN_Y = 520 + 116 / 2 - 788 / 2 + 42;

// SOLDOUT BANNER
export const MESSAGE_SOLD_OUT_BANNER_WON_Y = 619 + 105 / 2 - 1010 / 2;
export const SOLDOUT_SPINE_Y = 398 + 1010 / 2 - SLOTMACHINE_CONTAINER_LANDSCAPE_BASE_Y;
