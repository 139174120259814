import * as PIXI from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';
import { formatNumber } from '@phoenix7dev/utils-fe';

import { ISongs, durationOf } from '../../config';
import { setBetAmount, setCurrency } from '../../gql/cache';
import { normalizeCoins, showCurrency } from '../../utils';
import Animation from '../animations/animation';
import AnimationChain from '../animations/animationChain';
import AnimationGroup from '../animations/animationGroup';
import { TweenProperties } from '../animations/d';
import Tween from '../animations/tween';
import BgmControl from '../bgmControl/bgmControl';
import { LayeredBitmapText } from '../components/layeredBitmapText';
import {
  BIG_WIN_END_DURATION,
  EventTypes,
  MAXIMUM_FRACTION_DIGITS,
  MINIMUM_FRACTION_DIGITS,
  SOLDOUT_WINCOUNTUP_LANDSCAPE_TEXT_POS_Y,
  SOLDOUT_WINCOUNTUP_PORTRAIT_TEXT_POS_Y,
  SOLDOUT_WIN_CHECK_TIME_DURATION,
  SOLDOUT_WIN_TITLE_BEGIN_SCALE,
  SOLDOUT_WIN_TITLE_TARGET_SCALE,
  WINCOUNTUP_TEXT_POS_X,
  countUpSoldOutTextStyle,
  eventManager,
} from '../config';

class SoldOutCountUpMessage extends PIXI.Container {
  public winValue = 0.0;

  public winCountUpAnimation: Animation | null = null;

  private winValueText = new LayeredBitmapText(
    this.winValue.toLocaleString('en-EN', {
      minimumFractionDigits: MINIMUM_FRACTION_DIGITS,
      maximumFractionDigits: MAXIMUM_FRACTION_DIGITS,
    }),
    countUpSoldOutTextStyle,
  );

  constructor() {
    super();
    this.winValueText.x = WINCOUNTUP_TEXT_POS_X + 40;
    this.winValueText.y = SOLDOUT_WINCOUNTUP_LANDSCAPE_TEXT_POS_Y;
    this.winValueText.visible = false;
    this.addChild(this.winValueText);
    eventManager.addListener(EventTypes.SKIP_WIN_COUNT_UP_ANIMATION, this.skipWinCountUpAnimation.bind(this));
    eventManager.addListener(EventTypes.START_SOLDOUT_WINCOUNT_ANIMATION, this.startWinAnimation.bind(this));
    eventManager.addListener(EventTypes.START_SPIN_ANIMATION, this.onStartSpinAnimation.bind(this));
    eventManager.addListener(EventTypes.HIDE_WIN_COUNT_UP_MESSAGE, this.hideWinCountUpMessage.bind(this));

    eventManager.on(EventTypes.RESIZE, this.applicationResize.bind(this));
  }

  private hideWinCountUpMessage(): void {
    this.winValueText.visible = false;
  }

  private onStartSpinAnimation(): void {
    this.winCountUpAnimation?.skip();
  }

  private startWinAnimation(winAmount: number, callback: () => void): void {
    this.startWinAnimationbyNumber(winAmount, callback);
  }

  private startWinAnimationbyNumber(winAmount: number, callback: () => void): void {
    this.createWinAnimation(winAmount, callback).start();
  }

  private skipWinCountUpAnimation() {
    //this.winCountUpAnimation?.skip();
  }

  public createWinAnimation(winCoinAmount: number, onCountUpEnd?: () => void): Animation {
    const betAmount = normalizeCoins(setBetAmount());
    const winAmount = normalizeCoins(winCoinAmount);

    const animationChain = new AnimationChain({
      proceedNextAnimationOnSkip: true,
    });
    AudioApi.play({ type: ISongs.XT003S_cutin_soldout, stopPrev: true });

    animationChain.addOnStart(() => {
      eventManager.emit(EventTypes.SHOW_COINS);
    });
    animationChain.addOnComplete(() => {
      eventManager.emit(EventTypes.HIDE_COINS);
    });
    const soldOutWinAnimation = this.createSoldOutWinAnimation(winAmount, betAmount);
    soldOutWinAnimation.addOnStart(() => {
      BgmControl.stopAll();
      AudioApi.play({ type: ISongs.XT003S_win_loop, stopPrev: true });
    });

    animationChain.appendAnimation(soldOutWinAnimation);
    const bigWinEndDelay = Tween.createDelayAnimation(SOLDOUT_WIN_CHECK_TIME_DURATION);
    bigWinEndDelay.addOnStart(() => {
      AudioApi.stop({ type: ISongs.XT003S_win_loop });
      AudioApi.play({ type: ISongs.XT003S_win_end, stopPrev: true });
    });
    bigWinEndDelay.addOnComplete(() => {});
    animationChain.appendAnimation(bigWinEndDelay);
    const fadeOutAnimation = new Tween({
      propertyBeginValue: 1,
      target: 0,
      object: this.winValueText,
      // eslint-disable-next-line no-restricted-properties
      easing: (n) => Math.pow(n, 8),
      property: TweenProperties.ALPHA,
      duration: BIG_WIN_END_DURATION,
    });
    fadeOutAnimation.addOnStart(() => {
      onCountUpEnd?.();
    });
    animationChain.appendAnimation(fadeOutAnimation);
    animationChain.addOnStart(() => {
      this.winValueText.alpha = 1;
      this.winValueText.visible = true;
    });
    animationChain.addOnComplete(() => {
      this.clean();
    });
    this.winCountUpAnimation = animationChain;
    return animationChain;
  }

  private clean(): void {
    AudioApi.stop({ type: ISongs.XT003S_win_loop });
    //AudioApi.stop({ type: ISongs.XT003S_cutin_soldout });
    this.winValueText.visible = false;
    this.winValueText.scale.set(1, 1);
    this.setWinValue(0);
    this.winCountUpAnimation = null;
  }

  private createSoldOutWinAnimation(win: number, _bet: number): Animation {
    const soldOutWinAnimation = new AnimationChain();
    const countUpAnimationGroup = new AnimationGroup({});
    soldOutWinAnimation.addOnStart(() => {
      AudioApi.play({ type: ISongs.XT003S_win_epic });
    });
    soldOutWinAnimation.addOnComplete(() => {
      AudioApi.stop({ type: ISongs.XT003S_win_epic });
    });
    soldOutWinAnimation.addOnSkip(() => {
      AudioApi.stop({ type: ISongs.XT003S_win_epic });
      //this.setWinValue(win);
    });
    const duration = durationOf(ISongs.XT003S_cutin_soldout) - SOLDOUT_WIN_CHECK_TIME_DURATION;
    //    const duration = (win / bet / (SOLDOUT_WIN_COUNT_UP_MULTIPLIER * APPLICATION_FPS)) * 1000;

    const countUpAnimation = new Tween({
      propertyBeginValue: 0,
      target: win,
      object: this,
      property: TweenProperties.WIN_VALUE,
      update: this.setWinValue.bind(this),
      duration,
    });
    const scaleXAnimation = new Tween({
      object: this.winValueText.scale,
      propertyBeginValue: SOLDOUT_WIN_TITLE_BEGIN_SCALE,
      target: SOLDOUT_WIN_TITLE_TARGET_SCALE,
      property: TweenProperties.X,
      duration,
    });
    const scaleYAnimation = new Tween({
      object: this.winValueText.scale,
      propertyBeginValue: SOLDOUT_WIN_TITLE_BEGIN_SCALE,
      target: SOLDOUT_WIN_TITLE_TARGET_SCALE,
      property: TweenProperties.Y,
      duration,
    });
    countUpAnimationGroup.addAnimation(scaleXAnimation);
    countUpAnimationGroup.addAnimation(scaleYAnimation);
    countUpAnimationGroup.addAnimation(countUpAnimation);
    soldOutWinAnimation.appendAnimation(countUpAnimationGroup);

    return soldOutWinAnimation;
  }

  public setWinValue(winValue: number): void {
    this.winValue = winValue < 0 ? 0 : winValue;
    this.winValueText.text = `${formatNumber({
      currency: setCurrency(),
      value: winValue,
      showCurrency: showCurrency(setCurrency()),
    })}`;
  }

  private applicationResize = (width: number, height: number): void => {
    if (height > width) {
      this.winValueText.y = SOLDOUT_WINCOUNTUP_PORTRAIT_TEXT_POS_Y;
    } else {
      this.winValueText.y = SOLDOUT_WINCOUNTUP_LANDSCAPE_TEXT_POS_Y;
    }
  };
}

export default SoldOutCountUpMessage;
