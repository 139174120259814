import React from 'react';
import { useTranslation } from 'react-i18next';

import styles from './info.module.scss';

const AutoPlay: React.FC = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'infoAutoPlay' });
  const descriptions: string[] = t('Description', { returnObjects: true });

  return (
    <section>
      <h1 className={styles.title}>{t('Title')}</h1>
      {descriptions.map((desc, index) => (
        <p
          className={`${styles.p}`}
          key={`autoplay-${index}`}
          dangerouslySetInnerHTML={{
            __html: desc,
          }}
        />
      ))}
    </section>
  );
};

export default AutoPlay;
