import * as PIXI from 'pixi.js';

import { BaseMessageBannerProps } from '../../global.d';
import i18n from '../../i18next';
import AutoResizeText from '../components/autoResizeText';
import { TickerSpine } from '../components/spine';
import {
  SAFE_AREA_LANDSCAPE_PIVOT_X,
  SAFE_AREA_LANDSCAPE_WIDTH,
  SAFE_AREA_PORTRAIT_PIVOT_X,
  SAFE_AREA_PORTRAIT_WIDTH,
} from '../config';

import { BaseMessageBanner } from './baseMessageBanner';
import { MESSAGE_SOLD_OUT_BANNER_WON_Y, SOLDOUT_SPINE_Y } from './config';
import { soldOutYouWonTextStyle } from './textStyles';

export class MessageSoldOutBanner extends BaseMessageBanner {
  protected override props: BaseMessageBannerProps;

  private youWonText: AutoResizeText;

  private soldOutSpine: TickerSpine<'banner_rs_end_limit'>;

  constructor(props: BaseMessageBannerProps) {
    super(props);
    this.props = props;
    const backdrop = new PIXI.Sprite(PIXI.Texture.WHITE);
    backdrop.anchor.set(0.5);
    backdrop.tint = 0x000000;
    backdrop.alpha = 0.3;
    backdrop.width = 4000;
    backdrop.height = 4000;
    this.addChild(backdrop);

    this.soldOutSpine = new TickerSpine('banner_rs_end_limit');
    this.soldOutSpine.update(0);
    this.soldOutSpine.state.setAnimation(0, 'base', false);
    this.soldOutSpine.visible = false;
    this.addChild(this.soldOutSpine);

    this.youWonText = this.initYouWon();
    this.addChild(this.youWonText);
  }

  public override init(): MessageSoldOutBanner {
    super.init();
    this.soldOutSpine.visible = true;

    return this;
  }

  private initYouWon(): AutoResizeText {
    const text = new AutoResizeText(i18n.t('freeSpinsTotalWin.SoldOut'), soldOutYouWonTextStyle);
    text.anchor.set(0.5);
    text.y = MESSAGE_SOLD_OUT_BANNER_WON_Y;
    return text;
  }

  public override handleResize(width: number, height: number): void {
    super.handleResize(width, height);
    if (height > width) {
      this.x = SAFE_AREA_PORTRAIT_WIDTH / 2 + SAFE_AREA_PORTRAIT_PIVOT_X;
      this.y = SOLDOUT_SPINE_Y;
    } else {
      this.x = SAFE_AREA_LANDSCAPE_WIDTH / 2 + SAFE_AREA_LANDSCAPE_PIVOT_X;
      this.y = SOLDOUT_SPINE_Y;
    }
  }
}
