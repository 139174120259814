import { getFragmentData } from '../../gql';
import {
  setBonuses,
  setBrokenBuyFeatureGame,
  setBrokenGame,
  setCurrentBonus,
  setCurrentFreeSpinsTotalWin,
  setIsBuyFeaturePopupOpened,
  setSlotConfig,
} from '../../gql/cache';
import client from '../../gql/client';
import { userBonusFragment } from '../../gql/fragment';
import { getBonuses, getUserBonuses } from '../../gql/query';
import { BonusKind } from '../../slotMachine/config/bonusInfo';
import { getBonusKind, getNonNullableValue } from '../../utils';

export const useBonuses = () => {
  const getPurchasableBonuses = async () => {
    const bonusData = await client.query({
      query: getBonuses,
      variables: { input: { purchasable: true, slotId: setSlotConfig().id } },
      fetchPolicy: 'network-only',
    });
    setBonuses(bonusData.data.bonuses);
  };

  const checkBonusGame = async () => {
    const activeUserBonusData = await client.query({
      query: getUserBonuses,
      variables: {
        input: { status: 'ACTIVE', slotId: setSlotConfig().id },
      },
      fetchPolicy: 'network-only',
    });

    const userBonuses = getFragmentData(userBonusFragment, getNonNullableValue(activeUserBonusData.data.userBonuses));

    const buyFeatureBonus = userBonuses.find((ub) => getBonusKind(ub!.bonusId) === BonusKind.BUY_FEATURE);
    if (buyFeatureBonus) {
      setBrokenBuyFeatureGame(buyFeatureBonus.id);
      setIsBuyFeaturePopupOpened(true);
      setCurrentBonus({
        ...buyFeatureBonus,
        isActive: true,
      });
      setCurrentFreeSpinsTotalWin(0);
      return;
    }

    const freeSpinsBonus = userBonuses?.find((ub) => ub.bonus?.type === 'SPECIAL_ROUND');
    if (freeSpinsBonus) {
      setBrokenGame(true);
      setCurrentBonus({
        ...freeSpinsBonus,
        isActive: true,
      });
      setCurrentFreeSpinsTotalWin(0);
    }
  };
  return {
    getPurchasableBonuses,
    checkBonusGame,
  };
};
