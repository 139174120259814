import { useMutation } from '@apollo/client';

import { IAuthInput } from '../../global.d';
import { setIsAuthorized, setSlotConfig } from '../../gql/cache';
import { authGql } from '../../gql/mutation';
import { parseQuery } from '../../utils';

export const useAuth = () => {
  const [getAuth] = useMutation(authGql, {
    onCompleted({ auth }) {
      const { slotId } = parseQuery<IAuthInput>();
      setSlotConfig({
        ...setSlotConfig(),
        id: slotId,
        sessionId: auth!.sessionId,
      });
      setIsAuthorized(!!auth);
    },
  });
  return { getAuth };
};
