export const spineFile = [
  'banner_rs_end',
  'banner_rs_end_limit',
  'bg_basegame',
  'bg_respin',
  'buyfeature',
  'cutin',
  'cutin_sp',
  'introscreen',
  'longspin_waku',
  'message',
  'message_frame',
  'multiplier',
  'openning',
  'reelframe',
  'respin_title',
  'respin_waku',
  'symbol_a',
  'symbol_b',
  'symbol_c',
  'symbol_d',
  'symbol_e',
  'symbol_f',
  'symbol_g',
  'symbol_h',
  'symbol_i',
  'symbol_w',
  'symbol_x',
  'title_logo',
] as const;

export type SpineFile = (typeof spineFile)[number];

export interface SpineInterface {
  banner_rs_end: {
    skins: 'default' | 'symbol_a' | 'symbol_b' | 'symbol_c' | 'symbol_d' | 'symbol_e';
    animations: 'base';
  };
  banner_rs_end_limit: { skins: 'default'; animations: 'base' };
  bg_basegame: { skins: 'default'; animations: 'base' };
  bg_respin: { skins: 'default'; animations: '01' | '02' | '03' };
  buyfeature: {
    skins:
      | 'default'
      | 'conf_mystery'
      | 'conf_symbol_a'
      | 'conf_symbol_b'
      | 'conf_symbol_c'
      | 'conf_symbol_d'
      | 'conf_symbol_e';
    animations: 'conf' | 'select_a' | 'select_b' | 'select_c' | 'select_d' | 'select_e' | 'select_m';
  };
  cutin: { skins: 'default' | 'a' | 'b' | 'c'; animations: 'base' | 'furimuki' | 'in' };
  cutin_sp: { skins: 'default' | 'symbol_a' | 'symbol_b' | 'symbol_c' | 'symbol_d' | 'symbol_e'; animations: 'base' };
  introscreen: { skins: 'default'; animations: 'a' | 'b' | 'c' | 'frame' };
  longspin_waku: { skins: 'default'; animations: 'base' | 'in' };
  message: { skins: 'default' | 'bigwin' | 'epicwin' | 'greatwin' | 'megawin'; animations: 'base' };
  message_frame: { skins: 'default'; animations: 'base' };
  multiplier: {
    skins: 'default';
    animations:
      | 'base'
      | 'okwr_in_x1'
      | 'okwr_in_x2'
      | 'okwr_in_x3'
      | 'okwr_in_x4'
      | 'okwr_in_x5'
      | 'okwr_in_x10'
      | 'okwr_in_x20'
      | 'okwr_in_x30'
      | 'okwr_in_x40'
      | 'okwr_in_x50'
      | 'okwr_in_x100'
      | 'okwr_loop_x1'
      | 'okwr_loop_x2'
      | 'okwr_loop_x3'
      | 'okwr_loop_x4'
      | 'okwr_loop_x5'
      | 'okwr_loop_x10'
      | 'okwr_loop_x20'
      | 'okwr_loop_x30'
      | 'okwr_loop_x40'
      | 'okwr_loop_x50'
      | 'okwr_loop_x100'
      | 'yokoku_pt01'
      | 'yokoku_pt02'
      | 'yokoku_pt03';
  };
  openning: { skins: 'default'; animations: 'base' | 'start' };
  reelframe: { skins: 'default'; animations: 'base' | 'waku' };
  respin_title: { skins: 'default'; animations: 'in' | 'loop' };
  respin_waku: { skins: 'a' | 'b' | 'c'; animations: 'base' };
  symbol_a: { skins: 'default'; animations: 'longspin' | 'longspin_stop' | 'spin' | 'spin_after' | 'stop' | 'win' };
  symbol_b: { skins: 'default'; animations: 'longspin' | 'longspin_stop' | 'spin' | 'spin_after' | 'stop' | 'win' };
  symbol_c: { skins: 'default'; animations: 'longspin' | 'longspin_stop' | 'spin' | 'spin_after' | 'stop' | 'win' };
  symbol_d: { skins: 'default'; animations: 'longspin' | 'longspin_stop' | 'spin' | 'spin_after' | 'stop' | 'win' };
  symbol_e: { skins: 'default'; animations: 'longspin' | 'longspin_stop' | 'spin' | 'spin_after' | 'stop' | 'win' };
  symbol_f: { skins: 'default'; animations: 'longspin' | 'longspin_stop' | 'spin' | 'spin_after' | 'stop' | 'win' };
  symbol_g: { skins: 'default'; animations: 'longspin' | 'longspin_stop' | 'spin' | 'spin_after' | 'stop' | 'win' };
  symbol_h: { skins: 'default'; animations: 'longspin' | 'longspin_stop' | 'spin' | 'spin_after' | 'stop' | 'win' };
  symbol_i: { skins: 'default'; animations: 'longspin' | 'longspin_stop' | 'spin' | 'spin_after' | 'stop' | 'win' };
  symbol_w: { skins: 'default'; animations: 'longspin' | 'longspin_stop' | 'spin' | 'spin_after' | 'stop' | 'win' };
  symbol_x: { skins: 'default'; animations: 'longspin' | 'longspin_stop' | 'spin' | 'spin_after' | 'stop' | 'win' };
  title_logo: { skins: 'default'; animations: 'base' | 'intro' };
}

export const ENABLE_MERGED_ATRAS = true;

export const preloadSpineTextures = ['alpha-0.png', 'bw-0.png', 'no_alpha-0.jpg', 'no_alpha-1.jpg'];
