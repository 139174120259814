import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import resourcesToBackend from 'i18next-resources-to-backend';
import { initReactI18next } from 'react-i18next';

import { fallbackFn, fallbackLng } from '@phoenix7dev/utils-fe';

import { isDevelopment } from '../utils';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .use(
    resourcesToBackend((language: string) => {
      return import(
        /* webpackChunkName: "i18n-[request]" */
        /* webpackMode: "lazy" */
        `./resources.${language.toLowerCase().replace('_', '-')}.json`
      );
    }),
  )
  .init({
    debug: isDevelopment(),
    fallbackLng: fallbackFn(fallbackLng),
  });

export default i18n;
