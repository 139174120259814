import * as PIXI from 'pixi.js';

import { Colors, Variables } from '../../config';
import { AutoResizeTextStyle } from '../components/autoResizeText';

const font = Variables.FONT_FAMILY;

// Button Text Style
export const buyFeatureBtnTextStyle: Partial<AutoResizeTextStyle> = {
  fontFamily: Variables.FONT_FAMILY,
  fontWeight: 'bold',
  align: 'center',
  fontSize: 58,
  lineHeight: 58,
  maxWidth: 188,
  maxHeight: 84,
  fill: Colors.GAME_COLOR,
  letterSpacing: 0,
  lineJoin: 'round',
};

export const buyFeatureTitleStyle: Partial<AutoResizeTextStyle> = {
  fontSize: 62,
  fontFamily: font,
  maxWidth: 985,
  fill: '#3F3F53',
  fontWeight: '700',
  stroke: '#ffffff',
  strokeThickness: 8,
  lineJoin: 'round',
};

export const buyFeatureDescriptionTextStyle: Partial<AutoResizeTextStyle> = {
  fontSize: 47,
  fontFamily: font,
  maxWidth: 938,
  fill: '#3F3F53',
  fontWeight: '700',
  stroke: '#ffffff',
  strokeThickness: 6,
  lineJoin: 'round',
};

export const buyFeatureBetDescriptionTextStyle: Partial<AutoResizeTextStyle> = {
  ...buyFeatureDescriptionTextStyle,
  fontSize: 45,
};

export const buyFeatureConfirmTitleStyle: Partial<AutoResizeTextStyle> = {
  fontSize: 56,
  fontFamily: font,
  fill: '#3F3F53',
  fontWeight: '700',
  align: 'center',
  stroke: '#ffffff',
  strokeThickness: 5,
  maxWidth: 1057,
  lineJoin: 'round',
};

export const buyFeatureConfirmTextStyle: Partial<AutoResizeTextStyle> = {
  fontSize: 72,
  fontFamily: font,
  fill: ['#D01E0D ', '#750F00 '],
  fillGradientStops: [0, 1],
  letterSpacing: 0,
  fontWeight: '700',
  align: 'center',
  stroke: '#ffffff',
  strokeThickness: 5,
  maxWidth: 963,
  lineJoin: 'round',
};

export const totalCostTextStyle: Partial<AutoResizeTextStyle> = {
  fontSize: 49,
  fontFamily: font,
  letterSpacing: 0,
  align: 'left',
  maxWidth: 494,
  fill: '#3F3F53',
  fontWeight: '700',
  stroke: '#ffffff',
  strokeThickness: 4,
  lineJoin: 'round',
};

export const totalCostTextAmountStyle: Partial<AutoResizeTextStyle> = {
  fontSize: 130,
  fontFamily: font,
  fill: '#341B17',
  letterSpacing: 0,
  fontWeight: '700',
  align: 'right',
  stroke: '#ffffff',
  strokeThickness: 8,
  maxWidth: 472,
  lineJoin: 'round',
};

export const betValueStyle: Partial<AutoResizeTextStyle> = {
  fontSize: 90,
  fontFamily: font,
  fill: '#FFFFFF',
  letterSpacing: 0,
  fontWeight: '700',
  align: 'center',
  maxWidth: 403,
  lineJoin: 'round',
};

export const buyFeatureSymbolCostTextStyle: Partial<AutoResizeTextStyle> = {
  fontFamily: Variables.FONT_FAMILY,
  fontWeight: '700',
  align: 'right',
  fontSize: 89,
  lineHeight: 89,
  fill: ['#FB6800', '#6F0900'],
  fillGradientStops: [0, 1],
  fillGradientType: PIXI.TEXT_GRADIENT.LINEAR_VERTICAL,
  stroke: '#FFFFFF',
  strokeThickness: 6,
  maxWidth: 271,
  maxHeight: 89,
  lineJoin: 'round',
};

export const buyFeatureSymbolNameTextStyle: Partial<AutoResizeTextStyle> = {
  fontFamily: Variables.FONT_FAMILY,
  fontWeight: '700',
  align: 'right',
  fontSize: 52,
  lineHeight: 52,
  fill: ['#C2352C', '#5C3D67'],
  fillGradientStops: [0, 1],
  fillGradientType: PIXI.TEXT_GRADIENT.LINEAR_VERTICAL,
  stroke: '#FFFFFF',
  strokeThickness: 4,
  maxWidth: 274,
  maxHeight: 56,
  lineJoin: 'round',
};
