import * as PIXI from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';

import { Colors, ISongs } from '../../config';
import { PopupOpeningTypes } from '../../consts';
import {
  setBrokenGame,
  setIsAutoSpins,
  setIsOpenAutoplayPopup,
  setIsOpenBetSettingsPopup,
  setIsOpenInfoPopup,
  setIsPopupOpeningInProgress,
} from '../../gql/cache';
import i18n from '../../i18next';
import { calcBottomContainerHeight, calcPercentage, isFreeSpinsMode } from '../../utils';
import AutoResizeText from '../components/autoResizeText';
import { EventTypes, SLOTS_CONTAINER_HEIGHT, eventManager } from '../config';

import {
  FEATURE_BTN_LANDSCAPE_X,
  FEATURE_BTN_MOUSE_CLICK_SCALE,
  FEATURE_BTN_MOUSE_OVER_SCALE,
  FEATURE_BTN_TEXT_PRESS_X_OFFSET,
  FEATURE_BTN_TEXT_PRESS_Y_OFFSET,
  FEATURE_BTN_TEXT_X_OFFSET,
  FEATURE_BTN_TEXT_Y_OFFSET,
} from './config';
import { buyFeatureBtnTextStyle } from './textStyles';

class BuyFeatureBtn extends PIXI.Container {
  private btn: PIXI.Sprite;

  private text: PIXI.Text;

  private isDisabled: boolean;

  private intent: string;

  private scaleContainer: PIXI.Container;

  constructor() {
    super();
    this.intent = 'buy_button';
    this.isDisabled = false;
    this.visible = !setBrokenGame();

    this.btn = this.initBuyFeatureBtn();
    this.text = this.initBuyFeatureText();

    this.scaleContainer = new PIXI.Container();
    this.scaleContainer.addChild(this.btn, this.text);

    this.addChild(this.scaleContainer);

    eventManager.on(EventTypes.DISABLE_BUY_FEATURE_BTN, (disable: boolean) => {
      this.handleDisable(disable);
    });
    eventManager.on(EventTypes.CHANGE_MODE, ({ mode }) => {
      this.visible = !isFreeSpinsMode(mode);
    });

    eventManager.on(EventTypes.RESIZE_GAME_CONTAINER, this.gameContainerResize.bind(this));
  }

  private initBuyFeatureBtn() {
    const btn = new PIXI.Sprite(PIXI.Texture.from(`${this.intent}`));
    btn.anchor.set(0.5);
    btn.interactive = true;
    btn.buttonMode = true;
    btn.tint = Colors.GAME_COLOR;
    btn.on('click', (ev: PIXI.InteractionEvent) => {
      if (ev.data.isPrimary) this.onClick();
    });
    btn.on('touchstart', (ev: PIXI.InteractionEvent) => {
      if (ev.data.isPrimary) this.onClick();
    });

    btn.addListener('mouseover', () => {
      if (!this.isDisabled) {
        AudioApi.play({ type: ISongs.SFX_UI_Hover });
        this.text.position.set(FEATURE_BTN_TEXT_X_OFFSET, FEATURE_BTN_TEXT_Y_OFFSET);
        this.scaleContainer.scale.set(FEATURE_BTN_MOUSE_OVER_SCALE);
      }
    });
    btn.addListener('mouseout', () => {
      if (!this.isDisabled) {
        this.text.position.set(FEATURE_BTN_TEXT_X_OFFSET, FEATURE_BTN_TEXT_Y_OFFSET);
        this.scaleContainer.scale.set(1.0);
      }
    });
    btn.addListener('mousedown', () => {
      if (!this.isDisabled) {
        this.text.position.set(FEATURE_BTN_TEXT_PRESS_X_OFFSET, FEATURE_BTN_TEXT_PRESS_Y_OFFSET);
        this.scaleContainer.scale.set(FEATURE_BTN_MOUSE_CLICK_SCALE);
      }
    });
    btn.addListener('mouseup', () => {
      if (!this.isDisabled) {
        this.text.position.set(FEATURE_BTN_TEXT_X_OFFSET, FEATURE_BTN_TEXT_Y_OFFSET);
        this.scaleContainer.scale.set(1.0);
      }
    });
    return btn;
  }

  private initBuyFeatureText(): AutoResizeText {
    const text = new AutoResizeText(i18n.t('buyFeature.button'), buyFeatureBtnTextStyle);
    text.anchor.set(0.5);
    text.position.set(FEATURE_BTN_TEXT_X_OFFSET, FEATURE_BTN_TEXT_Y_OFFSET);
    return text;
  }

  private onClick(): void {
    if (!this.isDisabled && setIsPopupOpeningInProgress() === PopupOpeningTypes.NONE && !setIsAutoSpins()) {
      this.scaleContainer.scale.set(0.9);
      setIsPopupOpeningInProgress(PopupOpeningTypes.BUY_FEATURE_POPUP);
      setIsOpenAutoplayPopup(false);
      setIsOpenBetSettingsPopup(false);
      setIsOpenInfoPopup(false);
      eventManager.emit(EventTypes.DISABLE_BUY_FEATURE_BTN, true);
      eventManager.emit(EventTypes.OPEN_BUY_FEATURE_POPUP);
      eventManager.emit(EventTypes.OPEN_BUY_FEATURE_POPUP_BG);
      AudioApi.play({ type: ISongs.XT003S_push_buy_feature });
      setTimeout(() => setIsPopupOpeningInProgress(PopupOpeningTypes.NONE), 100);
    }
  }

  private setObjAlpha(alpha: number) {
    this.btn.alpha = alpha;
    this.text.alpha = alpha;
  }

  private handleDisable(disable: boolean): void {
    if (this.isAutoSpinInProgress) return;

    this.isDisabled = disable;
    this.btn.buttonMode = !disable;

    if (disable) {
      this.text.position.set(FEATURE_BTN_TEXT_X_OFFSET, FEATURE_BTN_TEXT_Y_OFFSET);
      this.setObjAlpha(0.5);
      this.scaleContainer.scale.set(1.0);
    } else {
      this.text.position.set(FEATURE_BTN_TEXT_X_OFFSET, FEATURE_BTN_TEXT_Y_OFFSET);
      this.setObjAlpha(1);
    }
  }

  private get isAutoSpinInProgress(): boolean {
    return this.isDisabled && setIsAutoSpins();
  }

  private gameContainerResize(width: number, height: number, scale: number): void {
    const isLandscape = width >= height;

    if (isLandscape) {
      this.pivot.set(0, 0);
      this.x = -this.btn.width / 2 + FEATURE_BTN_LANDSCAPE_X;
      this.y = SLOTS_CONTAINER_HEIGHT / 2;
      this.scale.set(1.0);
    } else {
      this.pivot.set(this.btn.width / 2, this.btn.height / 2);

      height = height - calcBottomContainerHeight(width, height);
      const betBtnSize = calcPercentage(height, 7.5);
      const betBtnGap = calcPercentage(width, 1.25);
      const betBtnTop = height - betBtnSize - betBtnGap;
      const buyBtnWGap = calcPercentage(width, 1.25);
      const buyBtnHGap = calcPercentage(height, 1);
      const buyBtnscale = Math.min(
        calcPercentage(height, 7.5) / scale / this.btn.height,
        calcPercentage(width, 20) / scale / this.btn.width,
      );

      this.scale.set(buyBtnscale);
      this.parent.toLocal({ x: width - buyBtnWGap, y: betBtnTop - buyBtnHGap }, undefined, this.position, false);
    }
  }
}

export default BuyFeatureBtn;
