import TaggedText from 'pixi-tagged-text';
import { Container, IDestroyOptions } from 'pixi.js';

import {
  INTRO_DOT_POSITION_Y,
  INTRO_FRAME_SPINE_HEIGHT,
  INTRO_OUTER_TEXT_POSITION,
  introContents,
} from '../../../config/introContents';
import i18n from '../../../i18next';
import { TweenProperties } from '../../../slotMachine/animations/d';
import Tween from '../../../slotMachine/animations/tween';
import { TickerSpine } from '../../../slotMachine/components/spine';
import { calcPercentage } from '../../../utils';
import AnimationChain from '../../animations/animationChain';
import AnimationGroup from '../../animations/animationGroup';
import AutoResizeText from '../../components/autoResizeText';

import { Dot } from './Dot';
import { CAROUSEL_ANIMATION_DELAY, CAROUSEL_ANIMATION_DURATION, CAROUSEL_DOTS_GAP, CAROUSEL_DOTS_SIZE } from './config';
import { introAutoResizeTextStylesInfo, introOuterAutoResizeTextStylesInfo, introTitleStylesInfo } from './styles';

class Carousel extends Container {
  private slides: typeof introContents;

  private frame: TickerSpine<'introscreen'>;

  private slidesContainer: Container;

  private dots: Container<Dot>;

  private slideAnimation: AnimationChain;

  private outerText: AutoResizeText;

  constructor(slides: typeof introContents) {
    super();
    this.slides = slides;

    this.frame = this.initFrame();
    this.slidesContainer = this.initSlides();
    this.outerText = this.initOuterText();
    this.dots = this.initDots();
    this.addChild(this.frame, this.slidesContainer, this.outerText, this.dots);

    this.slideAnimation = this.createSlideAnimation();
    this.slideAnimation.start();
  }

  private initFrame(): TickerSpine<'introscreen'> {
    const frame = new TickerSpine('introscreen');
    frame.pivot.set(0, -INTRO_FRAME_SPINE_HEIGHT / 2);
    frame.update(0);
    frame.state.setAnimation(0, 'frame', false);
    return frame;
  }

  private initSlides(): Container {
    const slides = new Container();
    this.slides.forEach((slide, index) => {
      const container = new Container();
      const imageSpine = new TickerSpine('introscreen');
      imageSpine.pivot.set(0, -INTRO_FRAME_SPINE_HEIGHT / 2);
      imageSpine.update(0);
      imageSpine.state.setAnimation(0, slide.animation, false);

      const textTop = new TaggedText(i18n.t(slide.txtKeyTop), introTitleStylesInfo);
      textTop.update();
      textTop.draw();
      textTop.pivot.x = textTop.defaultStyle.wordWrapWidth! / 2;
      textTop.position.copyFrom(slide.txtTop);

      container.addChild(imageSpine);
      container.addChild(textTop);

      if ('txtKeyBottom' in slide && slide.txtKeyBottom != '') {
        const textBottom = new AutoResizeText(i18n.t(slide.txtKeyBottom), introAutoResizeTextStylesInfo);
        textBottom.anchor.set(1, 1);
        textBottom.position.copyFrom(slide.txtBottom);
        container.addChild(textBottom);
      }

      container.alpha = index === 0 ? 1 : 0;
      slides.addChild(container);
    });

    return slides;
  }

  private initDots = (): Container<Dot> => {
    const dotsContainer = new Container<Dot>();
    dotsContainer.name = 'slidesContainer';
    for (let i = 0; i < this.slides.length; i++) {
      const dot = new Dot(`${i + 1}`, i === 0);
      dot.name = `${i}`;
      dot.x = i * (CAROUSEL_DOTS_SIZE + CAROUSEL_DOTS_GAP);
      dotsContainer.addChild(dot);
    }
    dotsContainer.x = -dotsContainer.width / 2;
    dotsContainer.y = INTRO_DOT_POSITION_Y;
    return dotsContainer;
  };

  private initOuterText = (): AutoResizeText => {
    const textOuter = new AutoResizeText(i18n.t('introTitle.outer'), introOuterAutoResizeTextStylesInfo);
    textOuter.anchor.set(0, 0);
    textOuter.position.copyFrom(INTRO_OUTER_TEXT_POSITION);
    return textOuter;
  };

  private createSlideAnimation(): AnimationChain {
    const slideAnimation = new AnimationChain({ isLoop: true });

    for (let i = 0; i < this.slides.length; i++) {
      const fadeAnimations = new AnimationGroup();
      const slide = this.slidesContainer.children[i]!;
      fadeAnimations.addAnimation(
        new Tween({
          object: slide,
          property: TweenProperties.ALPHA,
          propertyBeginValue: 1,
          target: 0,
          duration: CAROUSEL_ANIMATION_DURATION,
        }),
      );

      const nextIndex = (i + 1) % this.slides.length;
      const nextSlide = this.slidesContainer.children[nextIndex]!;
      fadeAnimations.addAnimation(
        new Tween({
          object: nextSlide,
          property: TweenProperties.ALPHA,
          propertyBeginValue: 0,
          target: 1,
          duration: CAROUSEL_ANIMATION_DURATION,
        }),
      );

      fadeAnimations.addOnStart(() => {
        this.dots.children[nextIndex]?.setActive(true);
        this.dots.children[i]?.setActive(false);
      });

      slideAnimation.appendAnimation(Tween.createDelayAnimation(CAROUSEL_ANIMATION_DELAY));
      slideAnimation.appendAnimation(fadeAnimations);
    }

    return slideAnimation;
  }

  public setSize(width: number, height: number, bottomGap: number): void {
    if (width >= height) {
      this.scale.set(height / 1080);
      this.y = height - INTRO_FRAME_SPINE_HEIGHT * (height / 1080);
    }
    if (width < height + bottomGap) {
      this.scale.set(width / 1080);
      this.y = height - INTRO_FRAME_SPINE_HEIGHT * (width / 1080) - calcPercentage(height, 22);
    }

    this.x = width / 2;
  }

  public override destroy(options?: boolean | IDestroyOptions): void {
    this.slideAnimation.skip();
    super.destroy(options);
  }
}
export default Carousel;
