import { Application, Container } from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';

import { introContents } from '../../config/introContents';
import { setProgress } from '../../gql/cache';
import { calcPercentage, getCssVariable, nextTick } from '../../utils';
import BgmControl from '../bgmControl/bgmControl';
import { TickerSpine } from '../components/spine';
import { UiButton } from '../components/ui/uiButton';
import { EventTypes, eventManager } from '../config';
import { SoundBtn } from '../controlButtons/soundBtn';

import Carousel from './Carousel/index';

export class IntroScreen {
  private readonly application: Application;

  private static introScreen: IntroScreen;

  public static initIntroScreen = (application: Application): void => {
    IntroScreen.introScreen = new IntroScreen(application);
  };

  public static getInstance = (): IntroScreen => IntroScreen.introScreen;

  private bg: TickerSpine<'openning'>;

  private container = new Container();

  private foregroundContainer = new Container();

  private backgroundContainer = new Container();

  private playBtn: UiButton;

  private soundBtn: SoundBtn;

  private carousel: Carousel;

  private bindedResize = this.resize.bind(this);

  private constructor(application: Application) {
    this.application = application;

    this.bg = new TickerSpine('openning');
    this.bg.update(0);
    this.bg.state.setAnimation(0, 'base', false);
    this.backgroundContainer.addChild(this.bg);

    this.carousel = new Carousel(introContents);
    this.playBtn = this.initPlayBtn();
    this.soundBtn = new SoundBtn();
    this.foregroundContainer.addChild(this.carousel, this.playBtn, this.soundBtn);

    this.container.addChild(this.backgroundContainer, this.foregroundContainer);

    this.application.stage.addChild(this.container);
    eventManager.addListener(EventTypes.RESIZE, this.bindedResize);
  }

  private initPlayBtn(): UiButton {
    const btn = new UiButton('play');

    btn.btn.anchor.set(1, 0.5);
    btn.interactive = true;

    const clickCallback = () => {
      if (AudioApi.restrictionChangedOnIntroScreen) return;
      AudioApi.restrictionChangedOnIntroScreen = true;

      nextTick(() => {
        this.destroy();
        setProgress({ ...setProgress(), wasLoaded: true });
        if (!AudioApi.isInitialized) {
          eventManager.emit(EventTypes.ENABLE_SOUND_LOADER);
        }
        AudioApi.unSuspend();
        AudioApi.processRestriction({
          restricted: false,
          listToPlay: [],
          onChangeRestriction: () => {
            BgmControl.handleChangeRestriction();
          },
        });
        eventManager.emit(EventTypes.HANDLE_DESTROY_INTRO_SCREEN);
      });
    };
    btn.on('click', clickCallback);
    btn.on('touchend', clickCallback);

    return btn;
  }

  private setBgSize = (width: number, height: number): void => {
    this.backgroundContainer.x = width / 2;
    this.backgroundContainer.y = height / 2;

    const size = { width: 2000, height: 2000 };
    const bgAspectRatio = size.width / size.height;
    const aspectRatio = width / height;

    const scale = bgAspectRatio > aspectRatio ? height / size.height : width / size.width;
    this.backgroundContainer.scale.set(scale);
  };

  private resize(width: number, height: number): void {
    this.application.renderer.resize(width, height);
    const isPortraitMode = width < height;
    const gap = calcPercentage(width, 1.25);
    const playBtnGap = calcPercentage(width, 5);
    this.setBgSize(width, height);
    const heightWithBottomGap = isPortraitMode
      ? calcPercentage(height, 100 - parseInt(getCssVariable('--bottom-height-percent-portrait'), 10))
      : calcPercentage(height, 100 - parseInt(getCssVariable('--bottom-height-percent-landscape'), 10));

    if (isPortraitMode) {
      this.soundBtn.btn.anchor.set(1, 0);
      this.soundBtn.x = width - gap;
      this.soundBtn.y = gap;
      this.soundBtn.setSize(calcPercentage(height, 7.5));

      this.playBtn.btn.anchor.set(0.5, 1);
      this.playBtn.x = width / 2;
      this.playBtn.y = heightWithBottomGap + playBtnGap;
      //this.playBtn.y = heightWithBottomGap - playBtnGap * 2;
      this.playBtn.setSize(calcPercentage(width, 40));
    } else {
      this.soundBtn.btn.anchor.set(0, 0);
      this.soundBtn.x = gap;
      this.soundBtn.y = gap;
      this.soundBtn.setSize(calcPercentage(width, 7.5));

      this.playBtn.btn.anchor.set(1, 1);
      this.playBtn.x = width - playBtnGap;
      this.playBtn.y = height - this.playBtn.height / 2;
      this.playBtn.setSize(calcPercentage(width, 17));
    }

    this.carousel.setSize(width, heightWithBottomGap, height - heightWithBottomGap);
  }
  private destroy(): void {
    eventManager.removeListener(EventTypes.RESIZE, this.bindedResize);
    this.container.destroy({ children: true });
  }
}
