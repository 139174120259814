import { useQuery } from '@apollo/client';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { formatNumber } from '@phoenix7dev/utils-fe';

import { getBetsSetting } from '../../gql/fromFragment';
import { getUserGql } from '../../gql/query';

import styles from './info.module.scss';

const GameRules: React.FC = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'infoGameRules' });
  const { data: userData } = useQuery(getUserGql);

  const {
    user: { balance },
  } = userData!;
  const betSettings = getBetsSetting();
  const { currency } = balance!;
  const minBet = formatNumber({ currency, value: betSettings.minBetCount });
  const maxBet = formatNumber({ currency, value: betSettings.maxBetCount });

  const rtpDesc: string[] = t('RTPDesc', {
    returnObjects: true,
  });
  return (
    <section>
      <h1 className={styles.title}>{t('Title')}</h1>

      <div className={`${styles.p}`}>
        {t('Rules', { returnObjects: true }).map((str, idx) => (
          <div key={idx} dangerouslySetInnerHTML={{ __html: str }} />
        ))}
      </div>
      <div className={`${styles.p}`}>
        <div
          dangerouslySetInnerHTML={{
            __html: t('Malfunction1'),
          }}
        />
        <div
          dangerouslySetInnerHTML={{
            __html: t('Malfunction2'),
          }}
        />
        <div
          dangerouslySetInnerHTML={{
            __html: t('Malfunction3'),
          }}
        />
      </div>
      <br />
      <div className={`${styles.p} ${styles.center}`}>
        <div
          dangerouslySetInnerHTML={{
            __html: t('Min', {
              minBet,
            }),
          }}
        />
        <div
          dangerouslySetInnerHTML={{
            __html: t('Max', {
              maxBet,
            }),
          }}
        />
      </div>
      <br></br>
      <div className={`${styles.p} ${styles.center}`}>
        <div
          dangerouslySetInnerHTML={{
            __html: t('RTP', {
              rtp: '96.51%',
            }),
          }}
        />
        {rtpDesc.map((v, i) => (
          <div key={`${i}`} dangerouslySetInnerHTML={{ __html: v }} />
        ))}
        <div dangerouslySetInnerHTML={{ __html: t('RTPFeatureA', { rtp: '96.93%' }) }} />
        <div dangerouslySetInnerHTML={{ __html: t('RTPFeatureB', { rtp: '96.90%' }) }} />
        <div dangerouslySetInnerHTML={{ __html: t('RTPFeatureC', { rtp: '96.89%' }) }} />
        <div dangerouslySetInnerHTML={{ __html: t('RTPFeatureD', { rtp: '96.88%' }) }} />
        <div dangerouslySetInnerHTML={{ __html: t('RTPFeatureE', { rtp: '96.86%' }) }} />
        <div dangerouslySetInnerHTML={{ __html: t('RTPFeatureM', { rtp: '96.91%' }) }} />
      </div>
      <div
        className={`${styles.p} ${styles.center}`}
        dangerouslySetInnerHTML={{
          __html: t('Version', {
            version: global.__ENV__.APP_VERSION,
          }),
        }}
      />
    </section>
  );
};

export default GameRules;
