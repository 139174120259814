import { Spine } from '@pixi-spine/all-4.1';
import { Loader, UPDATE_PRIORITY } from 'pixi.js';

import type { SpineFile, SpineInterface } from '../../../config/spine.generated';
import Animation from '../../animations/animation';
import Animator from '../../animations/animator';

export * from '@pixi-spine/all-4.1';

// settings.GLOBAL_AUTO_UPDATE = false;
export class TickerSpine<SF extends SpineFile = SpineFile> extends Spine<
  SpineInterface[SF]['animations'],
  SpineInterface[SF]['skins']
> {
  constructor(spineName: SF) {
    super(Loader.shared.resources[spineName]!.spineData!);

    this.autoUpdate = false;

    const ticker = Animator.ticker;
    const updater = () => {
      this.update(ticker.deltaMS / 1000);
    };

    this.on('added', () => {
      ticker.add(updater, undefined, UPDATE_PRIORITY.HIGH);
    });
    this.on('destroyed', () => {
      ticker.remove(updater);
    });
    this.on('removed', () => {
      ticker.remove(updater);
    });
  }

  public getAnimation(trackIndex: number, animationName: SpineInterface[SF]['animations']): Animation {
    const animation = new Animation({});
    animation.duration = (this.spineData.findAnimation(animationName).duration || 0) * 1000;

    animation.addOnStart(() => {
      this.state.setAnimation(trackIndex, animationName).listener = {
        complete: (event) => {
          if (event.animation?.name === animationName) {
            animation.onComplete();
          }
        },
      };
    });

    return animation;
  }
}

export default {};
