import { SmoothGraphics as Graphics } from '@pixi/graphics-smooth';
import i18n from 'i18next';
import { Container } from 'pixi.js';

import Tween from '../../animations/tween';
import { soundToastStyles } from '../../config';
import { TextField } from '../../text/TextField';

export class Toast extends Container {
  private rect = this.initRect();

  private text = this.initText();

  constructor() {
    super();

    this.visible = false;
    this.zIndex = 3;
    this.addChild(this.rect);
    this.addChild(this.text.getText());
  }

  private initRect(): Graphics {
    const rect = new Graphics();
    rect.beginFill(0x000000, 0.7, true);
    rect.drawRoundedRect(0, 0, 360, 126, 65);
    rect.endFill();

    return rect;
  }

  private initText(): TextField {
    const text = new TextField(i18n.t('soundIsOff'), 270, 60, {}, true, soundToastStyles);
    text.text.anchor.set(0.5);
    text.text.x = 180;
    text.text.y = 63;
    return text;
  }

  public setIsLoading(): void {
    this.visible = true;
    this.text.setText(i18n.t('soundIsLoading'));
  }

  public setIsOff(): void {
    this.visible = true;
    this.text.setText(i18n.t('soundIsOff'));
    Tween.setTimeout(() => (this.visible = false), 2000);
  }

  public setIsOn(): void {
    this.visible = true;
    this.text.setText(i18n.t('soundIsOn'));
    Tween.setTimeout(() => (this.visible = false), 2000);
  }

  public setIsOnOff(soundIsOn: boolean): void {
    this.visible = true;
    if (soundIsOn) {
      this.text.setText(i18n.t('soundIsOn'));
    } else {
      this.text.setText(i18n.t('soundIsOff'));
    }

    Tween.setTimeout(() => (this.visible = false), 2000);
  }
}
