import { SlotId } from '../config';
import { BuyFeatureType, ISettledBet, RespinReelType, RespinSymbolType } from '../global.d';
import { setBetAmount, setNextResult, setRespinCnt } from '../gql/cache';
import {
  REELS_AMOUNT,
  RESPIN_LIMIT_BONUS_MULTIPLE,
  RESPIN_MULTIPLIER_MAX_COUNT,
  SLOTS_PER_REEL_AMOUNT,
} from '../slotMachine/config';
import { BonusDataByBonusId, BonusKind, BuyFeatureBonusInfo, RespinBonusInfo } from '../slotMachine/config/bonusInfo';
import { Icon } from '../slotMachine/d';

import { getSpecialRounds } from './slotMachine';

export const getRespinMultipleCount = (respinCnt?: number): number => {
  const cnt = respinCnt ? respinCnt : setRespinCnt();
  return cnt >= RESPIN_MULTIPLIER_MAX_COUNT ? RESPIN_MULTIPLIER_MAX_COUNT : cnt;
};

export const getRespinResumeCount = (): number => {
  return setRespinCnt() === 0
    ? 0
    : RESPIN_MULTIPLIER_MAX_COUNT >= setRespinCnt()
    ? getRespinMultipleCount() - 1
    : getRespinMultipleCount();
};

export const isRespinLimitBonus = (nextResult: ISettledBet): boolean => {
  if (nextResult?.bet.data.features.gameRoundStore.respins! >= 30 && nextResult?.paylines.length! >= 2) {
    return true;
  }
  return false;
};

export const getRespinLimitBonusAmount = (nextResult: ISettledBet): number => {
  if (isRespinLimitBonus(nextResult)) {
    return setBetAmount() * RESPIN_LIMIT_BONUS_MULTIPLE;
  }
  return 0;
};

export const getRespinRoundCount = (nextResult: ISettledBet): number => {
  return nextResult?.bet.data.features.gameRoundStore.respins!;
};

export const isSuccessRespin = (nextResult: ISettledBet): boolean => {
  return isRespinLimitBonus(nextResult) || getSpecialRounds() ? true : false;
};

export const getRespinGuaranteedNum = (nextResult: ISettledBet): number => {
  return nextResult?.bet.data.features.gameRoundStore.guaranteedRespins!;
};

export const isRespinReviveSpinResult = (spinResult: Icon[]): boolean => {
  for (let j = 0; j < SLOTS_PER_REEL_AMOUNT; j++) {
    if (spinResult[REELS_AMOUNT * j + 2]?.id !== SlotId.X) {
      return false;
    }
  }
  return true;
};

export const isRespinRevivePattern = (spinResult: Icon[]): boolean => {
  return isRespinReviveSpinResult(spinResult) && isSuccessRespin(setNextResult());
};

export const findBonusInfoByBonusId = (id: string | undefined): RespinBonusInfo | BuyFeatureBonusInfo | undefined => {
  if (id) {
    return BonusDataByBonusId[id];
  } else {
    return undefined;
  }
};

export const getRespinSymbolType = (bonusId: string): RespinSymbolType | undefined => {
  const bonusInfo = findBonusInfoByBonusId(bonusId);
  if (bonusInfo && bonusInfo.bonusKind === BonusKind.FREE_SPINS) {
    return bonusInfo.respinSymbolType;
  }
  return undefined;
};

export const getRespinReelType = (nextResult: ISettledBet): RespinReelType | undefined => {
  const bonusInfo = findBonusInfoByBonusId(nextResult?.bet.userBonus?.bonusId);
  if (bonusInfo?.bonusKind === BonusKind.FREE_SPINS) {
    return bonusInfo.respinReelType;
  }
  return undefined;
};

export const findBuyFeatureType = (nextResult: ISettledBet): BuyFeatureType | undefined => {
  const bonusInfo = findBonusInfoByBonusId(nextResult?.bet.userBonus?.bonusId);
  if (bonusInfo?.bonusKind === BonusKind.BUY_FEATURE) {
    return bonusInfo.buyFeatureType;
  }
  return undefined;
};

export const findBuyFeatureTypeByBonusId = (id: string): BuyFeatureType | undefined => {
  const bonusInfo = findBonusInfoByBonusId(id);
  if (bonusInfo?.bonusKind === BonusKind.BUY_FEATURE) {
    return bonusInfo.buyFeatureType;
  }
  return undefined;
};
