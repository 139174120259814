import { Container } from 'pixi.js';

import { clockTextStyle } from '../../config';
import { TextField } from '../../text/TextField';

class Clock extends Container {
  private clock: TextField;

  constructor() {
    super();
    this.zIndex = 2;
    this.clock = this.initClock();
    this.addChild(this.clock.getText());
    setInterval(() => this.clock.setText(this.getTime()), 1000);
  }

  private getTime(): string {
    const date = new Date();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const nHours = hours < 10 ? `0${hours}` : hours;
    const nMinutes = minutes < 10 ? `0${minutes}` : minutes;

    return `${nHours}:${nMinutes}`;
  }

  private initClock(): TextField {
    const text = new TextField(this.getTime(), 200, 50, clockTextStyle);
    text.text.anchor.set(0, 1);

    return text;
  }

  public update(width: number, height: number): void {
    this.clock.update(width, height);
  }
}

export default Clock;
