import { EventTypes, eventManager } from '../config';

import Animation from './animation';
import { ITween, ITweenObject, TweenProperties } from './d';

class Tween extends Animation implements ITween {
  public object: ITweenObject;

  public property: TweenProperties;

  public propertyBeginValue: number;

  public target: number;

  public isStandBy = true;

  constructor(options: ITween) {
    super(options);
    this.object = options.object;
    this.property = options.property;
    this.propertyBeginValue = options.propertyBeginValue;
    this.target = options.target;
  }

  public override onComplete(): void {
    super.onComplete();
  }

  public override start(): void {
    super.start();
    this.isStandBy = true;
    this.startTime = -this.delay;
    eventManager.emit(EventTypes.START_TWEEN_ANIMATION, this);
  }

  public override skip(): void {
    super.skip();
    eventManager.emit(EventTypes.REMOVE_TWEEN_ANIMATION, this);
  }

  public override end(): void {
    if (this.ended) return;
    eventManager.emit(EventTypes.END_TWEEN_ANIMATION, this);
    this.onComplete();
  }

  public static createDelayAnimation(duration: number): Animation {
    const dummy = { x: 0 };
    return new Tween({
      object: dummy,
      property: TweenProperties.X,
      propertyBeginValue: 0,
      target: 0,
      duration,
    });
  }

  public static setTimeout(callback: () => void, ms: number): Animation {
    const delay = this.createDelayAnimation(ms);
    delay.addOnComplete(callback);
    delay.start();
    return delay;
  }
}

export default Tween;
