import { SlotId } from '../config';
import { GameMode } from '../consts';
import { ClientSlotFeatureSettings } from '../gql';
import { setIsBuyFeatureSpin } from '../gql/cache';

import { urlSearchParams } from './helper';

export const isMobileDevice = (): boolean => {
  const regex = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|WPDesktop/;
  return (
    regex.test(window.navigator.userAgent) ||
    (window.navigator.platform === 'MacIntel' &&
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      typeof window.navigator.standalone !== 'undefined')
  );
};

export const isBaseGameMode = (mode: GameMode): boolean => {
  return mode === GameMode.REGULAR || mode === GameMode.BUY_FEATURE;
};
export const isFreeSpinsMode = (mode: GameMode): boolean => {
  return mode === GameMode.FREE_SPINS;
};

export const isScatter = (_slotId: SlotId): boolean => {
  return false;
};

export const isBuyFeatureMode = (): boolean => {
  return setIsBuyFeatureSpin();
};

export const canPressSpin = ({
  gameMode,
  isFreeSpinsWin,
  isSpinInProgress,
  isSlotBusy,
  isSlotStopped,
  isOpenedMessageBanner,
  isInTransition,
  isBuyFeaturePopupOpened,
  isOpeningScenePlaying,
  isSpinShortCut,
  isRespinRevivalWaiting,
  isAutoPlay,
}: {
  gameMode: GameMode;
  isFreeSpinsWin: boolean;
  isSpinInProgress: boolean;
  isSlotBusy: boolean;
  isSlotStopped: boolean;
  isOpenedMessageBanner: boolean;
  isInTransition: boolean;
  isBuyFeaturePopupOpened: boolean;
  isOpeningScenePlaying: boolean;
  isSpinShortCut: boolean;
  isRespinRevivalWaiting: boolean;
  isAutoPlay: boolean;
}): boolean => {
  if (isInTransition) {
    return false;
  }
  if (isAutoPlay) {
    return false;
  }
  if (gameMode === GameMode.REGULAR && isFreeSpinsWin) {
    return false;
  }
  if (isFreeSpinsMode(gameMode) && isOpenedMessageBanner && !isRespinRevivalWaiting) {
    return true;
  }
  if (isFreeSpinsMode(gameMode) && !isSlotBusy) {
    return false;
  }

  if (isSpinInProgress && isSlotStopped) {
    return false;
  }

  if (isBuyFeaturePopupOpened) {
    return false;
  }
  if (isOpeningScenePlaying) {
    return false;
  }
  if (isSpinShortCut) {
    return false;
  }
  if (isRespinRevivalWaiting) {
    return false;
  }

  return true;
};

export const isRespinSlotId = (slotId: SlotId): boolean => {
  if (slotId == SlotId.A || slotId == SlotId.B || slotId == SlotId.C || slotId == SlotId.D || slotId == SlotId.E) {
    return true;
  }
  return false;
};

export const isDevelopment = (): boolean => process.env.NODE_ENV === 'development';

export const isBuyFeatureEnabled = (features: ClientSlotFeatureSettings[] = []): boolean => {
  const freeSpinFeature = features.find((i) => i.id === 'freeSpins');

  return freeSpinFeature?.enabled || false;
};

export const isDemo = urlSearchParams.has('isDemo');
