import { SlotId } from '../../config';

export * from './textStyles';
export * from './events';
export enum SlotMachineState {
  IDLE,
  SPIN,
  STOP,
  JINGLE,
  WINNING,
}

export const SLOTMACHINE_CONTAINER_LANDSCAPE_BASE_Y = 581;

export type GameViewState = 'Base';

// APPLICATION SETTINGS
export const APPLICATION_TRANSPARENT = false;
export const APPLICATION_FPS = 60;

// WIN SETTINGS
export const WIN_ANIMATION_INTERVAL = 1_000;
export const WIN_ANIMATION_DELAY_TIME_DURATION = 2_000;
export const WIN_CHECK_TIME_DURATION_ON_FREE_SPIN = 1_000;
export const SOLDOUT_WIN_CHECK_TIME_DURATION = 2_000;

// BASE WIN SETTINGS
export const DOUBLE_WIN_AMOUNT_LIMIT = 2;
export const BASE_WIN_AMOUNT_LIMIT = 10;
export const BASE_WIN_TITLE_SCALE = 1.2;
export const BASE_WIN_COUNT_UP_MULTIPLIER = 0.03;

// BIG WIN SETTINGS
export const BIG_WIN_TITLE = 'BIG WIN!';
export const BIG_WIN_AMOUNT_LIMIT = 25;
export const BIG_WIN_TITLE_SCALE = 1.2;
export const BIG_WIN_ZOOM_TITLE_DURATION = 500;
export const BIG_WIN_COUNT_UP_MULTIPLIER = 0.03;
export const BIG_WIN_END_DURATION = 1000;

// MEGA WIN SETTINGS
export const MEGA_WIN_TITLE = 'MEGA WIN!';
export const MEGA_WIN_AMOUNT_LIMIT = 50;
export const MEGA_WIN_TITLE_SCALE = 1.3;
export const MEGA_WIN_ZOOM_TITLE_DURATION = 550;
export const MEGA_WIN_COUNT_UP_MULTIPLIER = 0.1;

// GREAT WIN SETTINGS
export const GREAT_WIN_TITLE = 'GREAT WIN!';
export const GREAT_WIN_AMOUNT_LIMIT = 100;
export const GREAT_WIN_TITLE_SCALE = 1.4;
export const GREAT_WIN_ZOOM_TITLE_DURATION = 600;
export const GREAT_WIN_COUNT_UP_MULTIPLIER = 0.2;

// EPIC WIN SETTINGS
export const EPIC_WIN_TITLE = 'EPIC WIN!';
export const EPIC_WIN_TITLE_SCALE = 1.5;
export const EPIC_WIN_ZOOM_TITLE_DURATION = 650;
export const EPIC_WIN_COUNT_UP_MULTIPLIER = 0.5;

//SOLDOUT WIN
//export const SOLDOUT_WIN_COUNT_UP_MULTIPLIER = 1.4;
export const SOLDOUT_WIN_TITLE_BEGIN_SCALE = 1.0;
export const SOLDOUT_WIN_TITLE_TARGET_SCALE = 1.2;

// WIN SLOT ANIMATION SETTINGS
export const WIN_SLOT_ANIMATION_SCALE = 1.15;
export const WIN_SLOT_ANIMATION_DURATION = 600;
export const TURBO_SPIN_WIN_SLOT_ANIMATION_COEFFICIENT = 1;
export const WIN_SLOT_TINT_COLOR = 0x888888;

// GENERAL SLOT MACHINE SETTINGS
export const REELS_AMOUNT = 5;
export const SLOTS_PER_REEL_AMOUNT = 3;
export const REEL_WIDTH = 260;
export const SLOT_RESOURCE_WIDTH = 280;
export const SLOT_RESOURCE_HEIGHT = 280;
export const SLOT_WIDTH = 280;
export const SLOT_HEIGHT = 249;
export const SLOT_SCALE = 1.0;
export const SHOW_ALL_LINES_ON_WIN = true;
export const SLOT_REELMASK_X = -2;
export const SLOT_REELMASK_Y = 5;
export const SLOTS_CONTAINER_HEIGHT = SLOT_HEIGHT * 2 + SLOT_RESOURCE_HEIGHT; // 498 + 280 = 778;
export const SLOTS_CONTAINER_WIDTH = REEL_WIDTH * 4 + SLOT_WIDTH;
export const SHOW_LINES_NUMBER_CONTAINER = false;
export const LINES_NUMBER_CONTAINER_WIDTH = 30;
export const MINIMUM_SPIN_SLOTS_AMOUNT = 50;
export const GAME_CONTAINER_HEIGHT = SLOTS_CONTAINER_HEIGHT;
export const GAME_CONTAINER_WIDTH = SHOW_LINES_NUMBER_CONTAINER
  ? SLOTS_CONTAINER_WIDTH + 2 * LINES_NUMBER_CONTAINER_WIDTH
  : SLOTS_CONTAINER_WIDTH;
export const SLOTS_BACKGROUND_WIDTH = 1315;
export const SLOTS_BACKGROUND_HEIGHT = 597;
export const SLOTS_REELFRAME_WIDTH = 1331;
export const SLOTS_REELFRAME_HEIGHT = 603;

// ANTICIPATION SETTINGS
export const ANTICIPATION_ENABLE = true;
export const ANTICIPATION_SYMBOLS_ID = [SlotId.A];
export const ANTICIPATION_START_SYMBOLS_AMOUNT = [2];
export const ANTICIPATION_END_SYMBOLS_AMOUNT = [];

// SAFE AREA SETTINGS
export const SAFE_AREA_DESKTOP_BOTTOM_PADDING = 0;
export const SAFE_AREA_MOBILE_BOTTOM_PADDING = 0;
export const SAFE_AREA_LANDSCAPE_WIDTH = 1393 + 270 + 154 + 392; //1600; 1324 + 328 + 328
export const SAFE_AREA_LANDSCAPE_HEIGHT = 780 + 280 + 215; //601 + 300 + 300
export const SAFE_AREA_LANDSCAPE_PIVOT_X = -424;
export const SAFE_AREA_LANDSCAPE_PIVOT_Y = -244;
export const SAFE_AREA_PORTRAIT_WIDTH = 1400;
export const SAFE_AREA_PORTRAIT_HEIGHT = 2000; //601 + 435 * 2
export const SAFE_AREA_PORTRAIT_PIVOT_X = -36;
export const SAFE_AREA_PORTRAIT_PIVOT_Y = -141 - 443;

export const FLEXIBLE_GAMEVIEW_POSY_RATIO = 0.5;

// REELS SETTINGS
export const BASE_SPIN_TIME = 2000;
export const TURBO_SPIN_TIME = 1000;
export const ADDITIONAL_SPIN_TIME_PER_REEL = 300;
export const TURBO_ADDITIONAL_SPIN_TIME_PER_REEL = 100;
export const TURBO_SPIN_REEL_ANIMATION_DELAY_PER_REEL = 25;
export const SPIN_REEL_ANIMATION_DELAY_PER_REEL = 50;
// export const PERSISTENT_SHARP_SYMBOLS_ID = [SlotId.SC1, SlotId.WL, SlotId.MS1];
export const BASE_REEL_BLUR_VALUE = 18;
export const TURBO_REEL_BLUR_VALUE = 24;
export enum ReelState {
  IDLE,
  STARTING,
  ROLLING,
  ENDING,
}

// SPIN ANIMATION SETTINGS
export const BASE_REEL_STARTING_DURATION = 500;
export const BASE_REEL_FIRST_ROLLING_DURATION = 500;
export const BASE_REEL_ROLLING_DURATION = 600;
export const BASE_REEL_ROLLING_SPEED = 0.0155;
export const BASE_REEL_ENDING_DURATION = 250;
export const FAKE_ROLLING_DURATION = 12_000;
export const TURBO_REEL_STARTING_DURATION = 125;
export const TURBO_REEL_FIRST_ROLLING_DURATION = 125;
export const TURBO_REEL_ROLLING_DURATION = 100;
export const TURBO_REEL_ROLLING_SPEED = 0.05;
export const TURBO_REEL_ENDING_DURATION = 250;
export const FORCE_STOP_SPIN_ANIMATION_DURATION = 250;
export const FORCE_STOP_SPIN_PER_EACH_DURATION = 50;
export const REEL_STARTING_SLOTS_AMOUNT = (BASE_REEL_ROLLING_SPEED * BASE_REEL_STARTING_DURATION) / 3.4;
export const REEL_STARTING_SLOTS_AMOUNT_DUMMY = 0.1;
export const REEL_ENDING_SLOTS_AMOUNT = (BASE_REEL_ROLLING_SPEED * BASE_REEL_ENDING_DURATION) / 2; // (speed*duration) / (df/dt)(0)
export const BASE_REEL_STARTING_FORMULA = (t: number): number => t * t * (1.4 * t - 0.4);
export const BASE_REEL_ENDING_FORMULA = (t: number): number => 1 - (1 - t) * (1 - t);
// (t - 1) * (t - 1) * ((0.5 + 1) * (t - 1) + 0.5) + 1;

export const RESPIN_REEL_ENDING_SLOTS_AMOUNT: number[] = [4, 6, 6, 6, 6, 8, 8, 8, 8, 8, 12];
export const RESPIN_REEL_ENDING_MULTI_DURATION: number[] = [
  2000, 3000, 3000, 3000, 3000, 4000, 4000, 4000, 4000, 4000, 5000,
];
//TO DO
export const ANTICIPATION_DELAY = 0;
export const ANTICIPATION_LONG_EXTERNAL_ROLLING_DURATION = 2000 - BASE_REEL_ROLLING_DURATION;
export const ANTICIPATION_LONG_ENDING_DURATION: number[] = [2500, 4000, 6000];
export const ANTICIPATION_LONG_ENDING_SLOTS_AMOUNT: number[] = [4, 8, 12];
export const ANTICIPATION_SHORT_ROLLING_DURATION = 1000 - BASE_REEL_ROLLING_DURATION;
export const ANTICIPATION_SHORT_ENDING_SLOTS_AMOUNT = 2;
export const ANTICIPATION_SHORT_ENDING_DURATION = 1000;
export const ANTICIPATION_REEL_FORMULA = (t: number): number => 1 - (1 - t) * (1 - t);
export const ANTICIPATION_SLOTS_TINT = 0x7f7f7f;
export const ANTICIPATION_SHORT_START_REELID = 3;
export const ANTICIPATION_LONG_START_REELID = 4;

export const REEL_BUYFEATURE_ROLLING_DURATION = 2000;
export const REEL_BUYFEATURE_MYSTERY_ENDING_DURATION = [2500, 4000, 6000];
export const REEL_BUYFEATURE_MYSTERY_ENDING_AMOUNT = [4, 8, 12];

// TINT CONTAINER SETTINGS
export const TINT_CONTAINER_WIDTH = REEL_WIDTH;
export const TINT_CONTAINER_HEIGHT = 820;
export const TINT_BACKGROUND_COLOR = 0x000000;
export const TINT_ALPHA = 0.3;

// PAY_TABLE SETTINGS
export const PAY_TABLE_WIDTH = REEL_WIDTH * 1.5;
export const PAY_TABLE_HEIGHT = SLOT_HEIGHT * 0.75;
export const PAY_TABLE_BACKGROUND_COLOR = 0x000000;
export const PAY_TABLE_NUMBER_COLOR = 0xffffff;
export const PAY_TABLE_TEXT_COLOR = 0xf6e87e;

// COINS ANIMATIONS SETTINGS
export const COINS_AMOUNT_PER_SEC = 8;
export const SINGLE_COIN_ANIMATION_DURATION = 2500;
export const MINIMUM_FRACTION_DIGITS = 2;
export const MAXIMUM_FRACTION_DIGITS = 2;

// FREE SPINS
export const FREE_SPINS_FADE_OUT_DURATION = 1000;
export const FREE_SPINS_FADE_IN_DURATION = 1000;
export const FREE_SPINS_TIME_OUT_BANNER = 3500;

export const WIN_CHECK_TIME_DURATION_ON_FREESPIN = 1_000;
export const BIG_WIN_CHECK_TIME_DURATION_ON_FREESPIN = 3_000;

//export const BASEGAME_TO_FREESPIN_REEL_POSITIONS = [0, 0, 0, 0, 0];
//export const FREEGAME_TO_BASEGAME_REEL_POSITIONS = [3, 0, 5, 0, 0];

// WIN STAGES
export enum WinStages {
  None,
  BaseWin,
  BigWin,
  MegaWin,
  GreatWin,
  EpicWin,
}
// WIN TEXT SETTINGS
export const WINLABEL_POS_X = SLOTS_CONTAINER_WIDTH / 2;
export const WINLABEL_POS_Y = 329 + 1220 / 2 - SLOTMACHINE_CONTAINER_LANDSCAPE_BASE_Y - 15;
export const WINCOUNTUP_TEXT_POS_X = SLOTS_CONTAINER_WIDTH / 2;
export const WINCOUNTUP_LANDSCAPE_TEXT_POS_Y = 892 + 236 / 2 - SLOTMACHINE_CONTAINER_LANDSCAPE_BASE_Y;
export const WINCOUNTUP_PORTRAIT_TEXT_POS_Y = 892 + 236 / 2 - SLOTMACHINE_CONTAINER_LANDSCAPE_BASE_Y;
export const SOLDOUT_WINCOUNTUP_LANDSCAPE_TEXT_POS_Y = 1143 + 191 / 2 - 45 - SLOTMACHINE_CONTAINER_LANDSCAPE_BASE_Y;
export const SOLDOUT_WINCOUNTUP_PORTRAIT_TEXT_POS_Y = 1143 + 191 / 2 - 45 - SLOTMACHINE_CONTAINER_LANDSCAPE_BASE_Y;
export const WINCOUNTUP_TURBO_MULTIPILIER = 3;

//JINGLE to WIN SETTING
export const WIN_ANIM_START_DELAY = 500;
export const JINGLE_TO_WIN_DURATION = 2000;

//
export const REVIVAL_REEL_STOP_DURATION = 2000;
export const REVIVAL_ANIMATION_DURATION = 2500;
//RESPIN
export const RESPIN_ENDING_DELAY_DURATION = REVIVAL_REEL_STOP_DURATION;
export const RESPIN_REEL_INDEX = 2;
export const RESPIN_MULTIPLIER_MAX_COUNT = 10;
export const RESPIN_LIMIT_BONUS_MULTIPLE = 1000;
