import { Container } from 'pixi.js';

import type { UiButton } from '../components/ui/uiButton';

import { AutoplayBtn } from './autoplayBtn';
import { BetBtn } from './betBtn';
import { InfoBtn } from './infoBtn';
import MenuBtn from './menuBtn';
import { SpinBtn } from './spinBtn';
import { TurboSpinBtn } from './turboSpinBtn';

export function createUIButtonContainer(): Container<UiButton> {
  const container = new Container<UiButton>();
  container.name = 'ui';

  const menuBtn = new MenuBtn();
  const turboSpinBtn = new TurboSpinBtn();
  const spinBtn = new SpinBtn();
  const betBtn = new BetBtn();
  const autoplayBtn = new AutoplayBtn();
  const infoBtn = new InfoBtn();

  container.addChild(menuBtn, turboSpinBtn, spinBtn, betBtn, autoplayBtn, infoBtn);

  return container;
}
