import type TaggedText from 'pixi-tagged-text';

import { Variables } from '../../../config';
import { AutoResizeTextStyle } from '../../components/autoResizeText';

type TextStyleSet = (typeof TaggedText)['defaultStyles'];

export const introAutoResizeTextStylesInfo: Partial<AutoResizeTextStyle> = {
  fontFamily: Variables.FONT_FAMILY,
  lineJoin: 'round',
  align: 'center',
  dropShadow: false,
  fontStyle: 'normal',
  fill: '#101013',
  stroke: 0xffffff,
  strokeThickness: 5,
  fontSize: 39,
  fontWeight: '700',
  letterSpacing: 0,
  lineHeight: 40,
  maxWidth: 453,
  maxHeight: 249,
};

export const introOuterAutoResizeTextStylesInfo: Partial<AutoResizeTextStyle> = {
  fontFamily: Variables.FONT_FAMILY,
  lineJoin: 'round',
  align: 'left',
  dropShadow: false,
  fontStyle: 'normal',
  fill: '#fff',
  stroke: 0xffffff,
  fontSize: 36,
  fontWeight: '700',
  letterSpacing: 0,
  lineHeight: 42,
  maxWidth: 1024,
  maxHeight: 75,
};

export const introTitleStylesInfo: TextStyleSet = {
  default: {
    fontFamily: Variables.FONT_FAMILY,
    lineJoin: 'round',
    align: 'center',
    dropShadow: false,
    fontStyle: 'normal',
    wordWrapWidth: 1004,
    wordWrapHeight: 158,
    fill: '#101013',
    stroke: 0xffffff,
    strokeThickness: 5,
    fontSize: 42,
    fontWeight: '700',
    letterSpacing: 0,
    lineHeight: 42,
  },
};
