import MultiStyleText, { TextStyleSet } from 'pixi-multistyle-text';
import { ITextStyle, Text, TextStyle } from 'pixi.js';

export class TextField {
  text: Text | MultiStyleText;

  height: number;

  width: number;

  constructor(
    txt = '',
    width: number,
    height: number,
    style?: Partial<ITextStyle> | TextStyle,
    isMultiText = false,
    multiTextStyle?: TextStyleSet,
  ) {
    this.text = isMultiText && multiTextStyle ? new MultiStyleText(txt, multiTextStyle) : new Text(txt, style);
    this.width = width;
    this.height = height;
  }

  public update(width: number, height: number): void {
    this.width = width;
    this.height = height;
    this._update();
  }

  public getText(): Text | MultiStyleText {
    this._update();
    return this.text;
  }

  public setText(text: string): void {
    this.text.text = text;
    this._update();
  }

  private _update(): void {
    this.text.scale.set(1);
    const scaleFactor = Math.min(this.height / this.text.height, this.width / this.text.width, 1);
    this.text.scale.set(scaleFactor);
  }
}
