import { GameMode } from '../../consts';
import ViewContainer from '../components/container';
import { TickerSpine } from '../components/spine';
import { EventTypes, SLOTS_CONTAINER_WIDTH, eventManager } from '../config';

const TITLE_POSITION_X = 502 - 76 + 478 / 2;
const TITLE_POSITION_Y = -239 - 12 + 254 / 2;

class GameTitle extends ViewContainer {
  private title: TickerSpine<'title_logo'>;

  constructor() {
    super();

    this.title = new TickerSpine('title_logo');
    this.title.position.x = TITLE_POSITION_X;
    this.title.position.y = TITLE_POSITION_Y;
    this.addChild(this.title);

    this.title.state.setAnimation(0, 'base', true);
    eventManager.on(EventTypes.CHANGE_MODE, this.onModeChange.bind(this));
    //    eventManager.on(EventTypes.MANUAL_CHANGE_BACKGROUND, this.onModeChange.bind(this));
    eventManager.on(EventTypes.RESIZE, this.applicationResize.bind(this));
  }

  private onModeChange(_settings: { mode: GameMode }) {}

  private applicationResize = (width: number, height: number): void => {
    if (width > height) {
      this.title.state.setAnimation(0, 'base', true);
      this.title.position.x = TITLE_POSITION_X;
      this.title.position.y = TITLE_POSITION_Y;
    } else {
      this.title.state.setAnimation(0, 'intro', true);
      this.title.position.x = SLOTS_CONTAINER_WIDTH / 2;
      this.title.position.y = -141 - 443 / 2;
    }
  };
}

export default GameTitle;
