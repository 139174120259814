import * as PIXI from 'pixi.js';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import SlotMachine from '../../slotMachine';
import { FEATURE_DISP_INFO } from '../../slotMachine/buyFeature/config';

import styles from './info.module.scss';

const FreeSpinFeature: React.VFC = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'infoFeature' });

  const [infoBuyFeatureIcon, setInfoBuyFeatureIcon] = useState('');

  useEffect(() => {
    const extractor: PIXI.Extract = SlotMachine.getInstance().getApplication().renderer.plugins[
      'extract'
    ] as PIXI.Extract;
    setInfoBuyFeatureIcon(extractor.base64(SlotMachine.getInstance().buyFeatureBtn!));
  }, []);

  const featuresFeature: string[] = t('FeatureDesc', {
    returnObjects: true,
  });

  const featuresFreeSpins: string[] = t('FreeSpinDesc', {
    returnObjects: true,
  });

  const featuresBuyAFeature1: string[] = t('BuyAFeatureDesc1', {
    returnObjects: true,
  });
  const featuresBuyAFeature2: string[] = t('BuyAFeatureDesc2', {
    returnObjects: true,
    A: FEATURE_DISP_INFO['A'].multiplier,
    B: FEATURE_DISP_INFO['B'].multiplier,
    C: FEATURE_DISP_INFO['C'].multiplier,
    D: FEATURE_DISP_INFO['D'].multiplier,
    E: FEATURE_DISP_INFO['E'].multiplier,
    M: FEATURE_DISP_INFO['M'].multiplier,
  });
  const featuresBuyAFeature3: string[] = t('BuyAFeatureDesc3', {
    returnObjects: true,
  });

  return (
    <section>
      <h2 className={styles.subtitle}>{t('FeatureSubTitle')}</h2>
      <div className={`${styles.p}`}>
        {featuresFeature.map((v, i) => (
          <div key={`${i}`} dangerouslySetInnerHTML={{ __html: v }} />
        ))}
      </div>
      <div className={`${styles.p}`}>
        {featuresFreeSpins.map((v, i) => (
          <div key={`${i}`} dangerouslySetInnerHTML={{ __html: v }} />
        ))}
      </div>
      <h2 className={styles.subtitle}>{t('BuyAFeatureTitle')}</h2>
      <div className={styles.featureIcon}>
        <img draggable="false" src={infoBuyFeatureIcon} />
      </div>
      <div className={`${styles.p}`}>
        {featuresBuyAFeature1.map((v, i) => (
          <div key={`${i}`} dangerouslySetInnerHTML={{ __html: v }} />
        ))}
      </div>
      <div className={`${styles.p}`}>
        {featuresBuyAFeature2.map((v, i) => (
          <div key={`${i}`} dangerouslySetInnerHTML={{ __html: v }} />
        ))}
      </div>
      <div className={`${styles.p}`}>
        {featuresBuyAFeature3.map((v, i) => (
          <div key={`${i}`} dangerouslySetInnerHTML={{ __html: v }} />
        ))}
      </div>
    </section>
  );
};

const Features: React.VFC = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'infoFeature' });

  return (
    <section>
      <h1 className={styles.title}>{t('FeatureTitle')}</h1>
      <FreeSpinFeature />
    </section>
  );
};

export default Features;
