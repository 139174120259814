import * as PIXI from 'pixi.js';

import Animation from '../animations/animation';
import { TweenProperties } from '../animations/d';
import Tween from '../animations/tween';
import { EventTypes, Events, eventManager } from '../config';

class Backdrop extends PIXI.Sprite {
  constructor(openOn?: keyof Events, closeOn?: keyof Events, fadeDuration?: number) {
    super(PIXI.Texture.WHITE);

    this.anchor.set(0.5);

    this.tint = 0x000000;
    this.alpha = 0.5;
    this.interactive = true;
    this.visible = false;

    eventManager.addListener(EventTypes.RESIZE_GAME_CONTAINER, (width, height, scale) => {
      this.parent.toLocal({ x: width / 2, y: height / 2 }, undefined, this.position, false);
      this.width = width / scale;
      this.height = height / scale;
    });

    if (openOn) {
      eventManager.on(openOn, () => {
        this.visible = true;
        if (fadeDuration) {
          this.getFadeAnimation(0.7, fadeDuration, 0).start();
        }
      });
    }
    if (closeOn) {
      eventManager.on(closeOn, () => {
        this.visible = false;
      });
    }
  }

  private getFadeAnimation(alpha: number, duration: number, begin: number): Animation {
    const animation = new Tween({
      object: this,
      duration,
      propertyBeginValue: begin,
      target: alpha,
      property: TweenProperties.ALPHA,
    });
    return animation;
  }
}
export default Backdrop;
