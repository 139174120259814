export enum ResourceTypes {
  minipaytable = 'minipaytable',
  payline01 = 'payline01',
  payline02 = 'payline02',
  textPatternBannerRsEndLimitA = 'textPatternBannerRsEndLimitA',
  logo = 'logo',
  symbolA = 'symbolA',
  symbolB = 'symbolB',
  symbolBlurA = 'symbolBlurA',
  symbolBlurB = 'symbolBlurB',
  symbolBlurC = 'symbolBlurC',
  symbolBlurD = 'symbolBlurD',
  symbolBlurE = 'symbolBlurE',
  symbolBlurF = 'symbolBlurF',
  symbolBlurG = 'symbolBlurG',
  symbolBlurH = 'symbolBlurH',
  symbolBlurI = 'symbolBlurI',
  symbolBlurW = 'symbolBlurW',
  symbolBlurX = 'symbolBlurX',
  symbolC = 'symbolC',
  symbolD = 'symbolD',
  symbolE = 'symbolE',
  symbolF = 'symbolF',
  symbolG = 'symbolG',
  symbolH = 'symbolH',
  symbolI = 'symbolI',
  symbolW = 'symbolW',
  symbolX = 'symbolX',
}
