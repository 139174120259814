import { captureException } from '@sentry/react';

import { ReelSet } from '../global';
import { Icon } from '../slotMachine/d';

export const getSpinResult3x5 = ({
  reelPositions,
  reelSet,
  icons,
}: {
  reelPositions: number[];
  reelSet: ReelSet;
  icons: Icon[];
}): Icon[] => {
  const spinResult = [
    ...reelPositions.map((random, index) => {
      const prevRandom = random - 1 < 0 ? reelSet.layout[index]!.length - 1 : random - 1;
      return getIconById(icons, reelSet.layout[index]![prevRandom]!);
    }),
    ...reelPositions.map((random, index) => {
      return getIconById(icons, reelSet.layout[index]![random]!);
    }),
    ...reelPositions.map((random, index) => {
      const nextRandom = random + 1 >= reelSet.layout[index]!.length ? 0 : random + 1;
      return getIconById(icons, reelSet.layout[index]![nextRandom]!);
    }),
  ];
  return spinResult;
};

export const getIconById = (icons: Icon[], id: string): Icon => {
  const result = icons.find((icon) => icon.id === id);
  if (result) {
    return result;
  }
  const error = new Error(`NO SUCH ICON FOR ID ${id}`);
  captureException(error);
  throw error;
};
