import { ISongs, SlotId } from '../../config';
import { SpineInterface } from '../../config/spine.generated';
import { RespinReelType, RespinSymbolType } from '../../global.d';
import { BgType } from '../background/background';

export type AnnounceType = 'None' | 'lanternLow' | 'lanternMid' | 'lanternHi' | 'phoenix';
export type AnnounceLotTblType = 'LOW' | 'MID' | 'HI1' | 'HI2';
export const AnnounceConvTbl: AnnounceType[] = ['None', 'lanternLow', 'lanternMid', 'lanternHi', 'phoenix'];
export const AnnounceTypeToLanternAnimName: Record<AnnounceType, SpineInterface['multiplier']['animations']> = {
  None: 'base',
  lanternLow: 'yokoku_pt01',
  lanternMid: 'yokoku_pt02',
  lanternHi: 'yokoku_pt03',
  phoenix: 'base',
};
export const AnnounceTypeToLanternSoundName: Record<AnnounceType, ISongs> = {
  None: ISongs.XT003S_lantern_low,
  lanternLow: ISongs.XT003S_lantern_low,
  lanternMid: ISongs.XT003S_lantern_mid,
  lanternHi: ISongs.XT003S_lantern_high,
  phoenix: ISongs.XT003S_lantern_low,
};
export const RespinActionInfo: {
  in: SpineInterface['multiplier']['animations'][];
  loop: SpineInterface['multiplier']['animations'][];
  respinBgType: BgType[];
  respinStopSound: ISongs[];
} = {
  in: [
    'okwr_in_x1',
    'okwr_in_x2',
    'okwr_in_x3',
    'okwr_in_x4',
    'okwr_in_x5',
    'okwr_in_x10',
    'okwr_in_x20',
    'okwr_in_x30',
    'okwr_in_x40',
    'okwr_in_x50',
    'okwr_in_x100',
  ],
  loop: [
    'okwr_loop_x1',
    'okwr_loop_x2',
    'okwr_loop_x3',
    'okwr_loop_x4',
    'okwr_loop_x5',
    'okwr_loop_x10',
    'okwr_loop_x20',
    'okwr_loop_x30',
    'okwr_loop_x40',
    'okwr_loop_x50',
    'okwr_loop_x100',
  ],
  respinBgType: [
    'respin01',
    'respin01',
    'respin01',
    'respin01',
    'respin01',
    'respin02',
    'respin02',
    'respin02',
    'respin02',
    'respin02',
    'respin03',
  ],
  respinStopSound: [
    ISongs.XT003S_okwr_stop_suc1,
    ISongs.XT003S_okwr_stop_suc1,
    ISongs.XT003S_okwr_stop_suc1,
    ISongs.XT003S_okwr_stop_suc1,
    ISongs.XT003S_okwr_stop_suc1,
    ISongs.XT003S_okwr_stop_suc2,
    ISongs.XT003S_okwr_stop_suc2,
    ISongs.XT003S_okwr_stop_suc2,
    ISongs.XT003S_okwr_stop_suc2,
    ISongs.XT003S_okwr_stop_suc2,
    ISongs.XT003S_okwr_stop_suc3,
  ],
};

export const AnnounceDataTbl: {
  symbolCount: number;
  symbols: SlotId[];
  lotTblType: AnnounceLotTblType;
  lotTbl: number[];
  guaranteedMin: number;
}[] = [
  {
    symbolCount: 5,
    symbols: [SlotId.A, SlotId.B, SlotId.C, SlotId.D, SlotId.E],
    lotTblType: 'HI2',
    lotTbl: [223, 210, 172, 62, 333],
    guaranteedMin: 17,
  },
  {
    symbolCount: 5,
    symbols: [SlotId.A, SlotId.B, SlotId.C],
    lotTblType: 'HI1',
    lotTbl: [334, 315, 258, 93, 0],
    guaranteedMin: 1,
  },
  {
    symbolCount: 5,
    symbols: [SlotId.D, SlotId.E],
    lotTblType: 'MID',
    lotTbl: [532, 295, 173, 0, 0],
    guaranteedMin: 0,
  },
  {
    symbolCount: 4,
    symbols: [SlotId.A, SlotId.B, SlotId.C, SlotId.D, SlotId.E],
    lotTblType: 'LOW',
    lotTbl: [645, 305, 50, 0, 0],
    guaranteedMin: 0,
  },
];
export const ReelRollingExternalDuration: Record<AnnounceType, number> = {
  None: 0,
  lanternLow: 1000,
  lanternMid: 1700,
  lanternHi: 2500,
  phoenix: 4000,
};

export type CutinType = 'Low' | 'Mid' | 'High';
export const CutinTypeConvByReelType: Record<RespinReelType, CutinType> = {
  RS_L: 'Low',
  RS_M: 'Mid',
  RS_H: 'High',
  RS_LV: 'Low',
  RS_MV: 'Mid',
};
export const CutinVoiceConvByReelType: Record<RespinReelType, ISongs> = {
  RS_L: ISongs.XT003S_cutin_low,
  RS_M: ISongs.XT003S_cutin_mid,
  RS_H: ISongs.XT003S_cutin_high,
  RS_LV: ISongs.XT003S_cutin_low,
  RS_MV: ISongs.XT003S_cutin_mid,
};
export const CutinSkinConv: Record<CutinType, { skinName: SpineInterface['cutin']['skins'] }> = {
  Low: { skinName: 'a' },
  Mid: { skinName: 'b' },
  High: { skinName: 'c' },
};

export const RespinSymbolTypeConvToSkin: Record<RespinSymbolType, SpineInterface['cutin_sp']['skins']> = {
  A: 'symbol_a',
  B: 'symbol_b',
  C: 'symbol_c',
  D: 'symbol_d',
  E: 'symbol_e',
};

export const RespinFrameSkinNameCnv: Record<CutinType, SpineInterface['respin_waku']['skins']> = {
  Low: 'a',
  Mid: 'b',
  High: 'c',
};
