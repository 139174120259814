import React from 'react';
import { useTranslation } from 'react-i18next';

import { ResourceTypes } from '../../resources.d';
import Resources from '../../utils/resources';

import styles from './info.module.scss';

const PayLines: React.FC = () => {
  const { t } = useTranslation();

  const paylineDesc: string[] = t('infoPayLinesDesc', {
    returnObjects: true,
  });

  return (
    <section>
      <h1 className={styles.title}>{t('infoPayLinesTitle')}</h1>
      <div className={styles.payLines}>
        <div className={`${styles.p} ${styles.center}`}>
          {paylineDesc.map((v, i) => (
            <div key={`${i}`} dangerouslySetInnerHTML={{ __html: v }} />
          ))}
        </div>
        <div className={styles['image-container']}>
          <img draggable="false" src={Resources.getSource(ResourceTypes.payline01)} alt="payLines" />
          <img draggable="false" src={Resources.getSource(ResourceTypes.payline02)} alt="payLines" />
        </div>
      </div>
    </section>
  );
};

export default PayLines;
