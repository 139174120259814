import { ResultOf } from '@graphql-typed-document-node/core';

import { GameMode } from '../consts';
import { ISettledBet } from '../global.d';
import { BonusType, FragmentType, getFragmentData } from '../gql';
import { setNextResult } from '../gql/cache';
import { betBonusRewardFragment, userBonusFragment } from '../gql/fragment';
import { BonusKind } from '../slotMachine/config/bonusInfo';
import { Combos, Icon } from '../slotMachine/d';

import { findBonusInfoByBonusId } from './specific';

export const saveReelPosition = (reelPositions: number[]): void => {
  const positions = reelPositions.toString();
  sessionStorage.setItem('positions', window.btoa(positions));
  window.dispatchEvent(new CustomEvent('setpos'));
};

export const getGameModeByBonusId = (bonusId: string): GameMode => {
  const BonusKindToGameMode: GameMode[] = [GameMode.FREE_SPINS, GameMode.BUY_FEATURE];
  const bonusKind = getBonusKind(bonusId);
  return bonusKind != undefined ? BonusKindToGameMode[bonusKind]! : GameMode.REGULAR;
};

export function getBonusFromRewards(
  settledBet: ISettledBet,
  type: BonusType,
): ResultOf<typeof userBonusFragment> | undefined | null {
  type RewardType = NonNullable<NonNullable<ISettledBet>['rewards']>[number];
  type BetBonusRewardType = FragmentType<typeof betBonusRewardFragment> & { __typename: 'BetBonusReward' };

  const isBonusReward = (reward: RewardType): reward is BetBonusRewardType => reward?.__typename === 'BetBonusReward';
  const reward = settledBet?.rewards
    ?.filter(isBonusReward)
    .map((rf) => getFragmentData(betBonusRewardFragment, rf))
    .find((r) => getFragmentData(userBonusFragment, r.userBonus)?.bonus?.type === type);

  return getFragmentData(userBonusFragment, reward?.userBonus);
}

export const getFreeSpinBonus = () => {
  return getBonusFromRewards(setNextResult(), 'FREE_SPIN');
};

export const getSpecialRounds = () => {
  return getBonusFromRewards(setNextResult(), 'SPECIAL_ROUND');
};

export const getBonusKind = (bonusId: string): BonusKind | undefined => {
  return findBonusInfoByBonusId(bonusId)?.bonusKind;
};

export const calcPercentage = (initialValue: number, percent: number): number => {
  return (initialValue / 100) * percent;
};

export function getCombosDataFromIcon(icon: Icon): Combos {
  return icon.combos
    .flatMap((combo) => {
      const coinReward = combo.rewards.find((v) => v.type === 'COINS');
      if (coinReward) {
        return {
          pattern: combo.pattern,
          multiplier: coinReward.multiplier,
        };
      }
      return [];
    })
    .sort((a, b) => {
      const patternA = a.pattern;
      const patternB = b.pattern;
      if (patternA < patternB) {
        return 1;
      }
      if (patternA > patternB) {
        return -1;
      }
      return 0;
    });
}
