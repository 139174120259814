export enum SlotId {
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
  E = 'E',
  F = 'F',
  G = 'G',
  H = 'H',
  I = 'I',
  W = 'W',
  X = 'X',
}

export const config = {
  failureRetries: 5,
  autoplay: {
    defaultAutoSpinsAmount: 100,
    timeOut: 500,
  },
  historyTable: {
    showBy: 20,
    head: {
      date: 'date',
      bet: 'bet',
      win: 'win',
      gameId: 'Game ID',
    },
  },
  payTableData: [
    SlotId.W,
    SlotId.A,
    SlotId.B,
    SlotId.C,
    SlotId.D,
    SlotId.E,
    SlotId.F,
    SlotId.G,
    SlotId.H,
    SlotId.I,
    SlotId.X,
  ],
} as const;
