import * as PIXI from 'pixi.js';
import { BitmapFont } from 'pixi.js';

import { formatNumber } from '@phoenix7dev/utils-fe';

import { setCurrency } from '../../gql/cache';
import { ResourceTypes } from '../../resources.d';
import { countUpSoldOutTextStyle, countUpTextStyle } from '../../slotMachine/config';

export async function createBitmapFont() {
  const currency = setCurrency();
  const chars = formatNumber({ currency, value: 1234567890.09, showCurrency: true });
  const charset = new Set(chars);
  charset.add(' ');

  countUpTextStyle.layerList.forEach((style, index) => {
    BitmapFont.from(`${countUpTextStyle.name}.${index + 1}`, style.style, {
      chars: [...charset],
      textureWidth: 1024,
    });
  });

  const canvas = document.createElement('canvas');
  const canvasPattern = canvas
    .getContext('2d')!
    .createPattern(PIXI.Loader.shared.resources[ResourceTypes.textPatternBannerRsEndLimitA]!.data, 'repeat')!;
  countUpSoldOutTextStyle.layerList.forEach((style, index) => {
    if (index === 0) {
      style.style.fill = canvasPattern;
    }
    BitmapFont.from(`${countUpSoldOutTextStyle.name}.${index + 1}`, style.style, {
      chars: [...charset],
      textureWidth: 1024,
    });
  });
}
