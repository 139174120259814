import { BitmapFont, BitmapText, Container, IBitmapFontOptions, IBitmapTextStyle, ITextStyle } from 'pixi.js';

interface ILayeredBitmapTextLayer {
  offset?: [number, number];
  style: Partial<ITextStyle>;
  bitmapTextStyle?: Partial<Omit<IBitmapTextStyle, `font${string}`>>;
}

export type LayeredBitmapTextStyle = {
  name: string;
  layerList: ILayeredBitmapTextLayer[];
};

export class LayeredBitmapText extends Container<BitmapText> {
  private _text: string;

  private layerList: ILayeredBitmapTextLayer[];

  constructor(text: string, style: LayeredBitmapTextStyle) {
    super();
    this._text = text;
    this.layerList = [...style.layerList].reverse();

    this.layerList.forEach((layer, index) => {
      const obj = new BitmapText(text, {
        ...layer.bitmapTextStyle,
        fontName: `${style.name}.${this.layerList.length - index}`,
      });
      obj.anchor.set(0.5);
      if (layer.offset) obj.position.set(...layer.offset);
      this.addChild(obj);
    });
  }

  public get text(): string {
    return this._text;
  }

  public set text(text: string) {
    this._text = text;
    this.children.forEach((child) => {
      child.text = text;
    });
  }

  public static install(style: LayeredBitmapTextStyle, options?: IBitmapFontOptions): void {
    style.layerList.forEach((layer, index) => {
      BitmapFont.from(`${style.name}.${index + 1}`, layer.style, options);
    });
  }
}
